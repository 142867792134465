import React from "react";

const JustifyLastSeond = ({ selectedFolder }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="vtlicon"
      style={{
        background:
          selectedFolder?.alignContent === "space-around" ? "#1A237E" : "none",
      }}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15 0H16V16H15L15 0ZM0 0H1L1 16H0L0 0Z"
        fill={
          selectedFolder?.alignContent === "space-around" ? "white" : "#1A237E"
        }
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.5 5C9.5 4.73478 9.60536 4.48043 9.79289 4.29289C9.98043 4.10536 10.2348 4 10.5 4H12.5C12.7652 4 13.0196 4.10536 13.2071 4.29289C13.3946 4.48043 13.5 4.73478 13.5 5V11C13.5 11.2652 13.3946 11.5196 13.2071 11.7071C13.0196 11.8946 12.7652 12 12.5 12H10.5C10.2348 12 9.98043 11.8946 9.79289 11.7071C9.60536 11.5196 9.5 11.2652 9.5 11V5ZM2.5 5C2.5 4.73478 2.60536 4.48043 2.79289 4.29289C2.98043 4.10536 3.23478 4 3.5 4H5.5C5.76522 4 6.01957 4.10536 6.20711 4.29289C6.39464 4.48043 6.5 4.73478 6.5 5V11C6.5 11.2652 6.39464 11.5196 6.20711 11.7071C6.01957 11.8946 5.76522 12 5.5 12H3.5C3.23478 12 2.98043 11.8946 2.79289 11.7071C2.60536 11.5196 2.5 11.2652 2.5 11L2.5 5Z"
        fill={
          selectedFolder?.alignContent === "space-around" ? "white" : "#1A237E"
        }
      />
    </svg>
  );
};

export default JustifyLastSeond;
