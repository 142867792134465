import React from "react";

const DeleteSvg = ({ openModal }) => {
  return (
    <div>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={openModal}
      >
        <g clip-path="url(#clip0_682_1102)">
          <path
            d="M4.5 14.25C4.5 15.0787 5.17125 15.75 6 15.75H12C12.8288 15.75 13.5 15.0787 13.5 14.25V5.25H4.5V14.25ZM14.25 3H11.625L10.875 2.25H7.125L6.375 3H3.75V4.5H14.25V3Z"
            fill="red"
          />
        </g>
        <defs>
          <clipPath id="clip0_682_1102">
            <rect width="18" height="18" fill="red" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default DeleteSvg;
