import React from "react";

function TextAlign({ selectedFolder }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="rticon"
      // style={{
      //   background: selectedFolder?.textAlign === "Left" && "#0AABE6",
      // }}
    >
      <path
        d="M2.25 3.75H8.25"
        stroke="#fff"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.25 9H12"
        stroke="#fff"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.25 14.25H15.75"
        stroke="#fff"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default TextAlign;
