import React, { useEffect, useState } from "react";
import Content from "./Content";
import { Link, useNavigate, Route, Routes } from "react-router-dom";
import { useParams, useLocation } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import Loader from "../common/Loader/Loader";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
const MainDocsPage = ({
  listItems,
  location,
  docId,
  selectedData,
  setSelectedData,
  tabs,
  setTabs,
  documentLoading,
  setDocumentLoading,
  specialItems,
  navigateForward,
  navigateBackward,
}) => {
  useEffect(() => {
    if (listItems) {
      setSelectedData(listItems[0]);
    }
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    if (docId) {
      setDocumentLoading(true);
      setTimeout(() => {
        setTabs(docId);
        setDocumentLoading(false);
      }, 400);
    }
  }, [docId]);

  useEffect(() => {
    if (location?.hash) {
      const element = document.getElementById(location?.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  useEffect(() => {
    if (docId) {
      for (let i = 0; i < listItems.length; i++) {
        if (listItems[i].url === docId) {
          setSelectedData(listItems[i]);
        }
      }
    }
  }, [docId]);

  const [expandedItems, setExpandedItems] = useState([]);
  const itemsWithIcons = [
    "Basic",
    "Buttons",
    "Views",
    "Flex Layout",
    "Containers",
    "Media",
    "Lists & Data",
    "Section List",
    "Swipeable List",
    "Table",
    "Form Inputs",
    "Form Controls",
    "Picker",
    "Maps",
    "Map Marker Cluster",
    "Swipesrs",
    "Swiper",
    "Deck Swiper",
    "Tab View",
    "Text Elements",
    "Overlays",
    "Action Sheet",
    "Utilities",
    "Tab Navigator",
  ];

  const toggleExpand = (url) => {
    // console.log(url);
    if (expandedItems.includes(url)) {
      setExpandedItems(expandedItems.filter((item) => item !== url));
    } else {
      // navigate(`/${url}`);
      setExpandedItems([...expandedItems, url]);
    }
  };
  const renderList = (items) => (
    <ul className="nav">
      {items?.map((item, index) => (
        <>
          {console.log(item?.depth, "items?.depth")}

          <Link to={`/docs/${item.url}`} key={index}>
            <li key={item.url} className="nav-item">
              <span
                onClick={() => toggleExpand(item.url)}
                className={`nav-link ${
                  specialItems.includes(item.name)
                    ? "special"
                    : item?.depth === 2
                    ? "nav-children "
                    : item?.depth === 3 && "nav-children3"
                }
                }`}
              >
                {itemsWithIcons.includes(item.name) && (
                  <ArrowForwardIosIcon
                    className={` ${
                      expandedItems.includes(item.url) ? "rotated" : "icon"
                    }`}
                  />
                )}
                {item.name}
              </span>
              {item.children &&
                expandedItems.includes(item.url) &&
                renderList(item.children)}
            </li>
          </Link>
        </>
      ))}
    </ul>
  );
  return (
    <div className="main_wrapper">
      <div className="container">
        <Row>
          <Col className="sidebar">{renderList(listItems)}</Col>

          {documentLoading ? (
            <Col className="loader-container">
              <Loader />
            </Col>
          ) : (
            <>
              <Col className="midsection">
                <Content
                  tabs={tabs}
                  navigateForward={navigateForward}
                  navigateBackward={navigateBackward}
                  docId={docId}
                  listItems={listItems}
                />
                {/* <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom"></div>
                <div className="button_group ">
                  {prevItem && (
                    <div className="button_backward" onClick={navigateBackward}>
                      {prevItem.name}
                    </div>
                  )}
                  {nextItem && (
                    <div className="button_forward" onClick={navigateForward}>
                      {nextItem.name}
                    </div>
                  )}
                </div> */}
              </Col>
              <Col className="lastsection">
                <div>
                  <ul className="flex-column table-list display-flex">
                    <div className="flex-table-icon">
                      <FormatAlignLeftIcon />
                      <li>TABLE OF CONTENTS</li>
                    </div>
                  </ul>
                  <li className="table-children">
                    <ul>
                      {selectedData?.headings?.map((item) => (
                        <li>
                          <Link to={`/docs/${docId}#${item.url}`}>
                            {item.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </li>
                </div>
              </Col>
            </>
          )}
        </Row>
      </div>
    </div>
  );
};

export default MainDocsPage;

{
  /* <ul className="nav flex-column"> */
}
// {listItems?.map((item, index) => (
// <li className="nav-item">
//   <Link
//     to={`/docs/${item.url}`}
//     className={`nav-link ${
//       specialItems.includes(item.name) ? "special" : ""
//     }`}
//   >
//     {item.name}
//   </Link>

//    {item.children?.map((data) => (
//     <Link to={`/docs/${data.url}`} className="nav-link">
//       {data.name}
//     </Link>
//   ))}
// </li>
// ))}

{
  /* {listItems.map((item, index) => ( 
                <Link to={`/docs/${item.url}`} key={index}>
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        specialItems.includes(item.name) ? "special" : ""
                      }`}
                    >
                      {item.name}
                    </a>
                  </li>
                </Link>
              ))} */
}
{
  /* </ul> */
}
