import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Draggable from "react-draggable";
import demoImg from "../../assets/Image/demoImg.svg";
import {
  resetCanvasData,
  setDefaultValues,
  getScreenData,
  setDivWidthHeight,
  setFolderWidthHeight,
  setSelectedFolder,
  setFolderStructure,
} from "../../redux/actions/defaultActions";
import { useNavigate } from "react-router-dom";
import TextArea from "./RightbarTab/FunctionComponent/TextArea";
import GoogleFontLoader from "react-google-font-loader";
import ResponsiveDateTimePickers from "./RightbarTab/rightTabCommon/DateTimePicker";
import Modal from "react-responsive-modal";
import DatePicker from "./RightbarTab/FunctionComponent/DatePicker";
import ButtonComponent from "./RightbarTab/FunctionComponent/Button";
import UpdateMyValues from "../../common/customHooks/UpdateMyValues";
import TextArea2 from "./RightbarTab/FunctionComponent/TextArea2";
import Drawer from "./RightbarTab/FunctionComponent/Drawer";
import DropDown from "./RightbarTab/FunctionComponent/DropDown";
import ModalEditor from "./RightbarTab/FunctionComponent/ModalEditor";
// import {
//   exportComponentAsJPEG,
//   exportComponentAsPDF,
//   exportComponentAsPNG,
// } from "react-component-export-image";

const CanvasDisplay = ({
  screen,
  setSettingBar,
  uploadMyImage,
  selectedPercentage,
  orientation,
  setDivWidthHeight,
  divs,
  isDraftPreview,
  exportComponentAsJPEG,
  componentRef,
  myApiKeys,
}) => {
  const [backgroundColor, setBackgroundColor] = useState("#ffffff");
  const [opens, setOpens] = useState(false);
  const [valuesButton, setValuesButton] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [text, setText] = useState("");

  const { addCanvasImage } = useSelector((state) => state.auth);
  const {
    defaultValues,
    selectedCanvas,
    addNewScreenList,
    deleteMyScreen,
    folderStructure,
    selectedFolder,
    editScreenData,
  } = useSelector((state) => state.default);

  // selectedFolder?.type === "datepicker" &&
  //   selectedFolder?.dateData &&
  //   selectedFolder?.dateData

  const getMyScreenData = useSelector(
    (state) => state?.default?.getMyScreenData
  );
  const myJsonData = useSelector((state) => state?.flashlist?.myJsonData);

  const [isRendered, setIsRendered] = useState(false);
  // const componentRef = useRef(null);

  const pageLoading = useSelector((state) => state?.default?.pageLoading);

  const selectedFolderId = useSelector((state) => state.default.selectedFolder);

  const setSelectedFolderAction = (folder) =>
    dispatch(setSelectedFolder(folder));

  const setFolderStructureAction = (structure) =>
    dispatch(setFolderStructure(structure));

  const { handleStyleUpdate } = UpdateMyValues(
    setFolderStructureAction,
    setSelectedFolderAction
  );

  const [screenSize, setScreenSize] = useState(100);

  // Function to handle background color change
  const handleBackgroundColorChange = (color) => {
    setBackgroundColor(color.hex);
  };

  useEffect(() => {
    if (addCanvasImage) {
      dispatch(
        setDefaultValues({
          ...defaultValues,
          imgValue: addCanvasImage?.imgValue,
          textValue: addCanvasImage?.textValue,
          viewValue: addCanvasImage?.viewValue,
        })
      );
    }
  }, [addCanvasImage, selectedCanvas]);

  useEffect(() => {
    if (addCanvasImage && selectedCanvas) {
      const canvas = document.getElementById(
        `canvas-container-${selectedCanvas.screen._id}`
      );

      if (canvas) {
        canvas.style.width = `${390 * (selectedPercentage / 100)}px`;
        canvas.style.height = `${750 * (selectedPercentage / 100)}px`;
      }
    }
  }, [addCanvasImage, selectedCanvas, selectedPercentage, backgroundColor]);

  const projectId = localStorage.getItem("project_id");

  const screen_Id = localStorage.getItem("screen_Id");
  const selectedVersion = localStorage.getItem("sdkVersion");

  useEffect(() => {
    dispatch(getScreenData(projectId, screen_Id));
  }, [projectId, screen_Id, addNewScreenList, deleteMyScreen, editScreenData]);

  const uploadImghandler = (e, item, index) => {
    if (item?.customDataType === false) {
      e.stopPropagation();
      setSettingBar("set-3");
      dispatch(setSelectedFolder(item));
    }
  };
  const uploadButtonhandler = (e, item, index) => {
    e.stopPropagation();
    dispatch(setSelectedFolder(item));

    if (item?.customDataType === false) {
      e.stopPropagation();
      setSettingBar("set-3");
      dispatch(setSelectedFolder(item));
    }
  };

  const containerStyle = {
    transform: `scale(${selectedPercentage / 100})`,
  };

  const uploadDropdownHandler = (e, item, index) => {
    if (selectedFolder?.id !== item?.id) {
      dispatch(setSelectedFolder(item));
      // e.stopPropagation();
    }

    // if (item?.customDataType === false) {
    //   // e.stopPropagation();
    //   setSettingBar("set-3");
    //   dispatch(setSelectedFolder(item));
    // }
  };
  const uploadBottomSheetsHandler = (e, item, index) => {
    e.stopPropagation();
    dispatch(setSelectedFolder(item));

    if (item?.customDataType === false) {
      e.stopPropagation();
      setSettingBar("set-1");
      dispatch(setSelectedFolder(item));
    }
  };
  const viewItemSelect = (item) => {
    dispatch(setSelectedFolder(item));
  };
  const uploadTexthandler = (item, index) => {
    setSettingBar("set-3");
    dispatch(setSelectedFolder(item));
  };

  const handleDivClick = (e, item) => {
    e.stopPropagation();
    dispatch(setSelectedFolder(item));
  };
  const handlePressableClick = (e, item) => {
    e.stopPropagation();
    dispatch(setSelectedFolder(item));
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      adjustRows(e.target);
    }
  };

  const adjustRows = (textarea) => {
    const { scrollHeight, clientHeight } = textarea;
    if (scrollHeight > clientHeight) {
      textarea.rows += 1;
    }
  };

  const viewBlockHandler = (e, index) => {
    dispatch(
      setDefaultValues({
        ...defaultValues,
        viewBlockIndex: index,
        selectedComponent: "view",
        selectedComponentValue: index,
      })
    );
  };

  const openthedate = (item) => {
    setOpens(true);
    dispatch(setSelectedFolder(item));
  };

  const closeDataModal = () => {
    setOpens(false);
  };
  const pickerRef = useRef(null);

  useEffect(() => {
    if (pickerRef.current) {
      const okButton = pickerRef.current.querySelector(
        'button[aria-label="OK"]'
      );
      if (okButton) {
        okButton.addEventListener("click", closeDataModal);
      }
    }
  }, [pickerRef.current]);

  const datetimeOnChange = (newDate) => {
    handleStyleUpdate(folderStructure, selectedFolder, "dateData", newDate);
  };

  const RenderComponent = (item, index) => {
    const [textValue, setTextValue] = useState(item.canvas_text);
    const [textValue2, setTextValue2] = useState(item.canvas_text_area);

    const prevTextValue = useRef(item.canvas_text);
    const prevTextValue2 = useRef(item.canvas_text_area);

    const generateUniqueId = () => {
      const timestamp = Date.now(); // Current time in milliseconds since Unix epoch
      const randomNum = Math.floor(Math.random() * 10000); // Random number between 0 and 9999
      return `${timestamp}-${randomNum}`;
    };

    const createGrid = (items, cols) => {
      const rows = [];
      for (let i = 0; i < items.length; i += cols) {
        rows.push(items.slice(i, i + cols));
      }
      return rows;
    };

    const { folderStructure, selectedFolder } = useSelector(
      (state) => state.default
    );

    // Usage:

    const setSelectedFolderAction = (folder) =>
      dispatch(setSelectedFolder(folder));

    const setFolderStructureAction = (structure) =>
      dispatch(setFolderStructure(structure));

    const updateAlignChanges = (folders, folderId, align) => {
      return folders.map((folder) => {
        if (folder.id === folderId) {
          return { ...folder, canvas_text: align };
        } else if (folder.children && folder.children.length > 0) {
          const updatedChildren = updateAlignChanges(
            folder.children,
            folderId,
            align
          );
          return { ...folder, children: updatedChildren };
        }
        return folder;
      });
    };

    useEffect(() => {
      if (selectedFolder?.type === "text") {
        if (textValue !== prevTextValue.current) {
          const updatedSelectedFolder = {
            ...selectedFolder,
            canvas_text: textValue,
          };
          const updatedFolderStructure = updateAlignChanges(
            folderStructure,
            selectedFolder.id,
            textValue
          );
          setFolderStructureAction(updatedFolderStructure);
          // setSelectedFolderAction(updatedSelectedFolder);
        }
        prevTextValue.current = textValue;
      }
    }, [textValue, selectedFolder, folderStructure]);

    useEffect(() => {
      if (selectedFolder?.type === "textField") {
        if (textValue2 !== prevTextValue2.current) {
          handleStyleUpdate(
            folderStructure,
            selectedFolder,
            "canvas_text_area",
            textValue2
          );
        }
      }
    }, [textValue2, selectedFolder, folderStructure]);

    useEffect(() => {
      if (item?.type === "image" && item?.image) {
        const img = new Image();
        img.onload = () => {
          setIsRendered(true);
        };
        img.src = item.image;
      }
    }, [item]);

    if (item?.type === "image" && item?.size && item.size.length > 0) {
      const width = parseInt(item.size[0].width);
      const height = parseInt(item.size[1].height);
      const min_height = parseInt(item.size[3]?.min_height);
      const min_width = parseInt(item.size[2]?.min_width);
      const max_height = parseInt(item.size[5]?.max_height);
      const max_width = parseInt(item.size[4]?.max_width);
      const widthUnit = item.size[0]?.unit;
      const heightUnit = item.size[1]?.unit;
      const min_heightunit = item.size[3]?.unit;
      const min_widthunit = item.size[2]?.unit;
      const max_heightunit = item.size[5]?.unit;
      const max_widthunit = item.size[4]?.unit;

      const marginStyle = item.margin
        ? {
            margin: item.margin[0].all + item.margin[0].unit,
            marginLeft: item.margin[1].left + item.margin[1].unit,
            marginTop: item.margin[2].top + item.margin[2].unit,
            marginRight: item.margin[3].right + item.margin[3].unit,
            marginBottom: item.margin[4].bottom + item.margin[4].unit,
          }
        : {};

      const RadiusStyle = item.borderRadius
        ? {
            borderRadius: item.borderRadius[0].all + item.borderRadius[0].unit,
            borderRadiusLeft:
              item.borderRadius[1].topLeft + item.borderRadius[1].unit,
            borderRadiusTop:
              item.borderRadius[2].topRight + item.borderRadius[2].unit,
            borderRadiusRight:
              item.borderRadius[3].bottomLeft + item.borderRadius[3].unit,
            borderRadiusBottom:
              item.borderRadius[4].bottomRight + item.borderRadius[4].unit,
          }
        : {};
      const borderWidthStyle = item.borderWidth
        ? {
            borderWidth: item.borderWidth[0].all + item.borderWidth[0].unit,
            borderLeftWidth:
              item.borderWidth[1].left + item.borderWidth[1].unit,
            borderTopWidth: item.borderWidth[2].top + item.borderWidth[2].unit,
            borderRightWidth:
              item.borderWidth[3].right + item.borderWidth[3].unit,
            borderBottomWidth:
              item.borderWidth[4].bottom + item.borderWidth[4].unit,
          }
        : {};

      return (
        <img
          src={item.image ? item.image : demoImg}
          alt={item.name}
          key={item?.id + index * 3}
          className={
            isDraftPreview && selectedFolder?.id === item?.id
              ? "selectBits_img"
              : "img_block"
          }
          style={{
            width: `${width}${widthUnit}`,
            height: `${height}${heightUnit}`,
            minHeight: `${min_height}${min_heightunit}`,
            minWidth: `${min_width}${min_widthunit}`,
            maxHeight: `${max_height}${max_heightunit}`,
            maxWidth: `${max_width}${max_widthunit}`,
            ...marginStyle,
            ...RadiusStyle,
            ...borderWidthStyle,
            borderStyle: item.id ? item.borderStyle : null,
            borderColor: item.id ? item.borderColor : null,
          }}
          onClick={(e) => isDraftPreview && uploadImghandler(e, item)}
        />
      );
    }

    if (item?.type === "text") {
      return (
        <>
          <GoogleFontLoader
            fonts={[{ font: item?.fontFamily ? item?.fontFamily : "Arial" }]}
          />

          <TextArea
            item={item}
            index={index}
            selectedFolder={selectedFolder}
            setTextValue={setTextValue}
            textValue={textValue}
            setSelectedFolderAction={setSelectedFolderAction}
            handleKeyDown={handleKeyDown}
            readOnly={!isDraftPreview}
            myApiKeys={myApiKeys}
            folderStructure={folderStructure}
            setFolderStructureAction={setFolderStructureAction}
          />
        </>
      );
    }

    if (item?.type === "textField") {
      return (
        <>
          <GoogleFontLoader
            fonts={[{ font: item?.fontFamily ? item?.fontFamily : "Arial" }]}
          />
          {/* <TextArea
            handleKeyDown={handleKeyDown}
             key={`item-${item?.id+index}`}
            item={item}
            index={index}
            selectedFolder={selectedFolder}
            setTextValue={setTextValue}
            adjustRows={adjustRows}
            textValue={textValue}
          /> */}

          <TextArea2
            item={item}
            index={index}
            selectedFolder={selectedFolder}
            setTextValue2={setTextValue2}
            // setTextValue2={setTextValue2}
            textValue2={textValue2}
            // onClicksss={onClicksss}
            setSelectedFolderAction={setSelectedFolderAction}
            handleKeyDown={handleKeyDown}
            // disabled={!isDraftPreview} // Disable text area in web preview mode
            readOnly={!isDraftPreview}
            myApiKeys={myApiKeys}
          />
        </>
      );
    }

    if (item?.type === "datepicker") {
      return (
        <>
          <GoogleFontLoader
            fonts={[{ font: item?.fontFamily ? item?.fontFamily : "Arial" }]}
          />

          <DatePicker
            key={index}
            keys={index}
            onClicks={openthedate}
            item={item}
            opens={opens}
            closeDataModal={closeDataModal}
            datetimeOnChange={datetimeOnChange}
            selectedFolder={selectedFolder}
            readOnly={!isDraftPreview}
          />
        </>
      );
    }

    if (item?.type === "button") {
      return (
        <>
          <ButtonComponent
            uploadButtonhandler={uploadButtonhandler}
            item={item}
            values={valuesButton}
            setValues={setValuesButton}
            isDraftPreview={!isDraftPreview}
          />
        </>
      );
    }

    if (item?.type === "dropdown") {
      return (
        <>
          <DropDown
            uploadDropdownHandler={uploadDropdownHandler}
            item={item}
            values={valuesButton}
            setValues={setValuesButton}
            isDraftPreview={!isDraftPreview}
          />
        </>
      );
    }

    if (item?.type === "view" && item.size && item.size.length > 0) {
      const width = parseInt(item.size[0].width);
      const height = parseInt(item.size[1].height);
      const min_height = parseInt(item.size[3]?.min_height);
      const min_width = parseInt(item.size[2]?.min_width);
      const max_height = parseInt(item.size[5]?.max_height);
      const max_width = parseInt(item.size[4]?.max_width);
      const widthUnit = item.size[0]?.unit;
      const heightUnit = item.size[1]?.unit;
      const min_heightunit = item.size[3]?.unit;
      const min_widthunit = item.size[2]?.unit;
      const max_heightunit = item.size[5]?.unit;
      const max_widthunit = item.size[4]?.unit;

      const marginStyle = item.margin
        ? {
            margin: item.margin[0].all + item.margin[0].unit,
            marginLeft: item.margin[1].left + item.margin[1].unit,
            marginTop: item.margin[2].top + item.margin[2].unit,
            marginRight: item.margin[3].right + item.margin[3].unit,
            marginBottom: item.margin[4].bottom + item.margin[4].unit,
          }
        : {};
      const paddingStyle = item.padding
        ? {
            padding: item.padding[0].all + item.padding[0].unit,
            paddingLeft: item.padding[1].left + item.padding[1].unit,
            paddingTop: item.padding[2].top + item.padding[2].unit,
            paddingRight: item.padding[3].right + item.padding[3].unit,
            paddingBottom: item.padding[4].bottom + item.padding[4].unit,
          }
        : {};

      const radiusStyle = item.borderRadius
        ? {
            borderTopLeftRadius:
              item.borderRadius[1].topLeft + item.borderRadius[1].unit,
            borderTopRightRadius:
              item.borderRadius[2].topRight + item.borderRadius[2].unit,
            borderBottomLeftRadius:
              item.borderRadius[3].bottomLeft + item.borderRadius[3].unit,
            borderBottomRightRadius:
              item.borderRadius[4].bottomRight + item.borderRadius[4].unit,
          }
        : {};
      const borderWidthStyle = item.borderWidth
        ? {
            borderWidth: item.borderWidth[0].all + item.borderWidth[0].unit,
            borderLeftWidth:
              item.borderWidth[1].left + item.borderWidth[1].unit,
            borderTopWidth: item.borderWidth[2].top + item.borderWidth[2].unit,
            borderRightWidth:
              item.borderWidth[3].right + item.borderWidth[3].unit,
            borderBottomWidth:
              item.borderWidth[4].bottom + item.borderWidth[4].unit,
          }
        : {};

      return (
        <div
          key={item?.id + index * 3999}
          className={
            isDraftPreview && selectedFolder?.id === item?.id
              ? "selectView_comp"
              : "view_comp"
          }
          style={{
            width: `${widthUnit ? width + widthUnit : width}`,
            height: `${heightUnit ? height + heightUnit : height}`,
            minHeight: `${min_height}${min_heightunit}`,
            minWidth: `${min_width}${min_widthunit}`,
            maxHeight: `${max_height}${max_heightunit}`,
            maxWidth: `${max_width}${max_widthunit}`,

            ...marginStyle,
            ...paddingStyle,
            ...radiusStyle,
            ...borderWidthStyle,

            borderRadius:
              item?.borderRadius?.[0].all > 0
                ? item.borderRadius[0].all + item.borderRadius[0]?.unit
                : radiusStyle,

            display: item.id ? item?.display : "flex",
            flexDirection: item.id ? item?.direction : null,
            alignItems: item.id ? item?.align : null,
            justifyContent: item.id ? item?.justify : null,
            backgroundColor: item.id ? item?.background : null,
            flexWrap: item.id ? item?.flexWrap : null,
            alignContent: "flex-start",
            zIndex: "1",
            borderStyle: item.id ? item.borderStyle : null,
            borderColor: item.id ? item.borderColor : null,
            overflow: "hidden",
            gridColumn: item.id ? item.gridColumn : null,
            gridRow: item.id ? item.gridRow : null,
            gridTemplateColumns: item.id ? item.gridTemplateColumns : null,
          }}
          onClick={(e) => isDraftPreview && handleDivClick(e, item)}
        >
          {item.children.map((child, index) => RenderComponent(child, index))}
        </div>
      );
    }
    if (item?.type === "pressable" && item.size && item.size.length > 0) {
      const width = parseInt(item.size[0].width);
      const height = parseInt(item.size[1].height);
      const min_height = parseInt(item.size[3]?.min_height);
      const min_width = parseInt(item.size[2]?.min_width);
      const max_height = parseInt(item.size[5]?.max_height);
      const max_width = parseInt(item.size[4]?.max_width);
      const widthUnit = item.size[0]?.unit;
      const heightUnit = item.size[1]?.unit;
      const min_heightunit = item.size[3]?.unit;
      const min_widthunit = item.size[2]?.unit;
      const max_heightunit = item.size[5]?.unit;
      const max_widthunit = item.size[4]?.unit;

      const marginStyle = item.margin
        ? {
            margin: item.margin[0].all + item.margin[0].unit,
            marginLeft: item.margin[1].left + item.margin[1].unit,
            marginTop: item.margin[2].top + item.margin[2].unit,
            marginRight: item.margin[3].right + item.margin[3].unit,
            marginBottom: item.margin[4].bottom + item.margin[4].unit,
          }
        : {};
      const paddingStyle = item.padding
        ? {
            padding: item.padding[0].all + item.padding[0].unit,
            paddingLeft: item.padding[1].left + item.padding[1].unit,
            paddingTop: item.padding[2].top + item.padding[2].unit,
            paddingRight: item.padding[3].right + item.padding[3].unit,
            paddingBottom: item.padding[4].bottom + item.padding[4].unit,
          }
        : {};

      const radiusStyle = item.borderRadius
        ? {
            borderTopLeftRadius:
              item.borderRadius[1].topLeft + item.borderRadius[1].unit,
            borderTopRightRadius:
              item.borderRadius[2].topRight + item.borderRadius[2].unit,
            borderBottomLeftRadius:
              item.borderRadius[3].bottomLeft + item.borderRadius[3].unit,
            borderBottomRightRadius:
              item.borderRadius[4].bottomRight + item.borderRadius[4].unit,
          }
        : {};
      const borderWidthStyle = item.borderWidth
        ? {
            borderWidth: item.borderWidth[0].all + item.borderWidth[0].unit,
            borderLeftWidth:
              item.borderWidth[1].left + item.borderWidth[1].unit,
            borderTopWidth: item.borderWidth[2].top + item.borderWidth[2].unit,
            borderRightWidth:
              item.borderWidth[3].right + item.borderWidth[3].unit,
            borderBottomWidth:
              item.borderWidth[4].bottom + item.borderWidth[4].unit,
          }
        : {};

      return (
        <div
          key={item?.id + index * 3999}
          className={
            isDraftPreview && selectedFolder?.id === item?.id
              ? "selectView_comp"
              : "view_comp"
          }
          style={{
            width: `${widthUnit ? width + widthUnit : width}`,
            height: `${heightUnit ? height + heightUnit : height}`,
            minHeight: `${min_height}${min_heightunit}`,
            minWidth: `${min_width}${min_widthunit}`,
            maxHeight: `${max_height}${max_heightunit}`,
            maxWidth: `${max_width}${max_widthunit}`,

            ...marginStyle,
            ...paddingStyle,
            ...radiusStyle,
            ...borderWidthStyle,

            borderRadius:
              item?.borderRadius?.[0].all > 0
                ? item.borderRadius[0].all + item.borderRadius[0]?.unit
                : radiusStyle,

            display: item.id ? item?.display : "flex",
            flexDirection: item.id ? item?.direction : null,
            alignItems: item.id ? item?.align : null,
            justifyContent: item.id ? item?.justify : null,
            backgroundColor: item.id ? item?.background : null,
            flexWrap: item.id ? item?.flexWrap : null,
            alignContent: "flex-start",
            zIndex: "1",
            borderStyle: item.id ? item.borderStyle : null,
            borderColor: item.id ? item.borderColor : null,
            overflow: "hidden",
            gridColumn: item.id ? item.gridColumn : null,
            gridRow: item.id ? item.gridRow : null,
            gridTemplateColumns: item.id ? item.gridTemplateColumns : null,
          }}
          onClick={(e) => isDraftPreview && handlePressableClick(e, item)} // Only trigger click handler in draft preview mode
        >
          {item.children.map((child, index) => RenderComponent(child, index))}
        </div>
      );
    }

    if (item?.type === "bottomSheets" && item.size && item.size.length > 0) {
      const width = parseInt(item.size[0].width);
      const height = parseInt(item.size[1].height);
      const min_height = parseInt(item.size[3]?.min_height);
      const min_width = parseInt(item.size[2]?.min_width);
      const max_height = parseInt(item.size[5]?.max_height);
      const max_width = parseInt(item.size[4]?.max_width);
      const widthUnit = item.size[0]?.unit;
      const heightUnit = item.size[1]?.unit;
      const min_heightunit = item.size[3]?.unit;
      const min_widthunit = item.size[2]?.unit;
      const max_heightunit = item.size[5]?.unit;
      const max_widthunit = item.size[4]?.unit;

      const marginStyle = item.margin
        ? {
            margin: item.margin[0].all + item.margin[0].unit,
            marginLeft: item.margin[1].left + item.margin[1].unit,
            marginTop: item.margin[2].top + item.margin[2].unit,
            marginRight: item.margin[3].right + item.margin[3].unit,
            marginBottom: item.margin[4].bottom + item.margin[4].unit,
          }
        : {};
      const paddingStyle = item.padding
        ? {
            padding: item.padding[0].all + item.padding[0].unit,
            paddingLeft: item.padding[1].left + item.padding[1].unit,
            paddingTop: item.padding[2].top + item.padding[2].unit,
            paddingRight: item.padding[3].right + item.padding[3].unit,
            paddingBottom: item.padding[4].bottom + item.padding[4].unit,
          }
        : {};

      const radiusStyle = item.borderRadius
        ? {
            borderTopLeftRadius:
              item.borderRadius[1].topLeft + item.borderRadius[1].unit,
            borderTopRightRadius:
              item.borderRadius[2].topRight + item.borderRadius[2].unit,
            borderBottomLeftRadius:
              item.borderRadius[3].bottomLeft + item.borderRadius[3].unit,
            borderBottomRightRadius:
              item.borderRadius[4].bottomRight + item.borderRadius[4].unit,
          }
        : {};
      const borderWidthStyle = item.borderWidth
        ? {
            borderWidth: item.borderWidth[0].all + item.borderWidth[0].unit,
            borderLeftWidth:
              item.borderWidth[1].left + item.borderWidth[1].unit,
            borderTopWidth: item.borderWidth[2].top + item.borderWidth[2].unit,
            borderRightWidth:
              item.borderWidth[3].right + item.borderWidth[3].unit,
            borderBottomWidth:
              item.borderWidth[4].bottom + item.borderWidth[4].unit,
          }
        : {};

      return (
        // <div
        //   key={item?.id + index * 3999}
        //   className={
        //     isDraftPreview && selectedFolder?.id === item?.id
        //       ? "selectView_comp"
        //       : "view_comp"
        //   }
        //   style={{
        //     width: `${widthUnit ? width + widthUnit : width}`,
        //     height: `${heightUnit ? height + heightUnit : height}`,
        //     minHeight: `${min_height}${min_heightunit}`,
        //     minWidth: `${min_width}${min_widthunit}`,
        //     maxHeight: `${max_height}${max_heightunit}`,
        //     maxWidth: `${max_width}${max_widthunit}`,
        //     position: "absolute",
        //     top: "67vh",
        //     ...marginStyle,
        //     ...paddingStyle,
        //     ...radiusStyle,
        //     ...borderWidthStyle,

        //     borderRadius:
        //       item?.borderRadius?.[0].all > 0
        //         ? item.borderRadius[0].all + item.borderRadius[0]?.unit
        //         : radiusStyle,

        //     display: item.id ? item?.display : "flex",
        //     flexDirection: item.id ? item?.direction : null,
        //     alignItems: item.id ? item?.align : null,
        //     justifyContent: item.id ? item?.justify : null,
        //     backgroundColor: item.id ? item?.background : null,
        //     flexWrap: item.id ? item?.flexWrap : null,
        //     alignContent: "flex-start",
        //     zIndex: "1",
        //     borderStyle: item.id ? item.borderStyle : null,
        //     borderColor: item.id ? item.borderColor : null,
        //     overflow: "hidden",
        //     gridColumn: item.id ? item.gridColumn : null,
        //     gridRow: item.id ? item.gridRow : null,
        //     gridTemplateColumns: item.id ? item.gridTemplateColumns : null,
        //   }}
        //   onClick={(e) => isDraftPreview && handleDivClick(e, item)}
        // >
        //   {item.children.map((child, index) => RenderComponent(child, index))}
        // </div>
        <>
          <Drawer
            RenderComponent={RenderComponent}
            item={item}
            uploadBottomSheetsHandler={uploadBottomSheetsHandler}
            isDraftPreview={!isDraftPreview}
          />
        </>
      );
    }

    if (item?.type === "fetch") {
      return (
        <>
          <div
            key={item?.id + index * 3}
            className={
              selectedFolder?.id === item?.id ? "selectView_comp" : "view_comp"
            }
            style={{
              display: "flex",
              flexDirection: item.id ? item?.direction : null,
              alignItems: item.id ? item?.align : null,
              justifyContent: item.id ? item?.justify : null,
              backgroundColor: item.id ? item?.background : null,
              flexWrap: item.id ? item?.flexWrap : null,
              alignContent: "flex-start",
              zIndex: "1",
              borderStyle: item.id ? item.borderStyle : null,
              borderColor: item.id ? item.borderColor : null,
              overflow: "hidden",
            }}
          >
            {item.children.map((child, index) => RenderComponent(child, index))}
          </div>
        </>
      );
    }

    if (item?.type === "tabview" && item.size && item.size.length > 0) {
      const width = parseInt(item.size[0].width);
      const height = parseInt(item.size[1].height);
      const min_height = parseInt(item.size[3]?.min_height);
      const min_width = parseInt(item.size[2]?.min_width);
      const max_height = parseInt(item.size[5]?.max_height);
      const max_width = parseInt(item.size[4]?.max_width);
      const widthUnit = item.size[0]?.unit;
      const heightUnit = item.size[1]?.unit;
      const min_heightunit = item.size[3]?.unit;
      const min_widthunit = item.size[2]?.unit;
      const max_heightunit = item.size[5]?.unit;
      const max_widthunit = item.size[4]?.unit;

      const marginStyle = item.margin
        ? {
            margin: item.margin[0].all + item.margin[0].unit,
            marginLeft: item.margin[1].left + item.margin[1].unit,
            marginTop: item.margin[2].top + item.margin[2].unit,
            marginRight: item.margin[3].right + item.margin[3].unit,
            marginBottom: item.margin[4].bottom + item.margin[4].unit,
          }
        : {};
      const paddingStyle = item.padding
        ? {
            padding: item.padding[0].all + item.padding[0].unit,
            paddingLeft: item.padding[1].left + item.padding[1].unit,
            paddingTop: item.padding[2].top + item.padding[2].unit,
            paddingRight: item.padding[3].right + item.padding[3].unit,
            paddingBottom: item.padding[4].bottom + item.padding[4].unit,
          }
        : {};

      const radiusStyle = item.borderRadius
        ? {
            borderTopLeftRadius:
              item.borderRadius[1].topLeft + item.borderRadius[1].unit,
            borderTopRightRadius:
              item.borderRadius[2].topRight + item.borderRadius[2].unit,
            borderBottomLeftRadius:
              item.borderRadius[3].bottomLeft + item.borderRadius[3].unit,
            borderBottomRightRadius:
              item.borderRadius[4].bottomRight + item.borderRadius[4].unit,
          }
        : {};
      const borderWidthStyle = item.borderWidth
        ? {
            borderWidth: item.borderWidth[0].all + item.borderWidth[0].unit,
            borderLeftWidth:
              item.borderWidth[1].left + item.borderWidth[1].unit,
            borderTopWidth: item.borderWidth[2].top + item.borderWidth[2].unit,
            borderRightWidth:
              item.borderWidth[3].right + item.borderWidth[3].unit,
            borderBottomWidth:
              item.borderWidth[4].bottom + item.borderWidth[4].unit,
          }
        : {};

      return (
        <div
          key={item?.id + index * 3999}
          className={
            isDraftPreview && selectedFolder?.id === item?.id
              ? "selectView_comp"
              : "view_comp"
          }
          style={{
            width: `${widthUnit ? width + widthUnit : width}`,
            height: `${heightUnit ? height + heightUnit : height}`,
            minHeight: `${min_height}${min_heightunit}`,
            minWidth: `${min_width}${min_widthunit}`,
            maxHeight: `${max_height}${max_heightunit}`,
            maxWidth: `${max_width}${max_widthunit}`,

            ...marginStyle,
            ...paddingStyle,
            ...radiusStyle,
            ...borderWidthStyle,

            borderRadius:
              item?.borderRadius?.[0].all > 0
                ? item.borderRadius[0].all + item.borderRadius[0]?.unit
                : radiusStyle,

            display: item.id ? item?.display : "flex",
            flexDirection: item.id ? item?.direction : null,
            alignItems: item.id ? item?.align : null,
            justifyContent: item.id ? item?.justify : null,
            backgroundColor: item.id ? item?.background : null,
            flexWrap: item.id ? item?.flexWrap : null,
            alignContent: "flex-start",
            zIndex: "1",
            borderStyle: item.id ? item.borderStyle : null,
            borderColor: item.id ? item.borderColor : null,
            overflow: "hidden",
            gridColumn: item.id ? item.gridColumn : null,
            gridRow: item.id ? item.gridRow : null,
            gridTemplateColumns: item.id ? item.gridTemplateColumns : null,
          }}
          onClick={(e) => isDraftPreview && handleDivClick(e, item)}
        >
          <div
            style={{
              color: "#000",
              border: "1px solid black",
              textAlign: "center",
              width: "100%",
              padding: "12px 0px",
            }}
          >
            Title
          </div>
          {item.children.map((child, index) => RenderComponent(child, index))}
        </div>
      );
    }

    if (item?.type === "tabviewitem" && item.size && item.size.length > 0) {
      const width = parseInt(item.size[0].width);
      const height = parseInt(item.size[1].height);
      const min_height = parseInt(item.size[3]?.min_height);
      const min_width = parseInt(item.size[2]?.min_width);
      const max_height = parseInt(item.size[5]?.max_height);
      const max_width = parseInt(item.size[4]?.max_width);
      const widthUnit = item.size[0]?.unit;
      const heightUnit = item.size[1]?.unit;
      const min_heightunit = item.size[3]?.unit;
      const min_widthunit = item.size[2]?.unit;
      const max_heightunit = item.size[5]?.unit;
      const max_widthunit = item.size[4]?.unit;

      const marginStyle = item.margin
        ? {
            margin: item.margin[0].all + item.margin[0].unit,
            marginLeft: item.margin[1].left + item.margin[1].unit,
            marginTop: item.margin[2].top + item.margin[2].unit,
            marginRight: item.margin[3].right + item.margin[3].unit,
            marginBottom: item.margin[4].bottom + item.margin[4].unit,
          }
        : {};
      const paddingStyle = item.padding
        ? {
            padding: item.padding[0].all + item.padding[0].unit,
            paddingLeft: item.padding[1].left + item.padding[1].unit,
            paddingTop: item.padding[2].top + item.padding[2].unit,
            paddingRight: item.padding[3].right + item.padding[3].unit,
            paddingBottom: item.padding[4].bottom + item.padding[4].unit,
          }
        : {};

      const radiusStyle = item.borderRadius
        ? {
            borderTopLeftRadius:
              item.borderRadius[1].topLeft + item.borderRadius[1].unit,
            borderTopRightRadius:
              item.borderRadius[2].topRight + item.borderRadius[2].unit,
            borderBottomLeftRadius:
              item.borderRadius[3].bottomLeft + item.borderRadius[3].unit,
            borderBottomRightRadius:
              item.borderRadius[4].bottomRight + item.borderRadius[4].unit,
          }
        : {};
      const borderWidthStyle = item.borderWidth
        ? {
            borderWidth: item.borderWidth[0].all + item.borderWidth[0].unit,
            borderLeftWidth:
              item.borderWidth[1].left + item.borderWidth[1].unit,
            borderTopWidth: item.borderWidth[2].top + item.borderWidth[2].unit,
            borderRightWidth:
              item.borderWidth[3].right + item.borderWidth[3].unit,
            borderBottomWidth:
              item.borderWidth[4].bottom + item.borderWidth[4].unit,
          }
        : {};

      return (
        <div
          key={item?.id + index * 3999}
          className={
            isDraftPreview && selectedFolder?.id === item?.id
              ? "selectView_comp"
              : "view_comp"
          }
          style={{
            width: `${widthUnit ? width + widthUnit : width}`,
            height: `${heightUnit ? height + heightUnit : height}`,
            minHeight: `${min_height}${min_heightunit}`,
            minWidth: `${min_width}${min_widthunit}`,
            maxHeight: `${max_height}${max_heightunit}`,
            maxWidth: `${max_width}${max_widthunit}`,

            ...marginStyle,
            ...paddingStyle,
            ...radiusStyle,
            ...borderWidthStyle,

            borderRadius:
              item?.borderRadius?.[0].all > 0
                ? item.borderRadius[0].all + item.borderRadius[0]?.unit
                : radiusStyle,

            display: item.id ? item?.display : "flex",
            flexDirection: item.id ? item?.direction : null,
            alignItems: item.id ? item?.align : null,
            justifyContent: item.id ? item?.justify : null,
            backgroundColor: item.id ? item?.background : null,
            flexWrap: item.id ? item?.flexWrap : null,
            alignContent: "flex-start",
            zIndex: "1",
            borderStyle: item.id ? item.borderStyle : null,
            borderColor: item.id ? item.borderColor : null,
            overflow: "hidden",
            gridColumn: item.id ? item.gridColumn : null,
            gridRow: item.id ? item.gridRow : null,
            gridTemplateColumns: item.id ? item.gridTemplateColumns : null,
          }}
          onClick={(e) => isDraftPreview && handleDivClick(e, item)}
        >
          {item.children.map((child, index) => RenderComponent(child, index))}
        </div>
      );
    }

    let grid;
    if (
      item?.type === "flashlist" &&
      item?.children?.length > 0 &&
      item.gridTemplateColumns
    ) {
      grid = createGrid(item?.children, parseInt(item.gridTemplateColumns));
    }

    return (
      <>
        {item?.type === "flashlist" && (
          <div
            key={item?.id + index * 3}
            className={
              selectedFolder?.id === item?.id ? "selectView_comp" : "view_comp"
            }
            style={{
              display: item.id ? item?.display : "flex",
              flexDirection: item.id ? item?.direction : null,
              alignItems: item.id ? item?.align : null,
              justifyContent: item.id ? item?.justify : null,
              backgroundColor: item.id ? item?.background : null,
              flexWrap: item.id ? item?.flexWrap : null,
              alignContent: "flex-start",
              zIndex: "1",
              borderStyle: item.id ? item.borderStyle : null,
              borderColor: item.id ? item.borderColor : null,
              overflow: "hidden",
              gridTemplateColumns: item.id ? item.gridTemplateColumns : null,
            }}
          >
            {item?.gridTemplateColumns
              ? grid?.map((row, index) => (
                  <div key={index * 999} style={{ display: "flex" }}>
                    {row.map((flashlistChild, columnIndex) => (
                      <div key={columnIndex * 999}>
                        {RenderComponent(flashlistChild, columnIndex)}
                      </div>
                    ))}
                  </div>
                ))
              : item.children.map((child, index) =>
                  RenderComponent(child, index)
                )}
          </div>
        )}
      </>
    );

    return null;
  };

  const DraggableComponents = ({ data }) => {
    if (!data) {
      return null;
    }
    return data.flatMap((item, index) => {
      return (
        <div key={index}>
          {item.children
            .filter((child) => {
              if (child.versionAccess) {
                const versions = child.versionAccess.split("/");
                return versions.includes(selectedVersion);
              }
              return true;
            })
            .map((child, index) => {
              return (
                <div key={child?.id + index * 10}>
                  {RenderComponent(child, index)}
                </div>
              );
            })}
        </div>
      );
    });
  };
  const paddingStyle = folderStructure[0]?.padding
    ? {
        padding:
          folderStructure[0]?.padding[0].all +
          folderStructure[0]?.padding[0].unit,
        paddingLeft:
          folderStructure[0]?.padding[1].left +
          folderStructure[0]?.padding[1].unit,
        paddingTop:
          folderStructure[0]?.padding[2].top +
          folderStructure[0]?.padding[2].unit,
        paddingRight:
          folderStructure[0]?.padding[3].right +
          folderStructure[0]?.padding[3].unit,
        paddingBottom:
          folderStructure[0]?.padding[4].bottom +
          folderStructure[0]?.padding[4].unit,
      }
    : null;

  const ComponentToPrint = React.forwardRef((props, ref) => {
    return (
      <>
        <p style={{ color: "#fff" }}>{selectedCanvas?.screen?.name}</p>
        <div
          className={`canvas ${isDraftPreview} ${orientation}`}
          id={`canvas-container-main-${selectedCanvas?.screen?._id}-${screen?.screen?._id}`}
          style={{
            ...containerStyle,
            backgroundColor: selectedFolder?.backgroundColor
              ? selectedFolder?.backgroundColor
              : folderStructure[0]?.background
              ? folderStructure[0]?.background
              : "#fff",
          }}
        >
          {selectedCanvas && (
            <div className="inner">
              <div className="canvas-container">
                <div
                  className="inner_canvas"
                  ref={ref}
                  style={{
                    cursor: "pointer",
                    zIndex: "1",
                    position: "relative",
                    backgroundColor: folderStructure[0]?.id
                      ? folderStructure[0]?.background
                      : null,
                    display: "flex",
                    flexDirection: folderStructure[0]?.id
                      ? folderStructure[0]?.direction
                      : null,
                    alignItems: folderStructure[0]?.id
                      ? folderStructure[0]?.align
                      : null,
                    justifyContent: folderStructure[0]?.id
                      ? folderStructure[0]?.justify
                      : null,

                    ...paddingStyle,
                    height: "100%",
                  }}
                >
                  <DraggableComponents data={folderStructure} />
                </div>

                <div></div>
              </div>
            </div>
          )}
        </div>
        <ModalEditor />
      </>
    );
  });

  return (
    <>
      <ComponentToPrint ref={componentRef} />
    </>
  );
};

export default CanvasDisplay;
