import { toast } from "react-toastify";
import { axiosInstance } from "../axiosInstance";
import {
  SET_BTN_LOADING,
  SET_PAGE_LOADING,
  SET_NEW_SIGNUP_USER,
  VERIFY_OTP_USER,
  SET_NEW_LOGIN_USER,
  SET_NEW_PASSWORD,
  SET_NEW_VERIFY_OTP,
  SET_NEW_USER_PASSWORD,
  FONT_ICON_TYPE,
  SET_CANVAS_DATA,
  SET_PROFILE_USER,
  UPDATE_PROFILE_USER,
  DELETE_PROFILE_USER,
  SET_USER_LOGOUT,
} from "../types";

const config = {
  headers: {
    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
  },
};

export const setBtnLoading = (data) => (dispatch) => {
  dispatch({
    type: SET_BTN_LOADING,
    payload: data,
  });
};

export const setPageLoading = (data) => (dispatch) => {
  dispatch({
    type: SET_PAGE_LOADING,
    payload: data,
  });
};

// export const canvasData = (data) => (dispatch) => {
//   dispatch({
//     type: SET_CANVAS_DATA,
//     payload: data,
//   });
// };
export const setFontFamily = (data) => (dispatch) => {
  dispatch({
    type: FONT_ICON_TYPE,
    payload: data,
  });
};
export const addUser = (data, navigate) => (dispatch) => {
  axiosInstance
    .post("register", data, config)
    .then((res) => {
      if (res.status) {
        toast.success(res?.data?.result?.message);
        localStorage.setItem("email", data.email);
        dispatch({
          type: SET_NEW_SIGNUP_USER,
          payload: res.data,
        });
        navigate("/signup-verifyOTP");
      }
    })
    .catch((error) => {
      if (error.response) {
        console.error("Error sending data to API:", error);
        toast.error(error.response.data.message);
      }
    });
};

export const verifyOTP = (data, navigate) => (dispatch) => {
  axiosInstance
    .post("emailotpverify", data, config)
    .then((res) => {
      if (res.status) {
        const access_token = res?.data?.result?.data?.access_token;
        localStorage.setItem("access_token", access_token);
        toast.success(res?.data?.result?.message);
        dispatch({
          type: VERIFY_OTP_USER,
          payload: res.data,
        });
        navigate("/dashboard");
      }
    })
    .catch((error) => {
      if (error.response) {
        console.error("Error sending data to API:", error);
        toast.error(error.response.data.message);
      }
    });
};

export const loginUser = (data, navigate) => (dispatch) => {
  axiosInstance
    .post("login", data, config)
    .then((res) => {
      if (res.status) {
        localStorage.setItem("access_token", res?.data?.result?.access_token);
        localStorage.setItem("refreshtoken", res?.data?.result?.refreshtoken);
        localStorage.setItem("user_id", res?.data?.result?.data?._id);
        localStorage.setItem(
          "userSdkVersion",
          res?.data?.result?.data?.sdkversion
        );

        console.log(res);
        toast.success(res?.data?.message, {
          position: "top-center",
        });
        dispatch({
          type: SET_NEW_LOGIN_USER,
          payload: res.data,
        });
        navigate("/dashboard");
      }
    })
    .catch((error) => {
      if (error.response) {
        console.error("Error sending data to API:", error);
        toast.error(error.response.data.message, {
          position: "top-center",
        });
      }
    });
};

export const userProfile = (data) => (dispatch) => {
  axiosInstance
    .get("profile", data, config)
    .then((res) => {
      if (res.status) {
        toast.success(res?.data?.message);
        dispatch({
          type: SET_PROFILE_USER,
          payload: res.data,
        });
      }
    })
    .catch((error) => {
      if (error.response) {
        console.error("Error sending data to API:", error);
        toast.error(error.response.data.message);
      }
    });
};

// export const updateProfile = (data) => (dispatch) => {
//   console.log(data);
//   axiosInstance
//     .patch("updateprofile", data, config)
//     .then((res) => {
//       if (res.status) {
//         toast.success(res?.data?.message);
//         dispatch({
//           type: UPDATE_PROFILE_USER,
//           payload: res.data,
//         });
//       }
//     })
//     .catch((error) => {
//       if (error.response) {
//         console.error("Error sending data to API:", error);
//         toast.error(error.response.data.message);
//       }
//     });
// };

// authActions.js

export const updateProfile = (data) => async (dispatch) => {
  try {
    const response = await axiosInstance.patch("updateprofile", data, config);
    if (response.status === 200) {
      toast.success(response?.data?.message);
      dispatch({
        type: UPDATE_PROFILE_USER,
        payload: response.data,
      });
    }
  } catch (error) {
    console.error("Error updating profile:", error);
    // Handle error, dispatch an error action, or show a notification to the user
  }
};

export const resetPassword = (data, navigate) => (dispatch) => {
  axiosInstance
    .post("forgotpassword", data, config)
    .then((res) => {
      if (res.status) {
        localStorage.setItem("myKey", data?.email);
        toast.success(res?.data?.message);
        dispatch({
          type: SET_NEW_PASSWORD,
          payload: res.data,
        });
        navigate("/verifyOTP");
      }
    })
    .catch((error) => {
      if (error.response) {
        console.error("Error sending data to API:", error);
        toast.error(error.response.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    });
};

export const verifyUserOtp = (data, navigate) => (dispatch) => {
  axiosInstance
    .post("otpverify", data, config)
    .then((res) => {
      if (res.status) {
        toast.success(res?.data?.result?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        dispatch({
          type: SET_NEW_VERIFY_OTP,
          payload: res.data,
        });
        navigate("/setPassword");
      }
    })
    .catch((error) => {
      if (error.response) {
        console.error("Error sending data to API:", error);
        toast.error(error.response.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    });
};

export const setUserPassword = (data, navigate) => (dispatch) => {
  axiosInstance
    .post("setPassword", data, config)
    .then((res) => {
      if (res.status) {
        toast.success(res?.data?.result?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        dispatch({
          type: SET_NEW_USER_PASSWORD,
          payload: res.data,
        });
        navigate("/login");
      }
    })
    .catch((error) => {
      if (error.response) {
        console.error("Error sending data to API:", error);
        toast.error(error.response.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    });
};
export const deleteUserProfile = () => (dispatch) => {
  axiosInstance
    .delete(`deleteprofile`, config)
    .then((res) => {
      if (res.status) {
        toast.success(res.message);
        dispatch({
          type: DELETE_PROFILE_USER,
          payload: res,
        });
      } else {
        toast.error(res.data.message);
      }
    })
    .catch((error) => {
      console.error("Error delete Profile:", error);
      toast.error("Error occurred while delete profile");
      dispatch(setPageLoading(false));
    });
};

// export const fetchGoogleFonts = (data) => async (dispatch) => {
//   try {
//     const response = await axiosInstance.get();

//     if (response.status) {
//       // navigate("/login");
//     }
//   } catch (error) {
//     // Handle error
//     console.error("Error fetching Google Fonts:", error);
//   }
// };

export const fetchGoogleFonts = (data) => (dispatch) => {
  axiosInstance
    .get(
      `https://www.googleapis.com/webfonts/v1/webfonts?key=${process.env.REACT_APP_FONT_API_KEY}`
    )
    .then((res) => {
      if (res) {
        toast.success(res?.data?.message);
        dispatch({
          type: FONT_ICON_TYPE,
          payload: res.data.items.map((font) => font.family),
        });
        // return ;
      }
    })
    .catch((error) => {
      if (error.response) {
        console.error("Error sending data to API:", error);
        toast.error(error.response.data.message);
      }
    });
};
