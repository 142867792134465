import React from "react";

const CrossSvg = ({ closeModelPopup }) => {
  // const closeModelPopup = () => {
  //   closeModal(false);
  // };

  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={closeModelPopup}
    >
      <path
        d="M3.975 14.025C4.125 14.175 4.275 14.25 4.5 14.25C4.725 14.25 4.875 14.175 5.025 14.025L9 10.05L12.975 14.025C13.125 14.175 13.35 14.25 13.5 14.25C13.65 14.25 13.875 14.175 14.025 14.025C14.325 13.725 14.325 13.275 14.025 12.975L10.05 9L14.025 5.025C14.325 4.725 14.325 4.275 14.025 3.975C13.725 3.675 13.275 3.675 12.975 3.975L9 7.95L5.025 3.975C4.725 3.675 4.275 3.675 3.975 3.975C3.675 4.275 3.675 4.725 3.975 5.025L7.95 9L3.975 12.975C3.675 13.275 3.675 13.725 3.975 14.025Z"
        fill="white"
      />
    </svg>
  );
};

export default CrossSvg;
