import React, { useEffect, useState } from "react";
import { Modal } from "react-responsive-modal";
import { Downloading, Delete } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { getMyAllAssets } from "../redux/actions/defaultActions";
import { addMyAllAssests } from "../redux/actions/defaultActions";
import { deleteMyAllAssests } from "../redux/actions/defaultActions";
import ModalDelete from "./svgComponents/ModalDelete";
import { toast } from "react-toastify";

const ImageAssetsModal = ({
  openModal,
  onCloseModal1,
  uploadThisImageHandler,
}) => {
  const dispatch = useDispatch();
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const { defaultValues, myAssetList, newAssetCreated, deleteMyAssets } =
    useSelector((state) => state.default);
  const myProjectId = localStorage.getItem("project_id");
  useEffect(() => {
    dispatch(getMyAllAssets(myProjectId));
  }, [newAssetCreated, deleteMyAssets]);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];

    if (!file) {
      return;
    }

    if (file.size > 5 * 1024 * 1024) {
      toast.error("You can only upload images up to 5MB in size");
      return;
    }

    const acceptedTypes = ["image/png", "image/jpeg", "image/gif"];
    if (!acceptedTypes.includes(file.type)) {
      toast.error("Supported file types include: png, jpg, gif, jpeg");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("projectId", myProjectId);
    dispatch(addMyAllAssests(formData));
  };

  const handleDeleteIconClick = (data) => {
    setSelectedImage(data);
    setDeleteConfirmationOpen(true);
  };

  const handleDeleteConfirmation = (confirm, data) => {
    if (confirm) {
      dispatch(deleteMyAllAssests(myProjectId, data));
    }
    setDeleteConfirmationOpen(false);
  };

  return (
    <>
      <Modal
        open={openModal}
        onClose={onCloseModal1}
        center
        classNames={{
          modal: "image_assets_modal",
        }}
      >
        <div className="top_head">
          <h3>Assets</h3>
        </div>

        <div className="body_sec">
          <div className="left_bar">
            <span>Images</span>
          </div>
          <div className="main_bar">
            <p>
              Max upload size is 5mb. Supported file types include: png, jpg,
              gif, jpeg
            </p>

            <div className="image-uploader">
              <div
                className="upload-container"
                onClick={() => document.getElementById("imageInput").click()}
              >
                <Downloading />
                <p className="clr">
                  Select a file <br />
                  <span>or drag and drop here</span>
                </p>
              </div>
              <input
                id="imageInput"
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleImageUpload}
                name="file"
              />
              {myAssetList?.length > 0 && (
                <div className="image-list">
                  <ul className="all_images">
                    {myAssetList?.map((image, index) => (
                      <li key={index}>
                        <div className="image-box">
                          <span
                            className="delete-icon"
                            onClick={() => handleDeleteIconClick(image)}
                          >
                            <Delete />
                          </span>
                          <img
                            src={image.imageurl}
                            alt={`Uploaded ${index + 1}`}
                            style={{ width: "225px", height: "144px" }}
                            onClick={() =>
                              uploadThisImageHandler(
                                image,
                                defaultValues?.imgIndex
                              )
                            }
                          />
                          <p className="image_name">{image.name}</p>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              {/* Delete Confirmation Modal */}
              <Modal
                open={deleteConfirmationOpen}
                onClose={() => handleDeleteConfirmation(false)}
                center
                classNames={{
                  modal: "image_assets_Delete_Modal",
                }}
              >
                <div>
                  <ModalDelete />
                  <p className="bld">Are you sure</p>
                  <p>
                    This asset may be in use and could break <br />
                    one or more screens.
                  </p>
                  <button
                    onClick={() =>
                      handleDeleteConfirmation(true, selectedImage?._id)
                    }
                    className="delete btns2"
                  >
                    Delete
                  </button>
                  <button
                    onClick={() => handleDeleteConfirmation(false)}
                    className="btns2"
                  >
                    Cancel
                  </button>
                </div>
              </Modal>
            </div>
          </div>
        </div>
        <div className="done_btn">
          <div className="main_btn">
            <button className="btn_close" onClick={onCloseModal1}>
              Done
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ImageAssetsModal;
