import React, { useState, useEffect } from "react";
import "./signUp.css";
import { Formik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { GoogleLogin } from "react-google-login";
import { gapi } from "gapi-script";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { axiosInstance } from "../../redux/axiosInstance";
// import { googleInstance } from "../../redux/axiosInstance";
import { useDispatch } from "react-redux";
import { addNewSignUpUser, addUser } from "../../redux/actions/authActions";

export const SignUp = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const clientId = process.env.REACT_APP_CLIENT_ID;

  const [accessToken, setAccessToken] = useState(null);

  //modal states

  const [open, setOpen] = useState(false);
  // const [terms, setTerms] = useState(false);

  const onOpenModal = () => setOpen(true);
  // const onCloseModal = () => setOpen(false);

  const onCloseModal = () => {
    setOpen(false);
    // setTerms(true);
  };

  // const handleTerms = () => {

  //   alert("functin called")
  // }

  useEffect(() => {
    function start() {
      gapi.client
        .init({
          clientId: clientId,
          scope: "profile email",
        })
        .then(() => {
          const authInstance = gapi.auth2.getAuthInstance();
          if (authInstance?.isSignedIn?.get()) {
            setAccessToken(
              authInstance.currentUser.get().getAuthResponse().access_token
            );
          }
        });
    }

    gapi.load("client:auth2", start);
  }, []);

  const handleGoogleSignUp = async (response) => {
    const data = response?.profileObj;

    try {
      // Make an API request to your server to handle the Google sign-up
      const apiResponse = await axiosInstance.post(
        "/googlesignup",
        {
          googleId: data.googleId,
          name: data.name,
          email: data.email,
          terms: data.terms,
          imageUrl: data.imageUrl,
          familyName: data.familyName,
          givenName: data.givenName,
          givenTerms: data.givenTerms,
        },
        {
          withCredentials: true,
        }
      );

      if (apiResponse.status === 200) {
        const newAccessToken = apiResponse?.data?.result?.access_token;

        // Store the access token in local storage
        localStorage.setItem("access_token", newAccessToken);

        toast.success(apiResponse?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });

        navigate("/dashboard");
      } else {
        console.error("Google sign-up failed:", apiResponse.data.message);
      }
    } catch (error) {
      console.error("Error making API request:", error);

      if (
        error?.response?.data?.message ===
        "An account with this email already exists! Please Login to continue"
      ) {
        navigate("/login");
      }
      toast.error(error?.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const onSuccess = (res) => {
    handleGoogleSignUp(res);
  };

  const onFailure = (res) => {};

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const data = {
        fname: values.firstName,
        lastname: values.lastName,
        email: values.email,
        password: values.password,
        confirmPassword: values.confirmPassword,
        terms: values.terms,
        // terms: values.terms,
      };
      const response = await axiosInstance.post("/register", data);

      if (response.status === 201) {
        console.log(response);

        localStorage.setItem("email", values.email);

        toast.success(response?.data?.result?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        navigate("/signup-verifyOTP");
      } else {
        console.error("Unexpected response status:", response.status);
        // Handle unexpected response status as needed
      }
    } catch (error) {
      console.error("Error sending data to API:", error);
      toast.error(error?.response?.data?.result?.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      navigate("/login");
      // Handle error and provide user feedback if necessary
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <div className="con_sign ">
        <div className="Sign  ">
          <h2 className="h2-box"> Create Your Account</h2>

          <Formik
            initialValues={{
              email: "",
              password: "",
              confirmPassword: "",
              firstName: "",
              lastName: "",
              terms: false,
            }}
            validate={(values) => {
              const errors = {};

              const nameRegex = /^[a-zA-Z\s]*$/;

              // First Name validation
              if (!values.firstName) {
                errors.firstName = "First Name is required";
              } else if (!nameRegex.test(values.firstName)) {
                errors.firstName = "Invalid characters in First Name";
              }

              // Last Name validation
              if (!values.lastName) {
                errors.lastName = "Last Name is required";
              } else if (!nameRegex.test(values.lastName)) {
                errors.lastName = "Invalid characters in Last Name";
              }

              // Email validation
              if (!values.email) {
                errors.email = "Email is required";
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = "Please enter a valid email address";
              }

              // Password validation
              if (!values.password) {
                errors.password = "Password is required";
              } else if (values.password.length < 8) {
                errors.password = "Password must be at least 8 characters long";
              } else if (
                !/(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-zA-Z])/.test(
                  values.password
                )
              ) {
                errors.password =
                  "Password must contain at least one number, one special character, and one letter";
              }

              // Confirm Password validation
              if (!values.confirmPassword) {
                errors.confirmPassword = "Confirm Password is required";
              } else if (values.confirmPassword !== values.password) {
                errors.confirmPassword = "Passwords do not match";
              }

              if (!values.terms) {
                errors.terms = (
                  <span>Please agree to the terms and conditions</span>
                );
              }

              return errors;
            }}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form>
                <div className="flex_input">
                  <div className="input_user">
                    <label className="label_text">First Name</label>
                    <input
                      className="input"
                      type="text"
                      name="firstName"
                      placeholder="Enter Your First Name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.firstName}
                      required
                    />
                  </div>
                  <div className="err_msg_resp">
                    {errors.firstName && touched.firstName && (
                      <span className="error-msg">{errors.firstName}</span>
                    )}
                  </div>

                  <div className="input_user">
                    <label className="label_text">Last Name</label>
                    <input
                      className="input"
                      type="text"
                      name="lastName"
                      placeholder="Enter Your Last Name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.lastName}
                      required
                    />
                    <div className="err_msg_resp">
                      {errors.lastName && touched.lastName && (
                        <span className="error-msg">{errors.lastName}</span>
                      )}
                    </div>
                  </div>
                </div>
                {errors.firstName || errors.lastName ? (
                  <div className="error_outside">
                    <div className="err_msg">
                      {errors.firstName && touched.firstName && (
                        <span className="error-msg">{errors.firstName}</span>
                      )}
                    </div>
                    <div className="err_msg">
                      {errors.lastName && touched.lastName && (
                        <span className="error-msg">{errors.lastName}</span>
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )}

                <div className="input_width">
                  <label className="label_text">Email</label>
                  <input
                    className="input"
                    type="email"
                    name="email"
                    placeholder="Enter Your Email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    required
                  />

                  <div className="err_msg">
                    {errors.email && touched.email && (
                      <span className="error-msg">{errors.email}</span>
                    )}
                  </div>
                </div>
                <div className="input_width">
                  <label className="label_text">Password</label>
                  <input
                    className="input"
                    type="password"
                    name="password"
                    placeholder="Enter Your Password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    required
                  />

                  <div className="err_msg">
                    {errors.password && touched.password && (
                      <span className="error-msg">{errors.password}</span>
                    )}
                  </div>
                </div>
                <div className="input_width">
                  <label className="label_text">Confirm Password</label>
                  <input
                    className="input"
                    type="password"
                    name="confirmPassword"
                    placeholder="Enter Your Confirm Password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.confirmPassword}
                    required
                  />

                  <div className="err_msg">
                    {errors.confirmPassword && touched.confirmPassword && (
                      <span className="error-msg">
                        {errors.confirmPassword}
                      </span>
                    )}
                  </div>
                </div>
                <div className="terms_conditions">
                  <label>
                    <input
                      className="check_term_box"
                      type="checkbox"
                      name="terms"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      checked={values.terms}
                    />
                    Agree To
                    <Link
                      to="#"
                      onClick={onOpenModal}
                      style={{ textDecoration: "none" }}
                    >
                      Terms and Conditions
                    </Link>
                  </label>

                  <div className="err_msg">
                    {errors.terms && touched.terms && (
                      <span className="error-msg">{errors.terms}</span>
                    )}
                  </div>
                </div>
                <div className="submiit_btn">
                  <button type="submit" onClick={handleSubmit}>
                    Create Account
                  </button>
                </div>

                <div className="google_btn">
                  <GoogleLogin
                    clientId={clientId}
                    buttonText="Sign Up With Google"
                    onSuccess={onSuccess}
                    onFailure={onFailure}
                    cookiePolicy="single_host_origin"
                    // isSignedIn={true}
                    className="login-google"
                  />
                </div>
                <div className="btm-txt">
                  <Link className="google_link" to="/Login">
                    <p className="lower_p">Login Your Account</p>
                  </Link>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>

      {/* modal  */}

      <Modal
        open={open}
        onClose={onCloseModal}
        center
        classNames={{
          modal: "terms_and_conditions_modal",
        }}
      >
        <h3>Terms and Conditions</h3>
        <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries, but also the leap into electronic typesetting,
          remaining essentially unchanged. It was popularised in the 1960s with
          the release of Letraset sheets containing Lorem Ipsum passages It has
          survived not only five centuries, but also the leap into electronic
          typesetting, remaining essentially unchanged. It was popularised in
          the 1960s with the release of Letraset
        </p>
        {/* <button name="terms" onClick={onCloseModal} className="accept-btn">
          Accept
        </button> */}
      </Modal>
    </>
  );
};
