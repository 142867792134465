import React from "react";

const AlignText = ({ typography2 }) => {
  return (
    <svg
      width="16"
      height="13"
      viewBox="0 0 16 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="brd1"
      style={{
        background: typography2?.align === "stretch" ? "#1A237E" : "none",
      }}
    >
      <path
        d="M1.98153 9H0.294034L3.36648 0.272727H5.31818L8.39489 9H6.70739L4.37642 2.0625H4.30824L1.98153 9ZM2.03693 5.57812H6.6392V6.84801H2.03693V5.57812ZM11.3597 9.1321C10.945 9.1321 10.5714 9.05824 10.239 8.91051C9.90945 8.75994 9.64808 8.53835 9.4549 8.24574C9.26456 7.95312 9.16939 7.59233 9.16939 7.16335C9.16939 6.79403 9.23757 6.48864 9.37393 6.24716C9.5103 6.00568 9.69638 5.8125 9.93217 5.66761C10.168 5.52273 10.4336 5.41335 10.729 5.33949C11.0273 5.26278 11.3356 5.20739 11.6538 5.1733C12.0373 5.13352 12.3484 5.09801 12.587 5.06676C12.8256 5.03267 12.9989 4.98153 13.1069 4.91335C13.2177 4.84233 13.2731 4.73295 13.2731 4.58523V4.55966C13.2731 4.23864 13.1779 3.99006 12.9876 3.81392C12.7972 3.63778 12.5231 3.54972 12.1651 3.54972C11.7873 3.54972 11.4876 3.6321 11.266 3.79688C11.0472 3.96165 10.8995 4.15625 10.8228 4.38068L9.38246 4.17614C9.49609 3.77841 9.68359 3.44602 9.94496 3.17898C10.2063 2.90909 10.5259 2.70739 10.9038 2.57386C11.2816 2.4375 11.6992 2.36932 12.1566 2.36932C12.4719 2.36932 12.7859 2.40625 13.0984 2.48011C13.4109 2.55398 13.6964 2.67614 13.9549 2.84659C14.2134 3.0142 14.4208 3.2429 14.5771 3.53267C14.7362 3.82244 14.8157 4.18466 14.8157 4.61932V9H13.3327V8.10085H13.2816C13.1879 8.28267 13.0558 8.45312 12.8853 8.61222C12.7177 8.76847 12.506 8.89489 12.2504 8.99148C11.9975 9.08523 11.7006 9.1321 11.3597 9.1321ZM11.7603 7.99858C12.07 7.99858 12.3384 7.9375 12.5657 7.81534C12.793 7.69034 12.9677 7.52557 13.0898 7.32102C13.2148 7.11648 13.2773 6.89347 13.2773 6.65199V5.88068C13.229 5.92045 13.1467 5.95739 13.0302 5.99148C12.9165 6.02557 12.7887 6.0554 12.6467 6.08097C12.5046 6.10653 12.364 6.12926 12.2248 6.14915C12.0856 6.16903 11.9648 6.18608 11.8626 6.20028C11.6325 6.23153 11.4265 6.28267 11.2447 6.35369C11.0629 6.42472 10.9194 6.52415 10.8143 6.65199C10.7092 6.77699 10.6566 6.93892 10.6566 7.13778C10.6566 7.42188 10.7603 7.63636 10.9677 7.78125C11.1751 7.92614 11.4393 7.99858 11.7603 7.99858Z"
        fill={typography2?.align === "stretch" ? "white" : "#1A237E"}
      />
      <path
        d="M0 11.3864H15.5785V12.2045H0V11.3864Z"
        fill={typography2?.align === "stretch" ? "white" : "#1A237E"}
      />
    </svg>
  );
};

export default AlignText;
