import imagestar from "../../../assets/Image/imagestar.svg";
import ProgressBar from "react-bootstrap/ProgressBar";

export const FutureCom = () => {
  const now = 60;
  return (
    <>
      <div className="coustom_container">
        <div className="section_fourth">
          <div className="left">
            <div className="box-1">Future</div>
            <div className="box-2">
              <h2>All Your Accounts Are 
                <br/>In One Place</h2>
            </div>
            <div className="box-3">
              <p>
                But I must explain to you how all this mistaken idea of
                denouncing pleasure and praising pain was born and I will give
                you a complete account of the system, and expound great explorer
                of the truth, the master-builder of human pleasure, but because
                those who do not.
              </p>
            </div>
            <div className="box-4">
              <span className="big-size">1.5x</span>

              <span className="small-size">Faster Export File </span>
              <ProgressBar
                className="bar_blue"
                now={now}
                label={`${now}%`}
                visuallyHidden
              />
              <h5>Phone Export Chart PDF</h5>
              <ProgressBar className="bar_yellow" variant="warning" now={100} />
              <h5>Last Export Chart PDF</h5>
            </div>
          </div>
          <div className="right">
            <img src={imagestar} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};
