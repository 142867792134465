// TestimonialSlider.js
import React, { useState, useEffect } from "react";
import { ArrowRightAlt } from "@mui/icons-material";

const testimonialsData = [
  {
    quote:
      "But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound great explorer of the truth, the master-builder explorer of the truth, the master-builder",
    author: "John Doe",
    profession: "UI/UX Designer",
    image:
      "https://www.onstreammedia.com/landing/lead_nurturing/demo_request/img/demo_person.png", // Add the image URL
    rating: 5, // Add the star rating (out of 5)
  },
  {
    quote:
      "But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound great explorer of the truth, the master-builder explorer of the truth, the master-builder",
    author: "John Doe",
    profession: "UI/UX Designer",
    image:
      "https://www.onstreammedia.com/landing/lead_nurturing/demo_request/img/demo_person.png", // Add the image URL
    rating: 5, // Add the star rating (out of 5)
  },

  {
    quote:
      "But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound great explorer of the truth, the master-builder explorer of the truth, the master-builder",
    author: "John Doe",
    profession: "UI/UX Designer",
    image:
      "https://www.onstreammedia.com/landing/lead_nurturing/demo_request/img/demo_person.png", // Add the image URL
    rating: 5, // Add the star rating (out of 5)
  },

  // Add more testimonials as needed
];

const TestimonialSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      nextTestimonial();
    }, 3000);

    return () => clearInterval(intervalId);
  }, [currentIndex]);

  const showTestimonial = (index) => {
    setCurrentIndex(index);
  };

  const nextTestimonial = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonialsData.length);
  };

  const prevTestimonial = () => {
    setCurrentIndex(
      (prevIndex) =>
        (prevIndex - 1 + testimonialsData.length) % testimonialsData.length
    );
  };

  return (
    <div className="slider-container">
      <div
        className="slider"
        style={{ transform: `translateX(${-currentIndex * 100}%)` }}
      >
        {testimonialsData.map((testimonial, index) => (
          <div key={index} className="testimonial">
            <p>{testimonial.quote}</p>

            <div className="testi-author">
              <div className="inner">
                <div className="left-t">
                  <div className="img-sec">
                    <img
                      src={testimonial.image}
                      alt={testimonial.author}
                      className="testimonial-image"
                    />
                  </div>
                  <div className="auth-txt">
                    <span className="author">{testimonial.author}</span>
                    <span className="prof">{testimonial.profession}</span>
                  </div>
                </div>
                <div className="right-t">
                  <div className="rating">
                    {Array.from({ length: testimonial.rating }, (_, i) => (
                      <span key={i} className="star">
                        &#9733;
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="btns">
        <button className="prev-button" onClick={prevTestimonial}>
          <ArrowRightAlt className="left-arr" />
        </button>
        <button className="next-button" onClick={nextTestimonial}>
          <ArrowRightAlt />
        </button>
      </div>
    </div>
  );
};

export default TestimonialSlider;
