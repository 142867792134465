import React, { useEffect, useState } from "react";
import { AddCircle } from "@mui/icons-material";
import GetProjects from "../Projects/GetProjects";
import DeletePopup from "../../Popup/DeletePopup";
import Newstartpopup from "../../Popup/Newstartpopup";
import {
  setFolderStructure,
  setSelectedCanvas,
  setSelectedFolder,
} from "../../redux/actions/defaultActions";
import { useDispatch, useSelector } from "react-redux";
import { myApiData } from "../../redux/actions/flashListActions";
import VersionUpdateModal from "../../common/VersionUpdateModal";
const Dashboard = () => {
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [change, setChange] = useState(false);
  const dispatch = useDispatch();

  const { selectedCanvas } = useSelector((state) => state.default);

  useEffect(() => {
    localStorage.removeItem("screen_Id");
    localStorage.removeItem("screen_name");
    localStorage.removeItem("project_name");
    localStorage.removeItem("project_id");
    localStorage.removeItem("sdkVersion");
  }, []);

  useEffect(() => {
    if (selectedCanvas !== null) {
      dispatch(setSelectedCanvas(null));
      dispatch(setFolderStructure([]));
      dispatch(setSelectedFolder({}));
      dispatch(myApiData([]));
    }
  }, [selectedCanvas]);

  const onOpenModal = () => setOpen(true);

  const onCloseModal = () => setOpenModal(false);
  return (
    <>
      <div>
        <div className="main">
          <div className="coustom_container">
            <div className="p-icon">
              <div className="head">
                <h3>Dashboard</h3>
                <p>Recently Updated Projects</p>
              </div>
              <div className="img-btn-box">
                <button onClick={onOpenModal}>
                  <AddCircle />
                  Starting New Project
                </button>
              </div>
            </div>
            <div className="map-con">
              <GetProjects onOpenModal={onOpenModal} />
            </div>
          </div>
        </div>
      </div>
      {open && <Newstartpopup open={open} setOpen={setOpen} />}
      {change && <DeletePopup change={change} setChange={setChange} />}
    </>
  );
};

export default Dashboard;
