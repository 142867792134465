import React from "react";

const Tabbar = () => {
  return (
    <>
      <svg
        width="20"
        height="20"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.625 1.375H4.875C3.9085 1.375 3.125 2.1585 3.125 3.125V4.875C3.125 5.8415 3.9085 6.625 4.875 6.625H6.625C7.5915 6.625 8.375 5.8415 8.375 4.875V3.125C8.375 2.1585 7.5915 1.375 6.625 1.375Z"
          fill="white"
          stroke="white"
        />
        <path
          d="M18.875 6.625H3.125C2.1585 6.625 1.375 7.4085 1.375 8.375V18.875C1.375 19.8415 2.1585 20.625 3.125 20.625H18.875C19.8415 20.625 20.625 19.8415 20.625 18.875V8.375C20.625 7.4085 19.8415 6.625 18.875 6.625Z"
          fill="#33405E"
          stroke="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.875 10.125H17.125V17.125H4.875V10.125Z"
          fill="#94A3B8"
          stroke="#94A3B8"
        />
      </svg>
    </>
  );
};

export default Tabbar;
