import React from "react";

const ViewSvg = ({ change }) => {
  return (
    <>
      <svg
        width="20"
        height="20"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M22.1668 8.16659V19.8333H25.6668V22.1666H22.1668V25.6666H19.8335V22.1666H8.16683V25.6666H5.8335V22.1666H2.3335V19.8333H5.8335V8.16659H2.3335V5.83325H5.8335V2.33325H8.16683V5.83325H19.8335V2.33325H22.1668V5.83325H25.6668V8.16659H22.1668ZM19.8335 8.16659H8.16683V19.8333H19.8335V8.16659Z"
          fill={change ? "#fff" : "#3c4c6e"}
        />
      </svg>
    </>
  );
};

export default ViewSvg;
