import React, { useState, useRef, useEffect } from "react";
import logoipsum from "../assets/Icons/logoipsum.svg";
import arrow_right_icon from "../assets/Icons/arrow_right_icon.svg";
import search_icon from "../assets/Icons/search_icon.svg";
import { Link, useNavigate } from "react-router-dom";
import ResponsiveDocsNavbar from "./ResponsiveDocsNavbar";
import MenuIcon from "@mui/icons-material/Menu";
import { Modal } from "react-responsive-modal";
const DocsNavbar = ({
  listItems,
  location,
  docId,
  selectedData,
  setSelectedData,
  tabs,
  setTabs,
  documentLoading,
  setDocumentLoading,
  specialItems,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [showNavbar, setShowNavbar] = useState(false);
  const [activeLink, setActiveLink] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const navbarRef = useRef(null);
  const popupRef = useRef(null);
  const searchRef = useRef(null);
  const navigate = useNavigate();

  // const handleOpen = () => setOpen(true);
  const handleClose = () => setShowSearch(false);

  useEffect(() => {
    if (docId) {
      setShowPopup(false);
    }
  }, [docId]);

  useEffect(() => {
    if (listItems) {
      setShowSearch(false);
    }
  }, [listItems]);

  const handleLinkClick = (item) => {
    console.log(item);
    setActiveLink(item.name);
    setSearchQuery(item.name);
    setFilteredSuggestions([]);
    navigate(`/docs/${item.url}`);
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchQuery(value);
    if (value.length > 0) {
      const filtered = listItems.filter((item) =>
        item.name.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredSuggestions(filtered);
    } else {
      setFilteredSuggestions([]);
    }
  };

  const handlePopupClick = () => {
    setShowPopup(!showPopup);
  };

  const handleClickSearch = () => {
    setShowSearch(true);
  };

  const handleClickOutside = (event) => {
    if (navbarRef.current && !navbarRef.current.contains(event.target)) {
      setShowNavbar(false);
    }
  };

  const handleClickPopupOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setShowPopup(false);
    }
  };

  const handleSearchOutside = (event) => {
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      setShowSearch(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickPopupOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickPopupOutside);
    };
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleSearchOutside);
    return () => {
      document.removeEventListener("mousedown", handleSearchOutside);
    };
  }, []);

  const getHighlightedText = (text, highlight) => {
    const parts = text.split(new RegExp(`(${highlight})`, "gi"));
    return parts.map((part, index) =>
      part.toLowerCase() === highlight.toLowerCase() ? (
        <span key={index} className="highlight">
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  return (
    <>
      <div className="navbar_wrapper">
        <div className="container" ref={navbarRef}>
          <div className="navbar_menu_item">
            <ResponsiveDocsNavbar
              setShowNavbar={setShowNavbar}
              showNavbar={showNavbar}
              handleLinkClick={handleLinkClick}
            />
            <MenuIcon
              onClick={() => setShowNavbar(true)}
              className="humburger_bar"
            />

            <img src={logoipsum} alt="" className="navbar_logo" />
            <div className="search_container">
              <div className="input_bar" onClick={handleClickSearch}>
                <img className="search_bar" src={search_icon} alt="" />
              </div>

              <Modal
                open={showSearch}
                onClose={handleClose}
                center
                classNames={{
                  modal: "search_wrapper",
                }}
              >
                <div className="container">
                  <div className="search_menu">
                    <img className="search_img" src={search_icon} alt="" />

                    <input
                      type="text"
                      value={searchQuery}
                      onChange={handleSearchChange}
                      placeholder=""
                    />
                  </div>
                </div>
                <div className="border_bottom"></div>
                <div className="popup_search_content">
                  <ul className="nav flex-column">
                    {filteredSuggestions.length > 0 && (
                      <ul className="suggestions">
                        {filteredSuggestions.map((suggestion, index) => (
                          <li
                            key={index}
                            onClick={() => handleLinkClick(suggestion)}
                          >
                            {getHighlightedText(suggestion.name, searchQuery)}
                          </li>
                        ))}
                      </ul>
                    )}
                  </ul>
                </div>
              </Modal>
            </div>
            <div className="responsive_logo">
              <div className="responsive_search" onClick={handleClickSearch}>
                <img src={search_icon} alt="" />
              </div>
            </div>

            <ul className="navbar_list">
              <li
                className="navbar_item"
                onClick={() => handleLinkClick({ name: "signup" })}
              >
                <Link
                  to="/"
                  className={
                    activeLink === "signup" ? "active_link" : "navbar_link"
                  }
                >
                  Sign Up For Figma Clone
                </Link>
                <img src={arrow_right_icon} alt="" />
              </li>
              <li
                className="navbar_item"
                onClick={() => handleLinkClick({ name: "dashboard" })}
              >
                <Link
                  to="/"
                  className={
                    activeLink === "dashboard" ? "active_link" : "navbar_link"
                  }
                >
                  Dashboard
                </Link>
                <img src={arrow_right_icon} alt="" />
              </li>
              <li
                className="navbar_item"
                onClick={() => handleLinkClick({ name: "signup" })}
              >
                <Link
                  to="/"
                  className={
                    activeLink === "signup" ? "active_link" : "navbar_link"
                  }
                >
                  Community
                </Link>
                <img src={arrow_right_icon} alt="" />
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="top-line"></div>
      <div className="navbar_popup">
        <div className="container" ref={navbarRef}>
          <div className="navbar_popup_top">
            <div className="buttom_nav">
              <button onClick={handlePopupClick}>Dashboard</button>
            </div>
          </div>
        </div>
      </div>
      {showPopup && (
        <div className="popup_wrapper" ref={popupRef}>
          <div className="container">
            <div className="navbar_popup_top">
              <div className="popup_content">
                <ul className="nav flex-column">
                  {listItems?.map((item, index) => (
                    <li className="nav-item">
                      <Link
                        to={`/docs/${item.url}`}
                        className={`nav-link ${
                          specialItems.includes(item.name) ? "special" : ""
                        }`}
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* {showSearch && (
       
        
      )} */}
    </>
  );
};

export default DocsNavbar;
