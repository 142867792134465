import React, { useState } from "react";
import info_icon from "../../../../assets/Icons/info_icon.svg";
import Accordion from "react-bootstrap/Accordion";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import Select from "react-select";
import SvgComponent from "./SvgComponent";
import JsonTextEditor from "../TextAreaEditor/JsonTextEditor";
import ModalEditor from "../FunctionComponent/ModalEditor";

const DateInputsandVariables = ({
  jsonObject,
  myStyle,
  jsonInput,
  currentEditObj,
  theme,
  radioType,
  buttonRowType,
  ToolTipBox,
  tooltipContent,
  setTooltipContent,
  showTooltipData,
  handleIconClick,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const colorOptions = [
    { value: "transparent", label: "Transparent" },
    { value: "#ff0000", label: "Red" },
    { value: "#00ff00", label: "Green" },
    { value: "#0000ff", label: "Blue" },
    { value: "#ffffff", label: "white" },
  ];

  const customStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: " #1A237E",
      borderRadius: "5px",
      width: "220px",
      border: "none",
      height: "10px",
      color: "#ffff",
    }),
    menu: (styles) => ({
      ...styles,
      backgroundColor: "#1A237E",
    }),
    option: (styles, { isFocused }) => ({
      ...styles,
      backgroundColor: isFocused ? "  #1A237E" : "#1A237E",
      color: isFocused ? "white" : "white",
      cursor: "pointer",
    }),
    singleValue: (styles) => ({
      ...styles,
      color: "white",
    }),
    placeholder: (styles) => ({
      ...styles,
      color: "White",
      fontSize: "12px",
    }),
  };
  const fixedStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: " #1A237E",
      borderRadius: "5px",
      width: "180px",
      border: "none",
      height: "10px",
    }),
    menu: (styles) => ({
      ...styles,
      backgroundColor: "#1A237E",
    }),
    option: (styles, { isFocused }) => ({
      ...styles,
      backgroundColor: isFocused ? "  #1A237E" : "#1A237E",
      color: isFocused ? "white" : "white",
      cursor: "pointer",
    }),
    singleValue: (styles) => ({
      ...styles,
      color: "white",
    }),
    placeholder: (styles) => ({
      ...styles,
      color: "White", // Change placeholder color here
      fontSize: "12px",
    }),
  };

  const openModal = () => {
    setModalIsOpen(true);
    console.log(modalIsOpen);
  };
  const closeModalEditor = () => {
    setModalIsOpen(false);
    console.log(modalIsOpen);
  };
  return (
    <>
      <div>
        <div className="main-bar">
          <div className="sec-one">
            <div className="scroll-main">
              <Accordion flush>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Date Inputs & Variables</Accordion.Header>
                  <Accordion.Body>
                    <div className="space-1">
                      <div className="text-upper">
                        <p>Data Source</p>
                        <ToolTipBox
                          type="Data Source"
                          handleIconClick={handleIconClick}
                          showTooltipData={showTooltipData}
                          tooltipContent={tooltipContent}
                        />{" "}
                      </div>
                      <div className="slt-type">
                        <Select
                          placeholder="Type a Color"
                          options={colorOptions}
                          styles={customStyles}
                        />
                      </div>
                    </div>

                    <>
                      <div className="space-1">
                        <div className="text-upper">
                          <SubdirectoryArrowRightIcon />
                          <p>transform</p>
                        </div>
                        <div className="slt-type">
                          <Select
                            placeholder="Type a Color"
                            options={colorOptions}
                            styles={customStyles}
                          />
                        </div>
                      </div>
                      <div className="bottom_line"></div>
                      {/* <div className="space-1">
                      <div className="text-small-slt">
                        <p>Label</p>
                        <img src={info_icon} alt="" />
                      </div>
                      <div className="svg_space">
                        <div className="slt-type">
                          <Select
                            placeholder="Type a Color"
                            options={colorOptions}
                            styles={fixedStyles}
                          />
                        </div>
                        <SvgComponent />
                      </div>
                    </div> */}
                      <div className="Custom-1">
                        <div className="snap_img">
                          <p>Custom Snap</p>
                          <ToolTipBox
                            type="Custom Snap"
                            handleIconClick={handleIconClick}
                            showTooltipData={showTooltipData}
                            tooltipContent={tooltipContent}
                          />{" "}
                        </div>
                        <div className="svg_space">
                          <Select
                            options={colorOptions}
                            name="colors"
                            className="basic-multi-select"
                            classNamePrefix="select"
                            styles={fixedStyles}
                            placeholder="Select..."
                          />
                          <SvgComponent />
                        </div>
                      </div>
                      {
                        (buttonRowType = "buttonRow" ? (
                          <div className="space-1">
                            <div className="text-small-slt">
                              <p>Label</p>
                              <ToolTipBox
                                type="Label"
                                handleIconClick={handleIconClick}
                                showTooltipData={showTooltipData}
                                tooltipContent={tooltipContent}
                              />{" "}
                            </div>
                            <div className="svg_space">
                              <div className="slt-type">
                                <Select
                                  placeholder="Type a Color"
                                  options={colorOptions}
                                  styles={fixedStyles}
                                />
                              </div>
                              <SvgComponent />
                            </div>
                          </div>
                        ) : null)
                      }
                      {
                        <div className="option_space">
                          <div className="text-small-slt">
                            <p>Options</p>
                            <ToolTipBox
                              type="Options"
                              handleIconClick={handleIconClick}
                              showTooltipData={showTooltipData}
                              tooltipContent={tooltipContent}
                            />{" "}
                          </div>
                          <div className="svg_space">
                            <div className="slt-type">
                              <Select
                                placeholder="Type a Color"
                                options={colorOptions}
                                styles={fixedStyles}
                              />
                            </div>
                            <SvgComponent onClick={openModal} />
                          </div>
                        </div>
                      }
                      {
                        (radioType = "radiobutton" ? (
                          <div className="space-1">
                            <div className="text-small-slt">
                              <p>value</p>
                              <ToolTipBox
                                type="value"
                                handleIconClick={handleIconClick}
                                showTooltipData={showTooltipData}
                                tooltipContent={tooltipContent}
                              />{" "}
                            </div>
                            <div className="svg_space">
                              <div className="slt-type">
                                <Select
                                  placeholder="Type a Color"
                                  options={colorOptions}
                                  styles={fixedStyles}
                                />
                              </div>
                              <SvgComponent />
                            </div>
                          </div>
                        ) : null)
                      }

                      <div className="space-1">
                        <div className="text-small-slt">
                          <p>Minimum date</p>
                          <ToolTipBox
                            type="Minimum date"
                            handleIconClick={handleIconClick}
                            showTooltipData={showTooltipData}
                            tooltipContent={tooltipContent}
                          />{" "}
                        </div>
                        <div className="svg_space">
                          <div className="slt-type">
                            <Select
                              placeholder="Type a Color"
                              options={colorOptions}
                              styles={fixedStyles}
                            />
                          </div>
                          <SvgComponent />
                        </div>
                      </div>
                      <div className="space-1">
                        <div className="text-small-slt">
                          <p>Disabled</p>
                          <ToolTipBox
                            type="Disabled"
                            handleIconClick={handleIconClick}
                            showTooltipData={showTooltipData}
                            tooltipContent={tooltipContent}
                          />{" "}
                        </div>
                        <div className="svg_space">
                          <div className="slt-type">
                            <Select
                              placeholder="Type a Color"
                              options={colorOptions}
                              styles={fixedStyles}
                            />
                          </div>
                          <SvgComponent />
                        </div>
                      </div>
                      {
                        <div className="space-1">
                          <div className="text-small-slt">
                            <p>Editable</p>
                            <ToolTipBox
                              type="Editable"
                              handleIconClick={handleIconClick}
                              showTooltipData={showTooltipData}
                              tooltipContent={tooltipContent}
                            />{" "}
                          </div>
                          <div className="svg_space">
                            <div className="slt-type">
                              <Select
                                placeholder="Type a Color"
                                options={colorOptions}
                                styles={fixedStyles}
                              />
                            </div>
                            <SvgComponent />
                          </div>
                        </div>
                      }
                      {
                        <div className="Error-piker">
                          <div className="text-small-slt">
                            <p>Error</p>
                            <ToolTipBox
                              type="Error"
                              handleIconClick={handleIconClick}
                              showTooltipData={showTooltipData}
                              tooltipContent={tooltipContent}
                            />{" "}
                          </div>
                          <div className="svg_space">
                            <div className="slt-type">
                              <Select
                                placeholder="Type a Color"
                                options={colorOptions}
                                styles={fixedStyles}
                              />
                            </div>
                            <SvgComponent />
                          </div>
                        </div>
                      }
                      <div className="space-1">
                        <div className="text-small-slt">
                          <p>Left icon name</p>
                          <ToolTipBox
                            type="Left icon name"
                            handleIconClick={handleIconClick}
                            showTooltipData={showTooltipData}
                            tooltipContent={tooltipContent}
                          />{" "}
                        </div>
                        <div className="input-leftname">
                          <input type="text" value={""} name="est_item_size" />
                        </div>
                      </div>

                      {
                        (radioType = "radiobutton" ? (
                          <div className="space-1">
                            <div className="text-small-slt">
                              <p>Selected Icon</p>
                              <ToolTipBox
                                type="Selected Icon"
                                handleIconClick={handleIconClick}
                                showTooltipData={showTooltipData}
                                tooltipContent={tooltipContent}
                              />{" "}
                            </div>
                            <div className="input-leftname">
                              <input
                                type="text"
                                value={""}
                                name="est_item_size"
                              />
                            </div>
                          </div>
                        ) : null)
                      }
                      {
                        (radioType = "radiobutton" ? (
                          <div className="space-1">
                            <div className="text-small-slt">
                              <p>Custom Selected</p>
                              <ToolTipBox
                                type="Custom Selected"
                                handleIconClick={handleIconClick}
                                showTooltipData={showTooltipData}
                                tooltipContent={tooltipContent}
                              />{" "}
                            </div>
                            <div className="svg_space">
                              <div className="slt-type">
                                <Select
                                  placeholder="Type a Color"
                                  options={colorOptions}
                                  styles={fixedStyles}
                                />
                              </div>
                              <SvgComponent />
                            </div>
                          </div>
                        ) : null)
                      }
                      {
                        (radioType = "radiobutton" ? (
                          <div className="space-1">
                            <div className="text-small-slt">
                              <p>Unselected Icon</p>
                              <ToolTipBox
                                type="Unselected Icon"
                                handleIconClick={handleIconClick}
                                showTooltipData={showTooltipData}
                                tooltipContent={tooltipContent}
                              />{" "}
                            </div>
                            <div className="input-leftname">
                              <input
                                type="text"
                                value={""}
                                name="est_item_size"
                              />
                            </div>
                          </div>
                        ) : null)
                      }
                      {
                        <div className="space-1">
                          <div className="text-small-slt">
                            <p>Custom Unselected</p>
                            <ToolTipBox
                              type="Custom Unselected"
                              handleIconClick={handleIconClick}
                              showTooltipData={showTooltipData}
                              tooltipContent={tooltipContent}
                            />{" "}
                          </div>
                          <div className="svg_space">
                            <div className="slt-type">
                              <Select
                                placeholder="Type a Color"
                                options={colorOptions}
                                styles={fixedStyles}
                              />
                            </div>
                            <SvgComponent />
                          </div>
                        </div>
                      }
                      <div className="space-1">
                        <div className="text-small-slt">
                          <p>Custom Left icon</p>
                          <ToolTipBox
                            type="Custom Left icon"
                            handleIconClick={handleIconClick}
                            showTooltipData={showTooltipData}
                            tooltipContent={tooltipContent}
                          />{" "}
                        </div>
                        <div className="svg_space">
                          <div className="slt-type">
                            <Select
                              placeholder="Type a Color"
                              options={colorOptions}
                              styles={fixedStyles}
                            />
                          </div>
                          <SvgComponent />
                        </div>
                      </div>
                      <div className="space-1">
                        <div className="text-small-slt">
                          <p>Right icon name</p>
                          <ToolTipBox
                            type="Custom Left icon"
                            handleIconClick={handleIconClick}
                            showTooltipData={showTooltipData}
                            tooltipContent={tooltipContent}
                          />{" "}
                        </div>
                        <div className="input-leftname">
                          <input type="text" value={""} name="est_item_size" />
                        </div>
                      </div>
                      <div className="space-1">
                        <div className="text-small-slt">
                          <p>Custom right </p>
                          <ToolTipBox
                            type="Custom right"
                            handleIconClick={handleIconClick}
                            showTooltipData={showTooltipData}
                            tooltipContent={tooltipContent}
                          />{" "}
                        </div>
                        <div className="svg_space">
                          <div className="slt-type">
                            <Select
                              placeholder="Type a Color"
                              options={colorOptions}
                              styles={fixedStyles}
                            />
                          </div>

                          <SvgComponent />
                        </div>
                      </div>
                    </>

                    {/* Add other fields for non-date types */}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
      {/* <ModalEditor
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        openModal={openModal}
        closeModalEditor={closeModalEditor}
      /> */}
    </>
  );
};

export default DateInputsandVariables;
