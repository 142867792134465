import React from "react";

const EditMode = () => {
  return (
    <div>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.52539 2.96387V6.34427M11.9999 2.96387V6.34427M15.4744 2.96387V6.34427M8.52539 17.6554V21.0357M11.9999 17.6554V21.0357M15.4744 17.6554V21.0357"
          stroke="#1A237E"
          stroke-width="4"
          stroke-miterlimit="10"
        />
        <path
          d="M2.96387 15.4743H6.34427M2.96387 11.9999H6.34427M2.96387 8.52539H6.34427M17.6554 15.4743H21.0357M17.6554 11.9999H21.0357M17.6554 8.52539H21.0357"
          stroke="#1A237E"
          stroke-width="2"
          stroke-miterlimit="10"
        />
      </svg>
    </div>
  );
};

export default EditMode;
