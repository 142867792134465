import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import info_icon from "../../../assets/Icons/info_icon.svg";
import thumb_icon from "../../../assets/Icons/thumb_icon.svg";
import Switch from "react-switch";
import SelectDateTrigger from "./CommonRightbarTab/SelectDateTrigger";
import UpdateMyValues from "../../../common/customHooks/UpdateMyValues";
import {
  setFolderStructure,
  setSelectedFolder,
} from "../../../redux/actions/defaultActions";

import { useDispatch } from "react-redux";
import ToolTipBox from "../../../ToolTip/ToolTipBox";
const FourthTab = ({
  selectedFolder,
  folderStructure,
  showTooltipData,
  setShowTooltipData,
}) => {
  const [settingBar, setSettingBar] = useState("set-4");
  const [press, setPress] = useState({ show: false });
  const [longPress, setLongPress] = useState({ show: false });
  const [tooltipContent, setTooltipContent] = useState();

  const dispatch = useDispatch();

  const ReverseSwitch = () => {
    const [checked, setChecked] = useState(false);

    const handleChange = (newChecked) => {
      setChecked(newChecked);
    };

    return (
      <label>
        <Switch onChange={handleChange} checked={checked} />
      </label>
    );
  };

  const setSelectedFolderAction = (folder) =>
    dispatch(setSelectedFolder(folder));

  const setFolderStructureAction = (structure) =>
    dispatch(setFolderStructure(structure));

  const { handleStyleUpdate } = UpdateMyValues(
    setFolderStructureAction,
    setSelectedFolderAction
  );

  const handleChangeTab = (type) => {
    setSettingBar(type);
  };

  const showUrlInputHandler = (data) => {
    if (data === "press") {
      setPress({ ...press, show: true });
      setLongPress({ ...longPress, show: false });
    } else {
      setPress({ ...press, show: false });
      setLongPress({ ...longPress, show: true });
    }
  };

  const longpressChangeHandler = (e) => {
    setLongPress({ ...longPress, [e.target.name]: e.target.value });

    handleStyleUpdate(
      folderStructure,
      selectedFolder,
      "onLongPress",
      e.target.value
    );
  };

  const pressChangeHandler = (e) => {
    setPress({ ...press, [e.target.name]: e.target.value });

    handleStyleUpdate(
      folderStructure,
      selectedFolder,
      "onPress",
      e.target.value
    );
  };
  const handleIconClick = (e, type) => {
    e.stopPropagation();

    if (type === tooltipContent) {
      setShowTooltipData(false);
      setTooltipContent();
    } else {
      setTooltipContent(type);
      setShowTooltipData(true);
    }
  };
  return (
    <>
      <div className="fourth-bar">
        <div className="fourth-inner">
          <div className="scroll-fourth">
            <Accordion flush>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  Trigger
                  <ToolTipBox
                    type="Trigger"
                    handleIconClick={handleIconClick}
                    showTooltipData={showTooltipData}
                    tooltipContent={tooltipContent}
                  />{" "}
                  <Accordion flush>
                    <Accordion.Item eventKey="0"></Accordion.Item>
                  </Accordion>
                </Accordion.Header>

                <Accordion.Body>
                  {selectedFolder?.type === "pressable" && (
                    <>
                      <div
                        className="sec-1"
                        onClick={() => showUrlInputHandler("press")}
                      >
                        <div className="flex-icon-btn-2">
                          <div className="icon-txt">
                            <img src={thumb_icon} alt="" />
                            <h5>On Press</h5>
                          </div>
                          <div className="btn-2">
                            <button>0</button>
                          </div>
                        </div>
                        <p>Runs when the element is passed</p>
                      </div>
                      <div
                        className="sec-1"
                        accordion
                        onClick={() => showUrlInputHandler("long_press")}
                      >
                        <div className="flex-icon-btn-2">
                          <div className="icon-txt">
                            <img src={thumb_icon} alt="" />
                            <h5>On Long Press</h5>
                          </div>
                          <div className="btn-2">
                            <button>0</button>
                          </div>
                        </div>
                        <p>Runs when the element is passed</p>
                      </div>
                    </>
                  )}
                  {press?.show && (
                    <div className="url-col">
                      <h6>URL (On Press)</h6>
                      <textarea
                        rows="2"
                        cols={10}
                        type="text"
                        placeholder="Enter your URL On press"
                        onChange={pressChangeHandler}
                        name="data"
                        value={
                          selectedFolder?.onPress ? selectedFolder?.onPress : ""
                        }
                      ></textarea>
                    </div>
                  )}
                  {longPress?.show && (
                    <div className="url-col">
                      <h6>URL (On Long Press)</h6>
                      <textarea
                        rows="2"
                        cols={10}
                        type="text"
                        placeholder="Enter your URL On long press"
                        onChange={longpressChangeHandler}
                        name="data"
                        value={
                          selectedFolder?.onLongPress
                            ? selectedFolder?.onLongPress
                            : ""
                        }
                      ></textarea>
                    </div>
                  )}
                  <SelectDateTrigger selectedFolder={selectedFolder} />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            {/* <div className="tlg-btn-tab">
              <ReverseSwitch />
              <p>Log action in this chain</p>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default FourthTab;
