import React from "react";
import image_dash from "../../assets/Icons/image_dash.svg";

const DashboardDoc = () => {
  return (
    <div>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">Dashboard</h1>
      </div>
      <div>
        <p className="clone_para">
          The Dashboard is the main hub in Figma Clone. Here you can see your
          most recent app projects, find out about new features and
          improvements, explore example apps and screens, watch helpful videos,
          and more.
        </p>
        <div className="img_for_dashboard">
          <img src={image_dash} alt="" className="img-for-dashboardDocs" />
        </div>

        <h2 id="workspaces" className="clone_head">
          Workspaces
        </h2>
        <p className="clone_para">
          Workspaces allow you to group apps together and invite other users to
          work collaboratively on those projects. Workspaces are only available
          on certain subscription plans.
        </p>

        <h2 id="starter-apps" className="clone_head">
          Starter Apps
        </h2>
        <p className="clone_para">
          If you're looking to kickstart a new app project, Starter Apps are a
          great resource. These are pre-built apps that contain many screens
          with various layouts that are ready for you to customize with your own
          colors, branding, and styles. Most Screens that available in our
          Starter Apps are also available to add individually to an existing app
          as Example Screens.
        </p>
        <div className="img_for_dashboard">
          <img src={image_dash} alt="" className="img-for-dashboardDocs" />
        </div>
        <h2 id="example-screens" className="clone_head">
          Example Screens
        </h2>
        <p className="clone_para">
          Here is where you can preview Example Screens and add them directly to
          one of your apps. These Example Screens can also be accessed inside of
          the Builder. To access any of these, click the + (plus) button next
          to Screens panel.
        </p>
        <div className="img_for_dashboard">
          <img src={image_dash} alt="" className="img-for-dashboardDocs" />
        </div>
        <h2 id="expert-services" className="clone_head">
          Expert Services
        </h2>
        <p className="clone_para">
          Hire our handpicked internal team of designers, developers, and app
          experts to accelerate your project. We're available for projects of
          all sizes, from small tweaks to full app & backend builds.
        </p>
        <div className="img_for_dashboard">
          <img src={image_dash} alt="" className="img-for-dashboardDocs" />
        </div>
        <h2 id="expert-projects" className="clone_head">
          Expert Projects
        </h2>
        <p className="clone_para">
          Our experts team is an excellent option when you have a specific
          feature or goal in mind. We'll quote you a specific price, start date,
          and timeline, and deliver the outcome on time.
        </p>
        <h2 id="support-and-resources" className="clone_head">
          Support and Resources
        </h2>
        <p className="clone_para">
          Our dedicated support team is here to help you with any questions you
          have about Draftbit. From just getting started to launching your app
          into the app store, we're standing by to answer questions via chat,
          email, or in our community.
        </p>
        <h2 id="account-settings" className="clone_head">
          {" "}
          Account Settings{" "}
        </h2>
        <p className="clone_para">
          This tab is where you can manage all of your account and billing
          settings.
        </p>
      </div>
    </div>
  );
};

export default DashboardDoc;
