import {
  SET_FLASHLIST,
  GET_FLASHLIST,
  DELETE_FLASHLIST,
  SET_ENDPOINT,
  GET_ENDPOINTS,
  DELETE_ENPOINT,
  MYNEW_DATA_DATA,
  SET_MY_FLASHLIST_KEYS,
  SET_MY_FLASHLIST_KEYS_DATA,
  EDIT_MY_FLASHLIST,
  EDIT_MY_ENDPOINT,
  SET_MY_FLASHLIST_DATA,
  GET_MY_FLASHLIST_DATA,
  SET_MY_MERGE_DATA,
  SET_RELOAD_UPDATE,
  SET_MY_MERGE_DATA2,
  SET_RELOAD,
} from "../types";

const initialState = {
  reloadUpdate: false,
};

const defaultReducers = (state = initialState, action) => {
  switch (action.type) {
    case SET_FLASHLIST:
      return {
        ...state,
        addFlashlist: action.payload,
      };
    case GET_FLASHLIST:
      return {
        ...state,
        allFlashlist: action.payload,
      };
    case DELETE_FLASHLIST:
      return {
        ...state,
        deleteFlashlist: action.payload,
      };
    case SET_ENDPOINT:
      return {
        ...state,
        addEndpoint: action.payload,
      };
    case GET_ENDPOINTS:
      return {
        ...state,
        getEndpoints: action.payload,
      };
    case MYNEW_DATA_DATA:
      return {
        ...state,
        myJsonData: action.payload,
      };
    case DELETE_ENPOINT:
      return {
        ...state,
        deleteEndpoint: action.payload,
      };
    case SET_MY_FLASHLIST_KEYS:
      return {
        ...state,
        addFlashlistKey: action.payload,
      };
    case SET_MY_FLASHLIST_KEYS_DATA:
      return {
        ...state,
        flashlistKeyData: action.payload,
      };
    case EDIT_MY_FLASHLIST:
      return {
        ...state,
        editFlashlist: action.payload,
      };
    case EDIT_MY_ENDPOINT:
      return {
        ...state,
        editEndpoint: action.payload,
      };
    case SET_MY_FLASHLIST_DATA:
      return {
        ...state,
        addFlashListData: action.payload,
      };
    case GET_MY_FLASHLIST_DATA:
      return {
        ...state,
        getFlashData: action.payload,
      };
    case SET_RELOAD:
      return {
        ...state,
        reloadMergeApi: action.payload,
      };
    case SET_MY_MERGE_DATA:
      return {
        ...state,
        getMyMergeData: action.payload,
      };
    case SET_MY_MERGE_DATA2:
      return {
        ...state,
        mergedNewData: action.payload,
      };
    case SET_RELOAD_UPDATE:
      return {
        ...state,
        reloadUpdate: action.payload,
      };
    default:
      return state;
  }
};

export default defaultReducers;
