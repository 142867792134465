import React from "react";
import { Link } from "react-router-dom";
import arrow_right_icon from "../assets/Icons/arrow_right_icon.svg";
import logoipsum from "../assets/Icons/logoipsum.svg";

const ResponsiveDocsNavbar = ({
  showNavbar,
  setShowNavbar,
  handleLinkClick,
}) => {
  return (
    <div
      className={`responsivenavbar-wrapper ${showNavbar && "active-navbar"}`}
    >
      {/* <div className="search_container">
        <div className="input_bar">
          <input value={""} />
        </div>
        <div className="img_search">
          <img src={search_icon} alt="" />
        </div>
      </div> */}
      <div className="navbar-header">
        <Link to="/" onClick={() => setShowNavbar(false)}>
          <div className="dashboard-logo"></div>
        </Link>
        <i
          className="fa fa-times"
          aria-hidden="true"
          onClick={() => setShowNavbar(false)}
        ></i>
      </div>

      <ul className="navbar_list responsive-list">
        <li
          className="navbar_item responsive-item"
          onClick={() => {
            setShowNavbar(false);
            handleLinkClick("home");
          }}
        >
          <Link to="/" className="navbar_link">
            Sign Up For Figma Clone
          </Link>
          <img src={arrow_right_icon} alt="" />
        </li>
        <li className="navbar_item responsive-item">
          <Link to="/" className="navbar_link">
            Dashboard
          </Link>
          <img src={arrow_right_icon} alt="" />
        </li>
        <li className="navbar_item responsive-item">
          <Link to="/" className="navbar_link">
            Community
          </Link>
          <img src={arrow_right_icon} alt="" />
        </li>
        <img src={logoipsum} alt="" className="navbar_logo" />
      </ul>
    </div>
  );
};

export default ResponsiveDocsNavbar;
