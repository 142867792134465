import React from "react";

const ShareSvg = () => {
  return (
    <>
      <svg
        width="17"
        height="17"
        viewBox="0 0 17 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.7499 11.1571C12.4637 11.157 12.1804 11.2149 11.9172 11.3274C11.654 11.4398 11.4163 11.6044 11.2185 11.8113L6.30447 9.04643C6.39966 8.68897 6.39966 8.31283 6.30447 7.95537L11.2185 5.19055C11.5764 5.56154 12.0572 5.78934 12.5709 5.83126C13.0847 5.87319 13.5961 5.72636 14.0093 5.4183C14.4226 5.11023 14.7093 4.66207 14.8159 4.15775C14.9224 3.65344 14.8414 3.12758 14.588 2.6787C14.3347 2.22982 13.9264 1.88871 13.4396 1.71928C12.9528 1.54985 12.4209 1.56372 11.9436 1.75829C11.4663 1.95286 11.0763 2.31479 10.8467 2.77626C10.6171 3.23774 10.5636 3.7671 10.6962 4.26518L5.78219 7.03C5.48865 6.72395 5.11026 6.51271 4.69569 6.42348C4.28112 6.33424 3.84933 6.37109 3.45588 6.52927C3.06242 6.68746 2.72529 6.95974 2.48785 7.3111C2.25041 7.66247 2.12354 8.07683 2.12354 8.5009C2.12354 8.92496 2.25041 9.33933 2.48785 9.69069C2.72529 10.0421 3.06242 10.3143 3.45588 10.4725C3.84933 10.6307 4.28112 10.6676 4.69569 10.5783C5.11026 10.4891 5.48865 10.2779 5.78219 9.9718L10.6962 12.7366C10.5823 13.1656 10.6055 13.6194 10.7627 14.0345C10.9198 14.4496 11.2029 14.805 11.5724 15.051C11.9418 15.2969 12.379 15.4211 12.8225 15.4059C13.2661 15.3908 13.6938 15.2371 14.0455 14.9666C14.3973 14.696 14.6556 14.3221 14.7841 13.8972C14.9125 13.4724 14.9048 13.0181 14.7618 12.5979C14.6189 12.1777 14.348 11.8128 13.9872 11.5544C13.6264 11.296 13.1937 11.1571 12.7499 11.1571Z"
          fill="#141a29"
        />
      </svg>
    </>
  );
};

export default ShareSvg;
