import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "@redux-devtools/extension";
import { thunk } from "redux-thunk";
// import { composeWithDevTools } from "redux-devtools-extension";
import { combineReducers } from "redux";
import authReducer from "./reducers/authReducer";
import defaultReducers from "./reducers/defaultReducers";
import flashListReducer from "./reducers/flashListReducer";

let middleware = [thunk];

const rootReducer = combineReducers({
  auth: authReducer,
  default: defaultReducers,
  flashlist: flashListReducer,
});

// const store = createStore(
//   rootReducer,
//   composeWithDevTools(applyMiddleware(...middleware))
// );

// const middlewareEnhancer = applyMiddleware(...middleware)
// const composedEnhancers = compose(middlewareEnhancer)

// const store = createStore(rootReducer, undefined, composedEnhancers)

const composeEnhancers = composeWithDevTools({
  // Specify here name, actionsDenylist, actionsCreators and other options
});
const store = createStore(
  rootReducer,
  composeEnhancers(
    applyMiddleware(...middleware)
    // other store enhancers if any
  )
);

export default store;
