import React from "react";

const DuplicateSvg = () => {
  return (
    <>
      <svg
        width="17"
        height="17"
        viewBox="0 0 17 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.83317 15.5833H11.3332C11.7089 15.5833 12.0692 15.4341 12.3349 15.1684C12.6006 14.9027 12.7498 14.5424 12.7498 14.1667V5.66667C12.7498 5.29094 12.6006 4.93061 12.3349 4.66493C12.0692 4.39926 11.7089 4.25 11.3332 4.25H2.83317C2.45745 4.25 2.09711 4.39926 1.83144 4.66493C1.56576 4.93061 1.4165 5.29094 1.4165 5.66667V14.1667C1.4165 14.5424 1.56576 14.9027 1.83144 15.1684C2.09711 15.4341 2.45745 15.5833 2.83317 15.5833ZM4.24984 9.20833H6.37484V7.08333H7.7915V9.20833H9.9165V10.625H7.7915V12.75H6.37484V10.625H4.24984V9.20833Z"
          fill="#141a29"
        />
        <path
          d="M14.1665 1.41602H5.6665V2.83268H14.1665V11.3327H15.5832V2.83268C15.5832 2.05139 14.9478 1.41602 14.1665 1.41602Z"
          fill="#141a29"
        />
      </svg>
    </>
  );
};

export default DuplicateSvg;
