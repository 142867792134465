import React from "react";

const AddScreenArrow = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="27"
        height="27"
        viewBox="0 0 27 27"
        fill="none"
        className="arrowSvg"
      >
        <path
          d="M18.7795 5.21543L18.6687 5.31035L10.4158 12.4822C10.1363 12.7248 9.96231 13.0887 9.96231 13.4947C9.96231 13.9008 10.1416 14.2646 10.4158 14.5072L18.6529 21.6738L18.79 21.7951C18.9219 21.8848 19.0801 21.9375 19.2488 21.9375C19.7076 21.9375 20.082 21.5473 20.082 21.0621V5.93789C20.082 5.45273 19.7076 5.0625 19.2488 5.0625C19.0748 5.0625 18.9113 5.12051 18.7795 5.21543Z"
          fill="#1A237E"
        />
      </svg>
    </div>
  );
};

export default AddScreenArrow;
