import { Button } from "@mui/material";
import React from "react";
import Modal from "react-responsive-modal";

const ShareProjectModal = ({
  open,
  onCloseModal,
  shareProjectHandler,
  setShareEmail,
}) => {
  return (
    <>
      <div>
        <Modal
          open={open}
          onClose={onCloseModal}
          center
          classNames={{
            modal: "share_popup",
          }}
        >
          <div className="main_share_container">
            <div className="mainShare">
              <h4 className="h2Head">Share Project</h4>
              <form onSubmit={shareProjectHandler}>
                <div className="lab_input">
                  <label>Email Address</label>
                  <input
                    type="email"
                    className="shareInput"
                    name="email"
                    placeholder="Enter Your Email Address"
                    onChange={(e) => setShareEmail(e.target.value)}
                  />
                </div>
                <div className="btn-Div">
                  <button className="shareBtns" type="submit">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default ShareProjectModal;
