import React, { useState, useEffect } from "react";

const EditableText = ({ editTextHandleChange, init, name, onSave }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedText, setEditedText] = useState(init);

  useEffect(() => {
    if (init) {
      setEditedText(init);
    }
  }, [init]);

  const handleDoubleClick = () => {
    setIsEditing(true);
  };

  const handleBlur = () => {
    setIsEditing(false);

    onSave(editedText);
  };

  const handleChange = (event) => {
    setEditedText(event.target.value);
  };

  return (
    <div onDoubleClick={handleDoubleClick} style={{ flexBasis: "100%" }}>
      {isEditing ? (
        <input
          type="text"
          value={editedText}
          onChange={handleChange}
          onBlur={handleBlur}
          className="editable_text"
          name={name}
        />
      ) : (
        <span>{editedText}</span>
      )}
    </div>
  );
};

export default EditableText;
