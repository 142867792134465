import React, { useEffect, useRef, useState } from "react";
import Header from "./Header";
import LeftSidebar from "./LeftSidebar";
import RightSidebar from "./RightSidebar";
import CanvasDisplay from "./CanvasDisplay";
import PortraitSvg from "../../common/svgComponents/PortraitSvg";
import LandscapeViewSvg from "../../common/svgComponents/LandscapeViewSvg";
import DownArrowSvg from "../../common/svgComponents/DownArrowSvg";
import {
  getSaveBlock,
  setFolderStructure,
  setSelectedFolder,
} from "../../redux/actions/defaultActions";
import { exportComponentAsJPEG } from "react-component-export-image";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../common/Loader/Loader";
import { setReloadMergeApi, setReloadUpdate } from "../../redux/actions/flashListActions";

const DashboardView = ({}) => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(false);
  const [settingBar, setSettingBar] = useState("set-1");
  const [modalOpen, setModalOpen] = useState(false);
  const [uploadMyImage, setuploadMyImage] = useState();
  const [selectedPercentage, setSelectedPercentage] = useState(100);
  const [orientation, setOrientation] = useState("portrait");
  const [activeView, setActiveView] = useState("portrait");
  const [myApiKeys, setMyApiKeys] = useState(null);
  const [responseValue, setResponseValue] = useState(true);
  const [myApiKeys2, setMyApiKeys2] = useState(false);
  const [isDraftPreview, setIsDraftPreview] = useState(true);
  const [mysettings, setMySettings] = useState(true);

  const [showTooltipData, setShowTooltipData] = useState(false);

  const {
    defaultValues,
    selectedFolder,
    folderStructure,
    addNewSaveBlock,
    deleteMyNewSaveBlock,
    getMySdkVersionList,
  } = useSelector((state) => state.default);

  const pageLoading = useSelector((state) => state?.auth?.pageLoading);
  const screenVersionList = useSelector(
    (state) => state?.default?.screenVersionList
  );
  const componentRef = useRef(null);
  const getFlashData = useSelector((state) => state?.flashlist?.getFlashData);
  const getMyMergeData = useSelector(
    (state) => state?.flashlist?.getMyMergeData
  );

  const mergedNewData = useSelector((state) => state?.flashlist?.mergedNewData);

  const reloadUpdate = useSelector((state) => state?.flashlist?.reloadUpdate);

  const setSelectedFolderAction = (folder) =>
    dispatch(setSelectedFolder(folder));

  const setFolderStructureAction = (structure) =>
    dispatch(setFolderStructure(structure));

  const myCustomBlock = localStorage.getItem("project_id");
  const sdkVersion = localStorage.getItem("sdkVersion");

  useEffect(() => {
    setFolderStructureAction(folderStructure);
  }, [getMySdkVersionList]);

  const setmydatas = (item) => {
    setMyApiKeys2(!myApiKeys2);
    setMyApiKeys(item.value);
  };

  useEffect(() => {
    if (
      selectedFolder?.type === "fetch" &&
      selectedFolder?.endpointId !== "0"
    ) {
      localStorage.setItem("endpoint_id", selectedFolder?.endpointId);
    }
    if (responseValue) {
      if (
        selectedFolder?.type === "flashlist" &&
        selectedFolder?.response !== "response"
      ) {
        localStorage?.setItem("crnt_response", selectedFolder?.response);
      }
    } else if (
      selectedFolder?.type === "flashlist" &&
      selectedFolder?.response !== "response"
    ) {
      const updatedFamilyTree = addFavoriteFood2(
        folderStructure,
        selectedFolder?.id,
        selectedFolder?.response
      );
      setFolderStructureAction(updatedFamilyTree);
    }
  }, [selectedFolder]);

  let curntResponse;

  useEffect(() => {
    curntResponse = localStorage.getItem("crnt_response");
  }, [curntResponse]);

  useEffect(() => {
    if (!selectedFolder?.name) {
      dispatch(setSelectedFolder(folderStructure?.[0]));
    }
  }, [folderStructure]);

  const openModal = (val) => {
    setuploadMyImage(val);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const { v4: uuidv4 } = require("uuid");

  // function mergeNewArrayIntoFlashlist(folderStructure, newArray) {
  //   const memo = {};
  //   let isMerged = false;

  //   // Convert single object to array if newArray is not already an array
  //   if (!Array.isArray(newArray)) {
  //     newArray = [newArray];
  //   }

  //   function findAndUpdateFlashlist(node, index) {
  //     const key = `${node.type}-${index}`;
  //     if (memo[key]) {
  //       return memo[key];
  //     }

  //     if (node.type === "flashlist" && !isMerged) {
  //       if (node.children && newArray.length > 0) {
  //         let updatedChildren = [];
  //         for (let i = 0; i < newArray.length; i++) {
  //           for (let j = 0; j < node.children.length; j++) {
  //             let existingChild = node.children[j];
  //             if (existingChild.flashMap) {
  //               const updatedChild = {
  //                 ...existingChild,
  //                 id: existingChild.id,
  //                 flashMap: i === node.children.length,
  //               };
  //               updatedChildren.push(updatedChild);
  //             } else {
  //               const updatedChild = {
  //                 ...newArray[i],
  //                 flashMap: false,
  //               };
  //               updatedChildren.push(updatedChild);
  //             }
  //           }
  //         }
  //         node.children = updatedChildren;
  //         isMerged = true;
  //       }
  //     } else if (node.children) {
  //       for (let i = 0; i < node.children.length; i++) {
  //         findAndUpdateFlashlist(node.children[i], i);
  //       }
  //     }
  //     memo[key] = node;
  //     return node;
  //   }
  //   folderStructure.forEach((node, index) => {
  //     findAndUpdateFlashlist(node, index);
  //   });
  // }

  // function mergeNewArrayIntoFlashlist(folderStructure, newArray) {
  //   let isMerged = false;

  //   if (!Array.isArray(newArray)) {
  //     newArray = [newArray];
  //   }

  //   function filterFlashMap(node) {
  //     if (node.type === "flashlist" && node.children) {
  //       node.children = node.children
  //         .filter((child) => child.flashMap !== false)
  //         .map(filterFlashMap);
  //     } else if (node.children) {
  //       node.children = node.children.map(filterFlashMap);
  //     }
  //     return node;
  //   }

  //   folderStructure = folderStructure.map(filterFlashMap);

  //   function findAndUpdateFlashlist(node) {
  //     if (node.type === "flashlist" && !isMerged) {
  //       if (node.children && newArray.length > 0) {
  //         let updatedChildren = [];
  //         for (let i = 0; i < newArray.length; i++) {
  //           for (let j = 0; j < node.children.length; j++) {
  //             let existingChild = node.children[j];
  //             if (existingChild.flashMap) {
  //               const updatedChild = {
  //                 ...existingChild,
  //                 id: existingChild.id,
  //                 flashMap: i === node.children.length,
  //               };
  //               updatedChildren.push(updatedChild);
  //             } else {
  //               const updatedChild = {
  //                 ...newArray[i],
  //                 flashMap: false,
  //               };
  //               updatedChildren.push(updatedChild);
  //             }
  //           }
  //         }
  //         node.children = updatedChildren;
  //         isMerged = true;
  //       }
  //     } else if (node.children) {
  //       for (let i = 0; i < node.children.length; i++) {
  //         findAndUpdateFlashlist(node.children[i]);
  //       }
  //     }
  //     return node;
  //   }
  //   folderStructure.forEach((node) => {
  //     findAndUpdateFlashlist(node);
  //   });
  // }

  const mergeNewArrayIntoFlashlist = (
    folderStructure,
    newArray,
    flashlistId
  ) => {
    let isMerged = false;

    if (!Array.isArray(newArray)) {
      newArray = [newArray];
    }

    function filterFlashMap(node) {
      if (
        node.type === "flashlist" &&
        node.children &&
        node.id === flashlistId
      ) {
        node.children = node.children
          .filter((child) => child.flashMap !== false)
          .map(filterFlashMap);
      } else if (node.children) {
        node.children = node.children.map(filterFlashMap);
      }
      return node;
    }

    folderStructure = folderStructure.map(filterFlashMap);
    function updateChildProperties(node) {
      if (node.type === "image") {
        node.image = "";
      } else if (node.type === "text") {
        node.canvas_text = "";
      }

      // Recursively traverse the tree and update child properties
      if (node.children) {
        for (let i = 0; i < node.children.length; i++) {
          node.children[i] = updateChildProperties(node.children[i]);
        }
      }

      // Check if the node has a 'view' or 'pressable' property
      if (node.view) {
        node.view = updateChildProperties(node.view);
      }
      if (node.pressable) {
        node.pressable = updateChildProperties(node.pressable);
      }

      return node;
    }
    function findAndUpdateFlashlist(node) {
      if (node.type === "flashlist" && node.id === flashlistId && !isMerged) {
        if (node.children && newArray.length > 0) {
          let updatedChildren = [];
          for (let i = 0; i < newArray.length; i++) {
            for (let j = 0; j < node.children.length; j++) {
              let existingChild = node.children[j];
              if (existingChild.flashMap) {
                const updatedChild = {
                  ...existingChild,
                  id: existingChild.id,
                  flashMap: i === node.children.length,
                  flashUniqueId: uuidv4(),
                };
                updatedChildren.push(updateChildProperties(updatedChild));
              } else {
                const updatedChild = {
                  ...newArray[i],
                  flashMap: false,
                };
                updatedChildren.push(updateChildProperties(updatedChild));
              }
            }
          }
          node.children = updatedChildren;
          isMerged = true;
        }
      } else if (node.children) {
        for (let i = 0; i < node.children.length; i++) {
          findAndUpdateFlashlist(node.children[i]);
        }
      }
      return node;
    }

    folderStructure.forEach((node) => {
      findAndUpdateFlashlist(node);
    });
  };

  // const DirectmergeNewArrayIntoFlashlist = (
  //   folderStructure,
  //   newArray,
  //   flashlistId
  // ) => {
  //   let isMerged = false;

  //   if (!Array.isArray(newArray)) {
  //     newArray = [newArray];
  //   }

  //   function filterFlashMap(node) {
  //     if (node.children) {
  //       node.children = node.children
  //         .filter((child) => child.flashMap !== false)
  //         .map(filterFlashMap);
  //     }
  //     return node;
  //   }

  //   folderStructure = folderStructure.map(filterFlashMap);

  //   // Find the flashlist node and update its children
  //   function findAndUpdateFlashlist(node) {
  //     if (
  //       node.type === "flashlist" &&
  //       node.parentId === flashlistId &&
  //       !isMerged
  //     ) {
  //       if (node.children) {
  //         let updatedChildren = [];
  //         let newIndex = 0;

  //         // Add new items to nodes with flashMap === true
  //         for (let j = 0; j < node.children.length; j++) {
  //           let existingChild = node.children[j];
  //           if (existingChild.flashMap === true && newIndex < newArray.length) {
  //             const updatedChild = {
  //               ...newArray[newIndex],
  //               flashMap: false,
  //               flashUniqueId: uuidv4(),
  //             };
  //             updatedChildren.push(updatedChild);
  //             newIndex++;
  //           } else {
  //             updatedChildren.push(existingChild);
  //           }
  //         }

  //         // If newArray still has remaining items, add them
  //         while (newIndex < newArray.length) {
  //           const updatedChild = {
  //             ...newArray[newIndex],
  //             flashMap: false,
  //             flashUniqueId: uuidv4(),
  //           };
  //           updatedChildren.push(updatedChild);
  //           newIndex++;
  //         }

  //         node.children = updatedChildren;
  //         isMerged = true;
  //       }
  //     } else if (node.children) {
  //       node.children = node.children.map(findAndUpdateFlashlist);
  //     }
  //     return node;
  //   }

  //   folderStructure = folderStructure.map(findAndUpdateFlashlist);

  //   return folderStructure;
  // };

  const DirectmergeNewArrayIntoFlashlist = (
    folderStructure,
    newArray,
    flashlistId
  ) => {
    let isMerged = false;

    if (!Array.isArray(newArray)) {
      newArray = [newArray];
    }

    function filterFlashMap(node) {
      if (node.children) {
        node.children = node.children
          .filter((child) => child.flashMap !== false)
          .map(filterFlashMap);
      }
      return node;
    }

    folderStructure = folderStructure.map(filterFlashMap);

    function findAndUpdateFlashlist(node) {
      if (
        node.type === "flashlist" &&
        node.parentId === flashlistId &&
        !isMerged
      ) {
        if (node.children && newArray.length > 0) {
          let updatedChildren = [];
          for (let i = 0; i < newArray.length; i++) {
            for (let j = 0; j < node.children.length; j++) {
              let existingChild = node.children[j];
              if (existingChild.flashMap) {
                const updatedChild = {
                  ...existingChild,
                  id: existingChild.id,
                  flashMap: i === node.children.length,
                  flashUniqueId: uuidv4(),
                };
                updatedChildren.push(updatedChild);
              } else {
                const updatedChild = {
                  ...newArray[i],
                  flashMap: false,
                };
                updatedChildren.push(updatedChild);
              }
            }
          }
          node.children = updatedChildren;
          isMerged = true;
        }
      } else if (node.children) {
        node.children = node.children.map(findAndUpdateFlashlist);
      }
      return node;
    }

    folderStructure = folderStructure.map(findAndUpdateFlashlist);

    return folderStructure;
  };

  const addFavoriteFood2 = (folders, id, res) => {
    return folders.map((folder) => {
      if (folder.id === id) {
        return addFlashlistDataIdToDescendants2(
          { ...folder, crnt_res: res },
          res
        );
      } else if (folder.children && folder.children.length > 0) {
        return {
          ...folder,
          children: addFavoriteFood2(folder.children, id, res),
        };
      }
      return folder;
    });
  };

  const addFlashlistDataIdToDescendants2 = (folder, res) => {
    if (folder.children && folder.children.length > 0) {
      folder.children = folder.children.map((child) =>
        addFlashlistDataIdToDescendants2(
          {
            ...child,
            crnt_res: res,
          },
          res
        )
      );
    }
    return folder;
  };

  // function mapKeyToArray(arrayOfObjects, keys) {
  //   console.log(arrayOfObjects, "arra");
  //   if (!Array.isArray(arrayOfObjects)) {
  //     arrayOfObjects = [arrayOfObjects];
  //   }
  //   return arrayOfObjects?.map((obj) => {
  //     if (keys.includes(".")) {
  //       const nestedKeys = keys.split(".");
  //       let nestedValue = obj;
  //       for (const nestedKey of nestedKeys) {
  //         if (nestedValue === undefined || nestedValue === null) {
  //           return undefined;
  //         }
  //         nestedValue = nestedValue[nestedKey];
  //       }
  //       return nestedValue;
  //     } else {
  //       if (obj[keys] === undefined || obj[keys] === null) {
  //         return obj;
  //       }
  //       return obj[keys];
  //     }
  //   });
  // }

  // function mapKeysToArray6(arrayOfObjects, keys) {
  //   if (!Array.isArray(arrayOfObjects)) {
  //     arrayOfObjects = [arrayOfObjects];
  //   }

  //   return arrayOfObjects.map((obj) => {
  //     const values = keys.map((key) => {
  //       if (key.includes(".")) {
  //         const nestedKeys = key.split(".");
  //         let nestedValue = obj;
  //         for (const nestedKey of nestedKeys) {
  //           if (nestedValue === undefined || nestedValue === null) {
  //             return undefined;
  //           }
  //           nestedValue = nestedValue[nestedKey];
  //         }
  //         return nestedValue;
  //       } else {
  //         return obj[key];
  //       }
  //     });

  //     return values;
  //   });
  // }

  const updateValueRecursive = (folders, folderId, propertyName, newValues) => {
    return folders.map((folder) => {
      if (folder.id === folderId) {
        return { ...folder, [propertyName]: newValues.shift() };
      } else if (folder.children && folder.children.length > 0) {
        const updatedChildren = updateValueRecursive(
          folder.children,
          folderId,
          propertyName,
          newValues
        );
        return { ...folder, children: updatedChildren };
      }
      return folder;
    });
  };

  // const updateValueRecursive9 = (
  //   folders,
  //   folderId,
  //   propertyName,
  //   newValues
  // ) => {
  //   return folders.map((folder) => {
  //     if (folder.id === folderId) {
  //       return { ...folder, [propertyName]: newValues.shift() };
  //     } else if (folder.children && folder.children.length > 0) {
  //       const updatedChildren = updateValueRecursive(
  //         folder.children,
  //         folderId,
  //         propertyName,
  //         newValues
  //       );
  //       return { ...folder, children: updatedChildren };
  //     }
  //     return folder;
  //   });
  // };

  function mapKeyToArray(arrayOfObjects, keys) {
    if (!Array.isArray(arrayOfObjects)) {
      arrayOfObjects = [arrayOfObjects];
    }

    return arrayOfObjects.map((obj) => {
      if (keys.includes(".")) {
        const nestedKeys = keys.split(".");
        let nestedValue = obj;
        for (const nestedKey of nestedKeys) {
          if (nestedValue === undefined || nestedValue === null) {
            return undefined;
          }
          nestedValue = nestedValue[nestedKey];
        }
        return nestedValue;
      } else {
        if (obj[keys] === undefined || obj[keys] === null) {
          return undefined;
        }
        return obj[keys];
      }
    });
  }

  useEffect(() => {
    if (getMyMergeData && myApiKeys && selectedFolder?.type === "text") {
      const { id, type } = selectedFolder;

      let newValues;
      if (curntResponse === "Top-Level-Response") {
        newValues = mapKeyToArray(getMyMergeData, myApiKeys);
      } else {
        if (!curntResponse) {
          newValues = mapKeyToArray(getMyMergeData, myApiKeys);
        } else {
          newValues = mapKeyToArray(getMyMergeData[curntResponse], myApiKeys);
        }
      }

      const updatedFolderStructure = updateValueRecursive(
        folderStructure,
        id,
        "canvas_text",
        newValues,
        selectedFolder?.flashlistTypeValue,
        selectedFolder?.flashlistType,
        selectedFolder?.flashId
      );

      setFolderStructureAction(updatedFolderStructure);
    }

    if (getMyMergeData && myApiKeys && selectedFolder?.type === "image") {
      const { id, type } = selectedFolder;

      let newValues;
      if (curntResponse === "Top-Level-Response") {
        newValues = mapKeyToArray(getMyMergeData, myApiKeys);
      } else {
        if (!curntResponse) {
          newValues = mapKeyToArray(getMyMergeData, myApiKeys);
        } else {
          newValues = mapKeyToArray(getMyMergeData[curntResponse], myApiKeys);
        }
      }

      const updatedFolderStructure = updateValueRecursive(
        folderStructure,
        id,
        "image",
        newValues,
        selectedFolder?.flashlistTypeValue,
        selectedFolder?.flashlistType,
        selectedFolder?.flashId
      );

      setFolderStructureAction(updatedFolderStructure);
    }
    setMyApiKeys2(false);
  }, [mysettings, myApiKeys, myApiKeys2]);

  // useEffect(() => {
  //   if (getMyMergeData && reloadUpdate && selectedFolder?.type === "fetch") {
  //     const flashListChildren = selectedFolder.children.find(
  //       (child) => child.type === "flashlist"
  //     );

  //     if (flashListChildren) {
  //       function getComponents(children, type) {
  //         const components = children.filter((child) => child.type === type);
  //         const views = children.filter((child) => child.type === "view");

  //         views.forEach((view) => {
  //           components.push(...getComponents(view.children, type));
  //         });

  //         return components;
  //       }

  //       const imageComponents = getComponents(
  //         flashListChildren.children,
  //         "image"
  //       );
  //       const textComponents = getComponents(
  //         flashListChildren.children,
  //         "text"
  //       );

  //       console.log(textComponents);

  //       imageComponents.forEach((component) => {
  //         const { id, lastSelectedKey } = component;
  //         const keys = lastSelectedKey?.value;

  //         let newValues;

  //         newValues = mapKeyToArray(getMyMergeData, keys);

  //         const updatedFolderStructure = updateValueRecursive(
  //           folderStructure,
  //           id,
  //           "image",
  //           newValues, // Pass the array of objects directly
  //           component.flashlistTypeValue,
  //           component.flashlistType,
  //           component.flashId
  //         );

  //         setFolderStructureAction(updatedFolderStructure);
  //       });

  //       textComponents?.forEach((component) => {
  //         const { id, lastSelectedKey } = component;
  //         const keys = lastSelectedKey?.value;

  //         let newValues = mapKeyToArray(getMyMergeData, keys);

  //         console.log(newValues);

  //         const updatedFolderStructure = updateValueRecursive(
  //           folderStructure,
  //           id,
  //           "canvas_text",
  //           newValues,
  //           component.flashlistTypeValue,
  //           component.flashlistType,
  //           component.flashId
  //         );

  //         setFolderStructureAction(updatedFolderStructure);
  //       });
  //     }
  //     setReloadUpdate(false);
  //   }
  //   setMyApiKeys2(false);
  // }, [reloadUpdate]);
  let accumulatedImageData = [];

  // function extractUniqueValuesFromComponents(textComponents) {
  //   const uniqueValues = new Set();

  //   textComponents?.forEach((component) => {
  //     const { lastSelectedKey } = component;
  //     const keyString = lastSelectedKey?.value;

  //     if (keyString) {
  //       // Split the keyString by comma
  //       const keys = keyString.split(",").map((item) => item.trim());

  //       // Add each key to the Set
  //       keys.forEach((key) => uniqueValues.add(key));
  //     }
  //   });

  //   // Convert Set back to an array
  //   return Array.from(uniqueValues);
  // }

  // function extractValuesFromComponents2(textComponents) {
  //   const allValues = [];

  //   textComponents?.forEach((component) => {
  //     const { id, lastSelectedKey } = component;
  //     const keyString = lastSelectedKey?.value;

  //     if (keyString) {
  //       // Split the keyString by comma
  //       const keys = keyString.split(",").map((item) => item.trim());
  //       // Create objects with id and key
  //       keys.forEach((key) => {
  //         allValues.push({ id, key });
  //       });
  //     }
  //   });

  //   return allValues;
  // }

  function extractUniqueValuesFromComponents(textComponents) {
    const uniqueValuesMap = new Map();

    textComponents?.forEach((component) => {
      const { id, lastSelectedKey } = component;
      const keyString = lastSelectedKey?.value;

      if (keyString) {
        // Split the keyString by comma
        const keys = keyString.split(",").map((item) => item.trim());

        // Add each key to the Map with its ID as the key
        keys.forEach((key) => {
          uniqueValuesMap.set(id, key);
        });
      }
    });

    // Convert Map values back to an array of objects
    const uniqueValues = Array.from(uniqueValuesMap, ([id, key]) => ({
      id,
      key,
    }));
    return uniqueValues;
  }

  // function mapKeysToFlatArray(arrayOfObjects, keys) {
  //   if (!Array.isArray(arrayOfObjects)) {
  //     arrayOfObjects = [arrayOfObjects];
  //   }

  //   const flatValues = [];

  //   arrayOfObjects.forEach((obj) => {
  //     keys.forEach((key) => {
  //       let nestedValue; // Declare nestedValue here
  //       if (key.includes(".")) {
  //         const nestedKeys = key.split(".");
  //         nestedValue = obj;
  //         for (const nestedKey of nestedKeys) {
  //           if (nestedValue === undefined || nestedValue === null) {
  //             flatValues.push(undefined);
  //             break;
  //           }
  //           nestedValue = nestedValue[nestedKey];
  //         }
  //       } else {
  //         nestedValue = obj[key];
  //       }
  //       flatValues.push(nestedValue);
  //     });
  //   });

  //   return flatValues;
  // }

  const updateValueRecursive99 = (
    folders,
    folderId,
    propertyName,
    newValues
  ) => {
    return folders.map((folder) => {
      if (folder.id === folderId) {
        return { ...folder, [propertyName]: newValues.shift() };
      } else if (folder.children && folder.children.length > 0) {
        const updatedChildren = updateValueRecursive99(
          folder.children,
          folderId,
          propertyName,
          newValues
        );
        return { ...folder, children: updatedChildren };
      }
      return folder;
    });
  };

  // function mapKeyToArray99(arrayOfObjects, keys) {
  //   console.log(keys);
  //   if (!Array.isArray(arrayOfObjects)) {
  //     arrayOfObjects = [arrayOfObjects];
  //   }

  //   return arrayOfObjects.map((obj) => {
  //     if (keys.includes(".")) {
  //       const nestedKeys = keys.split(".");
  //       let nestedValue = obj;
  //       for (const nestedKey of nestedKeys) {
  //         if (nestedValue === undefined || nestedValue === null) {
  //           return undefined;
  //         }
  //         nestedValue = nestedValue[nestedKey];
  //       }
  //       return nestedValue;
  //     } else {
  //       if (obj[keys] === undefined || obj[keys] === null) {
  //         return undefined;
  //       }
  //       return obj[keys];
  //     }
  //   });
  // }

  useEffect(() => {
    if (
      reloadUpdate === true &&
      mergedNewData &&
      selectedFolder?.type === "fetch"
    ) {
      const flashListChildren = selectedFolder.children.find(
        (child) => child.type === "flashlist"
      );
      console.log("reloadUpdate", { reloadUpdate });
      console.log("mergedNewData", { mergedNewData });
      if (flashListChildren) {
        function getComponents(children, type) {
          const components = children.filter((child) => child.type === type);
          const views = children.filter((child) => child.type === "view");

          views.forEach((view) => {
            components.push(...getComponents(view.children, type));
          });

          return components;
        }

        const imageComponents = getComponents(
          flashListChildren.children,
          "image"
        );
        const textComponents = getComponents(
          flashListChildren.children,
          "text"
        );

        let updatedFolderStructure = folderStructure;

        const extractImageValues =
          extractUniqueValuesFromComponents(textComponents);

        extractImageValues?.forEach((component, index) => {
          const { id, key } = component;

          let newValues = [...mapKeyToArray(mergedNewData, key)];

          updatedFolderStructure = updateValueRecursive(
            updatedFolderStructure,
            id,
            "canvas_text",
            newValues
          );
        });

        const extractTextValues =
          extractUniqueValuesFromComponents(imageComponents);

        let updatedFolderStructure2 = updatedFolderStructure;

        extractTextValues?.forEach((component, index) => {
          const { id, key } = component;

          let newValues = [...mapKeyToArray(mergedNewData, key)];

          updatedFolderStructure2 = updateValueRecursive(
            updatedFolderStructure2,
            id,
            "image",
            newValues
          );
        });

        console.log({ updatedFolderStructure2 });

        setFolderStructureAction(updatedFolderStructure2);
      }
      dispatch(setReloadUpdate(false));
      dispatch(setReloadMergeApi(false));
    }
  }, [reloadUpdate]);

  const handleChangeTab = (type) => {
    setSettingBar(type);
  };

  useEffect(() => {
    dispatch(getSaveBlock(myCustomBlock));
  }, [addNewSaveBlock, deleteMyNewSaveBlock]);

  // const uploadThisImageHandler = (data) => {
  //   console.log(data);
  //   setModalOpen(false);
  //   if (uploadMyImage == "upImg") {
  //     const imgValue = defaultValues?.imgValue;
  //     let list = [...imgValue];

  //     list[defaultValues?.imgIndex].image = data?.src || data?.imageurl;

  //     dispatch(setDefaultValues({ ...defaultValues, imgValue: list }));
  //   }
  // };

  const updateFolderChanges = (folders, folderId, image) => {
    return folders.map((folder) => {
      if (folder.id === folderId) {
        return { ...folder, image: image };
      } else if (folder.children && folder.children.length > 0) {
        const updatedChildren = updateFolderChanges(
          folder.children,
          folderId,
          image
        );
        return { ...folder, children: updatedChildren };
      }
      return folder;
    });
  };

  const uploadThisImageHandler = (data) => {
    setModalOpen(false);
    if (uploadMyImage === "upImg") {
      const updatedSelectedFolder = {
        ...selectedFolder,
        image: data?.src || data?.imageurl,
      };

      const updatedFolderStructure = updateFolderChanges(
        folderStructure,
        selectedFolder.id,
        data.imageurl
      );

      setFolderStructureAction(updatedFolderStructure);
      setSelectedFolderAction(updatedSelectedFolder);
    }
  };

  const handlePercentageChange = (e) => {
    setSelectedPercentage(Number(e.target.value));
  };
  const percentageOptions = [50, 75, 100, 125];

  const handlePortraitClick = () => {
    setOrientation("portrait");
    setActiveView("portrait");
  };

  const handleLandscapeClick = () => {
    setOrientation("landscape");
    setActiveView("landscape");
  };

  const handleDraftPreview = () => {
    setIsDraftPreview(true);
  };

  const handleWebPreview = () => {
    setIsDraftPreview(false);
  };

  return (
    <>
      <Header
        openModal={openModal}
        closeModal={closeModal}
        modalOpen={modalOpen}
        uploadThisImageHandler={uploadThisImageHandler}
        handlePercentageChange={handlePercentageChange}
        screen={activeTab}
      />

      <div style={{ width: "100%" }}>
        <div className="main-dash" style={{ position: "fixed", width: "100%" }}>
          <div className="left-sec">
            <LeftSidebar
              setActiveTab={setActiveTab}
              screen={activeTab}
              handleChangeTab={handleChangeTab}
            />
          </div>
          <div className="main-midSec">
            <div className="mid-sec">
              {/* <Outlet/> */}
              {activeTab ? (
                <div className="top_screen_sec">
                  <div className="inner">
                    <div className="main_screen">
                      <p>iphone 12 (390 X 844)</p>
                      <DownArrowSvg />
                    </div>
                    <div className="view_sec">
                      <div
                        className={`portrait_view ${
                          activeView === "portrait" ? "active" : ""
                        }`}
                      >
                        <PortraitSvg
                          handlePortraitClick={handlePortraitClick}
                        />
                      </div>
                      <div
                        className={`landscape_view ${
                          activeView === "landscape" ? "active" : ""
                        }`}
                      >
                        <LandscapeViewSvg
                          handleLandscapeClick={handleLandscapeClick}
                        />
                      </div>
                    </div>
                    <div className="screen_percentage_sec">
                      <div className="percentage">
                        <select
                          value={selectedPercentage}
                          onChange={handlePercentageChange}
                        >
                          {percentageOptions.map((option) => (
                            <option key={option} value={option}>
                              {option}%
                            </option>
                          ))}
                        </select>
                        {/* <DownArrowSvg /> */}
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              {/* {pageLoading ? (
            <Loader />
          ) : ( */}
              {pageLoading ? (
                <Loader />
              ) : (
                activeTab && (
                  <CanvasDisplay
                    screen={activeTab}
                    setSettingBar={setSettingBar}
                    uploadMyImage={uploadMyImage}
                    selectedPercentage={selectedPercentage}
                    orientation={orientation}
                    myApiKeys={myApiKeys}
                    handleDraftPreview={handleDraftPreview}
                    handleWebPreview={handleWebPreview}
                    isDraftPreview={isDraftPreview}
                    exportComponentAsJPEG={exportComponentAsJPEG}
                    componentRef={componentRef}
                  />
                )
              )}
            </div>{" "}
          </div>
          <div className="right-sec">
            <RightSidebar
              handleChangeTab={handleChangeTab}
              settingBar={settingBar}
              setSettingBar={setSettingBar}
              openModal={openModal}
              screen={activeTab}
              mergeNewArrayIntoFlashlist={mergeNewArrayIntoFlashlist}
              DirectmergeNewArrayIntoFlashlist={
                DirectmergeNewArrayIntoFlashlist
              }
              setmydatas={setmydatas}
              handleDraftPreview={handleDraftPreview}
              handleWebPreview={handleWebPreview}
              isDraftPreview={isDraftPreview}
              exportComponentAsJPEG={exportComponentAsJPEG}
              componentRef={componentRef}
              setMySettings={setMySettings}
              setResponseValue={setResponseValue}
              screenVersionList={screenVersionList}
              showTooltipData={showTooltipData}
              setShowTooltipData={setShowTooltipData}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardView;
