import React from "react";
import { Link } from "react-router-dom";
import { East } from "@mui/icons-material";
const AppCom = () => {
  return (
    <>
      <div className="background-img">
        <div className="coustom_container">
          <div className="home_sub">
            <div className="flex_con">
              <div className="left_text">
                <h1 className="h1_text">The Simple Way To On App</h1>
                <p className="para_text">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s.
                </p>
                <div className="banner_btn_sec">
                  <div className="lower_start">
                    <div className="btn-sec">
                      <div className="btn-left">
                        <Link>
                          Get Started for Free <East />
                        </Link>
                      </div>
                      <div className="btn-right">
                        <Link>
                          Go Faster With Expert <East />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="dummy_img">
                <img src="/assets/Image/sideimg.png" alt="phone" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AppCom;
