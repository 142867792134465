import React from "react";

function Eyepreview() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_3392_9735)">
        <path
          d="M7.00006 10.9375C5.13412 10.9375 3.36837 10.1469 2.027 8.7115C1.86206 8.53519 1.87168 8.25825 2.048 8.09331C2.22475 7.92838 2.50125 7.938 2.66662 8.11431C3.84043 9.37081 5.37912 10.0625 7.00006 10.0625C9.08387 10.0625 11.0027 8.92281 12.1801 7C11.0032 5.07719 9.08387 3.9375 7.00006 3.9375C4.83968 3.9375 2.85606 5.16294 1.69318 7.21569C1.57418 7.42613 1.30643 7.5005 1.09687 7.38063C0.886434 7.26163 0.812934 6.99475 0.931934 6.78431C2.25187 4.45375 4.52075 3.0625 7.00006 3.0625C9.47981 3.0625 11.7482 4.45375 13.0682 6.78431C13.1439 6.91819 13.1439 7.08181 13.0682 7.21525C11.7482 9.54581 9.47981 10.9375 7.00006 10.9375Z"
          fill="white"
        />
        <path
          d="M7 9.1875C5.79381 9.1875 4.8125 8.20619 4.8125 7C4.8125 5.79381 5.79381 4.8125 7 4.8125C8.20619 4.8125 9.1875 5.79381 9.1875 7C9.1875 8.20619 8.20619 9.1875 7 9.1875ZM7 5.6875C6.27637 5.6875 5.6875 6.27637 5.6875 7C5.6875 7.72362 6.27637 8.3125 7 8.3125C7.72362 8.3125 8.3125 7.72362 8.3125 7C8.3125 6.27637 7.72362 5.6875 7 5.6875Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_3392_9735">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Eyepreview;
