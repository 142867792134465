import React from "react";

const CenterAlign = ({ selectedFolder }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="rticon"
      // style={{
      //   background: selectedFolder?.textAlign === "Center" && "#0AABE6",
      // }}
    >
      <g clip-path="url(#clip0_3307_3192)">
        <path
          d="M13.5 13.5V15H4.5V13.5H13.5ZM15.75 8.25V9.75H2.25V8.25H15.75ZM13.5 3V4.5H4.5V3H13.5Z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="clip0_3307_3192">
          <rect width="18" height="18" fill="#fff" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CenterAlign;
