import React, { useRef } from "react";
import mobile_icon from "../../../assets/Icons/mobile_icon.svg";
import { Close } from "@mui/icons-material";
import { useSelector } from "react-redux";

function MainMarginPopup({
  closeMainPopup,
  setMainPopup,
  // closeRadiusWidthPopup,
  // setRadiusWidthPopup,
  title,
  handleTopMarginChange,
  handleLeftMarginChange,
  handleRightMarginChange,
  handleBottomMarginChange,
  handleTopPaddingChange,
  handleLeftPaddingChange,
  handleRightPaddingChange,
  handleBottomPaddingChange,
  handleBottomWidthValueChange,
  handleBorderBottomRightRadiusChange,
  handleBorderBottomleftRadius,
  handleBorderleftRadiusChange,
  handleRightWidthValueChange,
  handleLeftWidthValueChange,
  handleBorderTopRadiusChange,
  handleTopWidthValueChange,
  handleBorderRightRadiusChange,
}) {
  const ref = useRef();
  const { selectedFolder } = useSelector((state) => state.default);

  return (
    <div className="main-container" ref={ref}>
      <div className="flex-icon-txt">
        <div className="title_sec">
          <img src={mobile_icon} alt="" />
          <h5>{title}</h5>
        </div>
        <div className="cross" onClick={() => setMainPopup(false)}>
          <Close />
        </div>
      </div>
      <div className="p-size">
        <p>Configure a value for Mobile screen sizes or larger</p>
      </div>
      <div className="divide-line"></div>
      {title === "Margin Top" && (
        <div className="flx-text-input">
          <p>Static Value:</p>
          <input
            type="number"
            placeholder="auto"
            value={selectedFolder?.margin?.[2]?.top}
            onChange={handleTopMarginChange}
          />
          <p>px</p>
        </div>
      )}
      {title === "Margin Left" && (
        <div className="flx-text-input">
          <p>Static Value:</p>
          <input
            type="number"
            placeholder="auto"
            value={selectedFolder?.margin?.[1]?.left}
            onChange={handleLeftMarginChange}
          />
          <p>px</p>
        </div>
      )}
      {title === "Margin Right" && (
        <div className="flx-text-input">
          <p>Static Value:</p>
          <input
            type="number"
            placeholder="auto"
            value={selectedFolder?.margin?.[3]?.right}
            onChange={handleRightMarginChange}
          />
          <p>px</p>
        </div>
      )}
      {title === "Margin Bottom" && (
        <div className="flx-text-input">
          <p>Static Value:</p>
          <input
            type="number"
            placeholder="auto"
            value={selectedFolder?.margin?.[4]?.bottom}
            onChange={handleBottomMarginChange}
          />
          <p>px</p>
        </div>
      )}
      {title === "Padding Top" && (
        <div className="flx-text-input">
          <p>Static Value:</p>
          <input
            type="number"
            placeholder="auto"
            value={selectedFolder?.padding?.[2]?.top}
            onChange={handleTopPaddingChange}
          />
          <p>px</p>
        </div>
      )}
      {title === "Padding Left" && (
        <div className="flx-text-input">
          <p>Static Value:</p>
          <input
            type="number"
            placeholder="auto"
            value={selectedFolder?.padding?.[1]?.left}
            onChange={handleLeftPaddingChange}
          />
          <p>px</p>
        </div>
      )}
      {title === "Padding Right" && (
        <div className="flx-text-input">
          <p>Static Value:</p>
          <input
            type="number"
            placeholder="auto"
            value={selectedFolder?.padding?.[3]?.right}
            onChange={handleRightPaddingChange}
          />
          <p>px</p>
        </div>
      )}
      {title === "Padding Bottom" && (
        <div className="flx-text-input">
          <p>Static Value:</p>
          <input
            type="number"
            placeholder="auto"
            value={selectedFolder?.padding?.[4]?.bottom}
            onChange={handleBottomPaddingChange}
          />
          <p>px</p>
        </div>
      )}
      {title === "Width top" && (
        <div className="flx-text-input">
          <p>Static Value:</p>
          <input
            type="number"
            placeholder="auto"
            value={selectedFolder?.borderWidth?.[2]?.top}
            onChange={handleTopWidthValueChange}
          />
          <p>px</p>
        </div>
      )}
      {title === "Width left" && (
        <div className="flx-text-input">
          <p>Static Value:</p>
          <input
            type="number"
            placeholder="auto"
            value={selectedFolder?.borderWidth?.[1]?.left}
            onChange={handleLeftWidthValueChange}
          />
          <p>px</p>
        </div>
      )}
      {title === "Width bottom" && (
        <div className="flx-text-input">
          <p>Static Value:</p>
          <input
            type="number"
            placeholder="auto"
            value={selectedFolder?.borderWidth?.[4]?.bottom}
            onChange={handleBottomWidthValueChange}
          />
          <p>px</p>
        </div>
      )}
      {title === "Width right" && (
        <div className="flx-text-input">
          <p>Static Value:</p>
          <input
            type="number"
            placeholder="auto"
            value={selectedFolder?.width?.[3]?.right}
            onChange={handleRightWidthValueChange}
          />
          <p>px</p>
        </div>
      )}
      {title === "Radius left" && (
        <div className="flx-text-input">
          <p>Static Value:</p>
          <input
            type="number"
            placeholder="auto"
            value={selectedFolder?.borderRadius?.[1]?.topLeft}
            onChange={handleBorderleftRadiusChange}
          />
          <p>px</p>
        </div>
      )}
      {title === "Radius Right" && (
        <div className="flx-text-input">
          <p>Static Value:</p>
          <input
            type="number"
            placeholder="auto"
            value={selectedFolder?.borderRadius?.[2]?.topRight}
            onChange={handleBorderRightRadiusChange}
          />
          <p>px</p>
        </div>
      )}
      {title === "Radius Bottom Left" && (
        <div className="flx-text-input">
          <p>Static Value:</p>
          <input
            type="number"
            placeholder="auto"
            value={selectedFolder?.borderRadius?.[3]?.bottomLeft}
            onChange={handleBorderBottomleftRadius}
          />
          <p>px</p>
        </div>
      )}
      {title === "Radius Bottom Right" && (
        <div className="flx-text-input">
          <p>Static Value:</p>
          <input
            type="number"
            placeholder="auto"
            value={selectedFolder?.borderRadius?.[4]?.bottomRight}
            onChange={handleBorderBottomRightRadiusChange}
          />
          <p>px</p>
        </div>
      )}

      <div className="divider"></div>
      <div className="vl-slt-flex ">
        <p>Dynamic Value:</p>
      </div>
      <div className="lw-txt">
        <p>
          If configured, will be applied to Mobile or larger breakpoints. If you
          configure a static style value, it will be used as a fallback if the
          data evaluates to undefined.
        </p>
      </div>
    </div>
  );
}

export default MainMarginPopup;
