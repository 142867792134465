import React, { useEffect, useState } from "react";
import { ArrowBack } from "@mui/icons-material";
import profile from "../../../assets/Image/profile.jpg";
import { Link, useLocation } from "react-router-dom";
import DashboardHeader from "../../../Componets/DashboardHeader/DashboardHeader";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-responsive-modal";
import ModalDelete from "../../../common/svgComponents/ModalDelete.js";

import {
  userProfile,
  updateProfile,
  deleteUserProfile,
} from "../../../redux/actions/authActions";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Profile = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const BASE_URL = process.env.REACT_APP_LIVE_PORT_URL;

  const { getProfile, updateProfileData, deleteProfile } = useSelector(
    (state) => state.auth
  );
  const [errors, setErrors] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({});

  const [formData, setFormData] = useState({
    fname: "",
    lastname: "",
    email: "",
    password: "",
    oldPassword: "",
  });

  useEffect(() => {
    if (getProfile && getProfile.result) {
      const { fname, lastname, email } = getProfile.result?.user;
      setFormData({ ...formData, fname, lastname, email });
    }
  }, [getProfile]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const validatePassword = (password) => {
    const re = /^(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,}$/;
    return re.test(password);
  };

  const handleSubmitBasicInfo = (e) => {
    e.preventDefault();

    if (
      !formData.fname.trim() ||
      !formData.lastname.trim() ||
      !formData.email.trim()
    ) {
      toast.error("Please fill in all fields in Basic Info");
      return;
    }

    const payload = {};
    if (formData.fname !== getProfile.result.user.fname) {
      payload.fname = formData.fname;
    }
    if (formData.lastname !== getProfile.result.user.lastname) {
      payload.lastname = formData.lastname;
    }
    if (formData.email !== getProfile.result.user.email) {
      payload.email = formData.email;
    }

    dispatch(updateProfile(payload));
    toast.success("Basic Info updated successfully");
  };

  const handleSubmitChangePassword = (e) => {
    e.preventDefault();

    if (!formData.oldPassword.trim() || !formData.password.trim()) {
      toast.error("Please fill in all fields in Change Password");
      return;
    }

    const payload = {
      oldPassword: formData.oldPassword,
      password: formData.password,
    };

    dispatch(updateProfile(payload));
    toast.success("Password changed successfully");
  };

  const handleDeleteProfile = async () => {
    try {
      setLoading(true);
      await dispatch(deleteUserProfile());
      toast.success("Profile deleted successfully");
      setTimeout(() => {
        localStorage.removeItem("access_token");
        navigate("/login");
      }, 2000);
    } catch (error) {
      toast.error("Failed to delete profile");
    } finally {
      setLoading(false);
      onCloseModal();
    }
  };

  const onOpenModal = () => setOpenModal(true);
  const onCloseModal = () => setOpenModal(false);

  const inputChangeHandler = (e) => {
    const { name, value, files } = e.target;

    if (!name) return;

    if (name === "profile_image" && files && files[0]) {
      const file = files[0];
      if (!file.name.match(/\.(jpg|JPG|jpeg|JPEG|png|PNG)$/)) {
        toast.error("Select 'jpeg', 'jpg', 'png' File Only.");
      } else {
        setValues({
          ...values,
          [name]: file,
          show_img: URL.createObjectURL(file),
        });
      }
    } else {
      setValues({ ...values, [name]: value });
    }
  };

  return (
    <>
      <DashboardHeader />
      <div className="main_profile">
        <div className="coustom_container2">
          <div className="head_sec">
            <div className="head_inner">
              <Link to="/dashboard">
                <ArrowBack />
                <h3>My Profile</h3>
              </Link>
            </div>
          </div>
          <div className="basic_info">
            <div className="left_sec">
              <label htmlFor="upload">
                <div>
                  <input
                    type="file"
                    id="upload"
                    hidden
                    onChange={inputChangeHandler}
                    name="profile_image"
                  />
                  <img
                    src={
                      values?.show_img
                        ? values?.show_img
                        : getProfile?.user?.profile_img
                        ? getProfile?.user?.profile_img
                        : profile
                    }
                    alt="img"
                  />
                </div>
              </label>

              <div className="name_email">
                <h3>{getProfile?.user?.fname}</h3>
                <p>{getProfile?.user?.email}</p>
              </div>
            </div>

            <div className="inner_info">
              <div className="right_sec">
                <div className="input_sec">
                  <p>Basic Info</p>
                  <div className="inner_input">
                    <div className="fields">
                      <label>
                        First Name
                        <input
                          type="text"
                          name="fname"
                          placeholder="Enter Your First Name"
                          value={formData.fname}
                          onChange={handleChange}
                        />
                      </label>
                    </div>
                    <div className="fields">
                      <label>
                        Last Name
                        <input
                          type="text"
                          name="lastname"
                          placeholder="Enter Your Last Name"
                          value={formData.lastname}
                          onChange={handleChange}
                        />
                      </label>
                    </div>
                  </div>
                  <div className="inner_input">
                    <div className="fields">
                      <label>
                        Email
                        <input
                          type="text"
                          name="email"
                          placeholder="Enter Your Email"
                          value={formData.email}
                          onChange={handleChange}
                        />
                        {errors.email && (
                          <span className="error">{errors.email}</span>
                        )}
                      </label>
                    </div>
                  </div>
                  <div className="submit_btn">
                    <button type="submit" onClick={handleSubmitBasicInfo}>
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="basic_info chng_pswrd">
            <div className="inner_info">
              <div className="right_sec">
                <div className="input_sec">
                  <p>Change Password</p>
                  <span>
                    Enter your existing password and your new password to
                    continue.
                  </span>
                  <div className="inner_input">
                    <div className="fields">
                      <label>
                        Current Password
                        <input
                          type="password"
                          name="oldPassword"
                          placeholder="Enter Your Current"
                          onChange={handleChange}
                          value={formData.oldPassword}
                        />
                      </label>
                    </div>
                    <div className="fields">
                      <label>
                        New Password
                        <input
                          type="password"
                          name="password"
                          placeholder="Enter New Password"
                          onChange={handleChange}
                          value={formData.password}
                        />
                        {errors.password && (
                          <span className="error">{errors.password}</span>
                        )}
                      </label>
                    </div>
                  </div>

                  <div className="submit_btn">
                    <button onClick={handleSubmitChangePassword}>Save</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="basic_info ac_dtls">
            <div className="inner_info_ac">
              <p>Activation Details</p>
              <div className="details">
                <div className="key">
                  {/* <ul>
                    <li>BaseUrl :</li>
                    <li>{BASE_URL}</li>
                  </ul>
                  <ul>
                    <li>Method :</li>
                    <li>Get</li>
                  </ul> */}
                  <ul>
                    <li>Secret Key: </li>
                    <span>{getProfile?.result?.secrets?.apiKey}</span>
                  </ul>
                </div>
                <div className="password">
                  <ul>
                    <li>Secret Password: </li>
                    <span>{getProfile?.result?.secrets?.apiSecret}</span>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="basic_info delete_ac">
            <div className="inner_delete_ac">
              <p>Delete Account</p>
              <span>
                This will delete all of your apps, settings, and profile
                information. It will not delete your billing history and
                transactions, which we will preserve for financial reasons.
              </span>
              <div className="delete">
                <button onClick={onOpenModal}>Delete</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* delete account modal  */}

      <Modal
        open={openModal}
        onClose={onCloseModal}
        center
        classNames={{
          modal: "delete_confirm_popup",
        }}
      >
        <div className="header_bar">
          <ModalDelete />
        </div>
        <div className="body_sec">
          <h4>Are You Sure?</h4>
          <p>This item will be permanently deleted?</p>
        </div>
        <div className="btn_sec">
          <div className="del_btn">
            <button onClick={handleDeleteProfile}>Delete</button>
          </div>
          <div className="cancel_btn">
            <button onClick={onCloseModal}>Cancel</button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Profile;
