import React, { useEffect, useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import upload_icon from "../assets/Image/upload_icon.svg";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { addMyNewProject } from "../redux/actions/projectsActions";
import thanyou_img from "../assets/Icons/thanyou_img.svg";

const Newstartpopup = ({ open, setOpen }) => {
  const dispatch = useDispatch();

  const [values, setValues] = useState({
    ProjectName: "",
    description: "",
  });
  const [selectImage, setSelectImage] = useState(null);
  const [errors, setErrors] = useState({});
  // const [openThankYou, setOpenThankYou] = useState(false);

  // const openThankYouModal = () => {
  //   setOpenThankYou(true);
  // };

  // const closeThankYouModal = () => {
  //   setOpenThankYou(false);
  // };
  const onCloseModal = () => {
    setOpen(false);
    setErrors({});
  };
  const handleInputChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: "" });
  };

  const pageLoading = useSelector((state) => state?.auth?.pageLoading);

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      if (/^image\/(jpeg|png|gif)$/.test(file.type)) {
        setSelectImage(file);
        setErrors({ ...errors, selectImage: "" });
      } else {
        setSelectImage(null);
        setErrors({
          ...errors,
          selectImage: "Please select a image ",
        });
        toast.error("Please select a valid image file (JPEG, PNG, GIF)");
      }
    } else {
      setSelectImage(null);
      setErrors({ ...errors, selectImage: "Please upload a file" });
    }
  };

  const handleSubmit = () => {
    try {
      const newErrors = {};
      if (!values.ProjectName.trim()) {
        newErrors.ProjectName = "Project Name is required";
      }

      if (!values.description.trim()) {
        newErrors.description = "Description is required";
      }

      setErrors(newErrors);

      if (Object.keys(newErrors).length === 0) {
        const formData = new FormData();
        formData.append("projectname", values.ProjectName);
        formData.append("description", values.description);

        if (selectImage && /^image\/(jpeg|png|gif)$/.test(selectImage.type)) {
          formData.append("file", selectImage);
        }

        dispatch(addMyNewProject(formData, onCloseModal));
      }
    } catch (error) {
      console.error("Error creating project:", error);
    }
  };

  // const closeBothModal = () => {
  //   closeThankYouModal();
  //   onCloseModal();
  // };

  return (
    <>
      {pageLoading ? (
        <span>Please Wait ...</span>
      ) : (
        <div className="main-popup">
          <Modal
            open={open}
            onClose={onCloseModal}
            center
            classNames={{
              modal: "new-start-popup",
            }}
          >
            <>
              {/* {!openThankYou ? ( */}
              <>
                {" "}
                <h6>Create New Project</h6>
                <div className="input-box">
                  <label className="label_name">Project Name</label>
                  <div className="name_box">
                    <input
                      type="text"
                      name="ProjectName"
                      className={`form-control ${
                        errors.ProjectName && "is-invalid"
                      }`}
                      placeholder="Enter your Project Name"
                      onChange={handleInputChange}
                    />
                    {errors.ProjectName && (
                      <div className="invalid-feedback">
                        {errors.ProjectName}
                      </div>
                    )}

                    <label>Description</label>
                    <div className="des_box">
                      <textarea
                        className={`${errors.description && "is-invalid"}`}
                        name="description"
                        rows="2"
                        cols={60}
                        placeholder=" description"
                        onChange={handleInputChange}
                      ></textarea>
                      {errors.description && (
                        <div className="invalid-feedback">
                          {errors.description}
                        </div>
                      )}
                    </div>

                    <div className="file_div_sec d-grid gap-2">
                      <label className="Upload_logo">
                        <img src={upload_icon} alt="" />
                        <h5>Upload logo</h5>
                        <input
                          type="file"
                          name="selectImage"
                          onChange={handleFileChange}
                        />
                        {selectImage && (
                          <div>
                            <li>{selectImage.name}</li>
                          </div>
                        )}
                        {errors.selectImage && (
                          <div className="invalid-feedback2">
                            {errors.selectImage}
                          </div>
                        )}
                      </label>
                    </div>

                    <div className="d-grid gap-2">
                      <button type="submit" onClick={handleSubmit}>
                        Create New Project
                      </button>
                    </div>
                  </div>
                </div>
              </>

              {/* //: (
              //   <div className="top_head">
              //     <img src={thanyou_img} alt="" />
              //     <div className="body_sec">
              //       <div className="left_bar">
              //         <h5>Thank You</h5>
              //         <p>
              //           Lorem ipsum dolor sit amet, consectetur adipiscing elit,
              //           sed do eiusmod tempor incididunt ut labore et dolore
              //           magna aliqua. Ut enim ad minim veniam, quis nostrud
              //           exercitation
              //         </p>
              //         <button className="btn_close" onClick={closeBothModal}>
              //           Back To Dashboard
              //         </button>
              //       </div>
              //     </div>
              //   </div>
              // )} */}
            </>
          </Modal>

          {/* <Modal
              open={openThankYou}
              onClose={closeThankYouModal}
              center
              classNames={{
                modal: "api_cloud_assets_modal_ThankYou",
              }}
            >
              <div className="top_head">
                <img src={thanyou_img} alt="" />
                <div className="body_sec">
                  <div className="left_bar">
                    <h5>Thank You</h5>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    </p>
                    <button className="btn_close" onClick={closeThankYouModal}>
                      Back To home
                    </button>
                  </div>
                </div>
              </div>
            </Modal> */}
        </div>
      )}
    </>
  );
};

export default Newstartpopup;
