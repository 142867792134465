import React from "react";

const InfoSvg = () => {
  return (
    <div>
      <svg
        width="20"
        height="20"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.5 1.25C6.26387 1.25 5.0555 1.61656 4.02769 2.30331C2.99988 2.99007 2.1988 3.96619 1.72576 5.10823C1.25271 6.25027 1.12894 7.50693 1.37009 8.71931C1.61125 9.93169 2.20651 11.0453 3.08059 11.9194C3.95466 12.7935 5.06831 13.3888 6.28069 13.6299C7.49307 13.8711 8.74974 13.7473 9.89178 13.2742C11.0338 12.8012 12.0099 12.0001 12.6967 10.9723C13.3834 9.94451 13.75 8.73613 13.75 7.5C13.75 6.67924 13.5883 5.86651 13.2743 5.10823C12.9602 4.34994 12.4998 3.66095 11.9194 3.08058C11.3391 2.50022 10.6501 2.03984 9.89178 1.72575C9.13349 1.41166 8.32076 1.25 7.5 1.25ZM7.1875 3.125C7.37292 3.125 7.55418 3.17998 7.70835 3.283C7.86252 3.38601 7.98268 3.53243 8.05364 3.70373C8.1246 3.87504 8.14316 4.06354 8.10699 4.2454C8.07082 4.42725 7.98153 4.5943 7.85042 4.72541C7.7193 4.85652 7.55226 4.94581 7.3704 4.98199C7.18854 5.01816 7.00004 4.99959 6.82874 4.92864C6.65743 4.85768 6.51101 4.73752 6.408 4.58335C6.30499 4.42918 6.25 4.24792 6.25 4.0625C6.25 3.81386 6.34877 3.5754 6.52459 3.39959C6.70041 3.22377 6.93886 3.125 7.1875 3.125ZM8.75 11.25H8.125C7.79348 11.25 7.47554 11.1183 7.24112 10.8839C7.0067 10.6495 6.875 10.3315 6.875 10V7.5C6.70924 7.5 6.55027 7.43415 6.43306 7.31694C6.31585 7.19973 6.25 7.04076 6.25 6.875C6.25 6.70924 6.31585 6.55027 6.43306 6.43306C6.55027 6.31585 6.70924 6.25 6.875 6.25H7.5C7.66576 6.25 7.82473 6.31585 7.94194 6.43306C8.05915 6.55027 8.125 6.70924 8.125 6.875V10H8.75C8.91576 10 9.07473 10.0658 9.19194 10.1831C9.30915 10.3003 9.375 10.4592 9.375 10.625C9.375 10.7908 9.30915 10.9497 9.19194 11.0669C9.07473 11.1842 8.91576 11.25 8.75 11.25Z"
          fill="#ffff"
        />
      </svg>
    </div>
  );
};

export default InfoSvg;
