import React from "react";

const fetchSvg = ({ width, height, change }) => {
  return (
    <div>
      {" "}
      <svg
        width={width}
        height={height}
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14 0C8.099 0 1.75 1.77887 1.75 5.6875V22.3125C1.75 26.2194 8.099 28 14 28C19.9001 28 26.25 26.2194 26.25 22.3125V5.6875C26.25 1.77887 19.8993 0 14 0ZM24.5 22.3125C24.5 24.486 19.7986 26.25 14 26.25C8.2005 26.25 3.5 24.486 3.5 22.3125V19.0435C5.30775 20.9064 9.66875 21.875 14 21.875C18.3312 21.875 22.6922 20.9064 24.5 19.0435V22.3125ZM24.5 17.0625H24.4965C24.4965 17.0713 24.5 17.0809 24.5 17.0896C24.5 19.25 19.7986 21 14 21C8.20138 21 3.5 19.25 3.5 17.0896C3.5 17.0809 3.5035 17.0713 3.5035 17.0625H3.5V13.7935C5.30775 15.6564 9.66875 16.625 14 16.625C18.3312 16.625 22.6922 15.6564 24.5 13.7935V17.0625ZM24.5 11.8125H24.4965C24.4965 11.8212 24.5 11.8309 24.5 11.8396C24.5 14 19.7986 15.75 14 15.75C8.20138 15.75 3.5 14 3.5 11.8396C3.5 11.8309 3.5035 11.8212 3.5035 11.8125H3.5V8.806C5.79338 10.5534 9.99687 11.375 14 11.375C18.0031 11.375 22.2066 10.5534 24.5 8.806V11.8125ZM14 9.625C8.2005 9.625 3.5 7.861 3.5 5.6875C3.5 3.51225 8.2005 1.75 14 1.75C19.7986 1.75 24.5 3.51225 24.5 5.6875C24.5 7.861 19.7986 9.625 14 9.625Z"
          fill={change ? "#fff" : "#3c4c6e"}
        />
        <path
          d="M21.875 23.625C22.3582 23.625 22.75 23.2332 22.75 22.75C22.75 22.2668 22.3582 21.875 21.875 21.875C21.3918 21.875 21 22.2668 21 22.75C21 23.2332 21.3918 23.625 21.875 23.625Z"
          fill={change ? "#fff" : "#3c4c6e"}
        />
        <path
          d="M21.875 18.375C22.3582 18.375 22.75 17.9832 22.75 17.5C22.75 17.0168 22.3582 16.625 21.875 16.625C21.3918 16.625 21 17.0168 21 17.5C21 17.9832 21.3918 18.375 21.875 18.375Z"
          fill={change ? "#fff" : "#3c4c6e"}
        />
        <path
          d="M21.875 13.125C22.3582 13.125 22.75 12.7332 22.75 12.25C22.75 11.7668 22.3582 11.375 21.875 11.375C21.3918 11.375 21 11.7668 21 12.25C21 12.7332 21.3918 13.125 21.875 13.125Z"
          fill={change ? "#fff" : "#3c4c6e"}
        />
      </svg>
    </div>
  );
};

export default fetchSvg;
