export const SET_BTN_LOADING = "SET_BTN_LOADING";

export const SET_PAGE_LOADING = "SET_PAGE_LOADING";

export const SET_MY_PROJECTS = "SET_MY_PROJECTS";

export const SET_MY_NEW_PROJECT = "SET_MY_NEW_PROJECT";

export const SET_DELETE_MY_PROJECT = "SET_DELETE_MY_PROJECT";

export const SET_NEW_SIGNUP_USER = "SET_NEW_SIGNUP_USER";

export const VERIFY_OTP_USER = "VERIFY_OTP_USER";

export const SET_NEW_LOGIN_USER = "SET_NEW_LOGIN_USER";

export const SET_NEW_PASSWORD = "SET_NEW_PASSWORD";

export const SET_NEW_VERIFY_OTP = "SET_NEW_VERIFY_OTP";

export const SET_NEW_USER_PASSWORD = "SET_NEW_USER_PASSWORD";

export const SET_CANVAS_DATA = "SET_CANVAS_DATA";

export const SET_DEFAULT_VALUES = "SET_DEFAULT_VALUES";

export const SET_PROFILE_USER = "SET_PROFILE_USER";

export const SET_MY_ASSETS = "SET_MY_ASSETS";

export const SET_MY_NEW_ASSETS = "SET_MY_NEW_ASSETS";

export const SET_DELETE_MY_NEW_ASSETS = "SET_DELETE_MY_NEW_ASSETS";

export const SET_SAVE_BLOCK = "SET_SAVE_BLOCK";

export const SET_NEW_SAVE_BLOCK = "SET_NEW_SAVE_BLOCK";

export const SET_DELETE_NEW_SAVE_BLOCK = "SET_DELETE_NEW_SAVE_BLOCK";

export const ADD_NEW_SCREEN = "ADD_NEW_SCREEN";

export const GET_MY_SCREEN = "GET_MY_SCREEN";

export const SET_SELECTED_CANVAS = "SET_SELECTED_CANVAS";

export const SET_DATA_BLOCK = "SET_DATA_BLOCK";

export const RESET_CANVAS_DATA = "RESET_CANVAS_DATA";

export const SAVE_CANVAS_DATA = "SAVE_CANVAS_DATA";

export const ADD_SCREEN = "ADD_SCREEN";

export const DELETE_SCREEN = "DELETE_SCREEN";

export const GET_MY_SCREEN_DATA = "GET_MY_SCREEN_DATA";

export const DELETE_MY_SCREEN = "DELETE_MY_SCREEN";

export const EDIT_MY_SCREEN_DATA = "EDIT_MY_SCREEN_DATA";

export const SET_MYNEWDATA = "SET_MYNEWDATA";

export const SET_MYNEWDATA2 = "SET_MYNEWDATA2";

export const SET_DUPLICATED_FOLDER = "SET_DUPLICATED_FOLDER";

export const SET_FOLDER_WIDTH_HEIGHT = "SET_FOLDER_WIDTH_HEIGHT";

export const UPDATE_CANVAS_BACKGROUND = "UPDATE_CANVAS_BACKGROUND";

export const FONT_ICON_TYPE = "FONT_ICON_TYPE";

export const SET_FLASHLIST = "SET_FLASHLIST";

export const GET_FLASHLIST = "GET_FLASHLIST";

export const DELETE_FLASHLIST = "DELETE_FLASHLIST";

export const SET_ENDPOINT = "SET_ENDPOINT";

export const GET_ENDPOINTS = "GET_ENDPOINTS";

export const DELETE_ENPOINT = "DELETE_ENPOINT";

export const MYNEW_DATA_DATA = "MYNEW_DATA_DATA";

export const SET_NEW_STYLE_DATA = "SET_NEW_STYLE_DATA";

export const SET_GET_STYLE_DATA = "SET_GET_STYLE_DATA";

export const SET_MY_FLASHLIST_KEYS = "SET_MY_FLASHLIST_KEYS";

export const SET_MY_FLASHLIST_KEYS_DATA = "SET_MY_FLASHLIST_KEYS_DATA";

export const UPDATE_PROFILE_USER = "UPDATE_PROFILE_USER";

export const DELETE_PROFILE_USER = "DELETE_PROFILE_USER";

export const EDIT_MY_FLASHLIST = "EDIT_MY_FLASHLIST";

export const EDIT_MY_ENDPOINT = "EDIT_MY_ENDPOINT";

export const SET_MY_FLASHLIST_DATA = "SET_MY_FLASHLIST_DATA";

export const GET_MY_FLASHLIST_DATA = "GET_MY_FLASHLIST_DATA";

export const SET_MY_MERGE_DATA = "SET_MY_MERGE_DATA";

export const SET_GET_VERSION_LIST = "SET_GET_VERSION_LIST";

export const SET_ADD_PUBLISH_SCREEN = "SET_ADD_PUBLISH_SCREEN";

export const SET_DELETE_VERSION = "SET_DELETE_VERSION";

export const SET_SDK_VERSION_LIST = "SET_SDK_VERSION_LIST";

export const SET_MY_SDK_VERSION_DATA = "SET_MY_SDK_VERSION_DATA";

export const SET_SDK_VERSION_RESOURCE_LIST = "SET_SDK_VERSION_RESOURCE_LIST";

export const SET_DUPLICATE_PROJECT = "SET_DUPLICATE_PROJECT";
export const SET_MY_PROJECT_SCREEN_LIST = "SET_MY_PROJECT_SCREEN_LIST";

export const SET_SHARE_PROJECT = "SET_SHARE_PROJECT";
export const GET_MY_SCREENMODAL_DATA = "GET_MY_SCREENMODAL_DATA";
export const SET_RELOAD_UPDATE = "SET_RELOAD_UPDATE";

export const SET_MY_MERGE_DATA2 = "SET_MY_MERGE_DATA2";

export const SET_RELOAD = "SET_RELOAD";
export const SET_MY_SDK_VERSION_DATA_DASHBOARD =
  "SET_MY_SDK_VERSION_DATA_DASHBOARD";
