import { useState } from "react";
import "./reset.css";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetPassword } from "../../redux/actions/authActions";

export const Reset = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState({});
  const [errorMessage, setErrorMessage] = useState("");

  const handleEmailChange = (e) => {
    setEmail({ ...email, [e.target.name]: e.target.value });
    setErrorMessage(""); // Clear the error message when the user starts typing
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // dispatch(resetPassword(email, navigate));
    if (!email.email || email.email.trim() === "") {
      setErrorMessage("Email is required");
    } else {
      dispatch(resetPassword(email, navigate));
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="main_Container">
          <div className="sub_con">
            <div className="h1_pass">
              <h2>Reset Your Password </h2>
            </div>
            <div className="p_massage">
              <p>Enter your email and we'll send you instructions</p>
            </div>

            <div className="input_email">
              <div>
                <label htmlFor="email">Email Address</label>
                <input
                  type="email"
                  onChange={(e) => handleEmailChange(e)}
                  placeholder="Enter Your Email"
                  name="email"
                />
                {errorMessage && (
                  <p
                    className="error-message"
                    style={{ color: "red", fontSize: "15px" }}
                  >
                    {errorMessage}
                  </p>
                )}
              </div>
            </div>
            <div className="send_btn">
              <button type="submit">Send</button>
            </div>

            <div className="p-max">
              <p>
                By creating an account, you agree to accept and abide by our
                <br />
                terms of services and privacy policy
              </p>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
