import React from "react";

const JustifyAlignSecond = ({ typography2 }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        background: typography2?.align === "flex-end" ? "#1A237E" : "none",
      }}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16 15V16H0L0 15H16ZM16 0V1H0L0 0L16 0Z"
        fill={typography2?.align === "flex-end" ? "white" : "#1A237E"}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11 9.5C11.2652 9.5 11.5196 9.60536 11.7071 9.79289C11.8946 9.98043 12 10.2348 12 10.5V12.5C12 12.7652 11.8946 13.0196 11.7071 13.2071C11.5196 13.3946 11.2652 13.5 11 13.5H5C4.73478 13.5 4.48043 13.3946 4.29289 13.2071C4.10536 13.0196 4 12.7652 4 12.5V10.5C4 10.2348 4.10536 9.98043 4.29289 9.79289C4.48043 9.60536 4.73478 9.5 5 9.5H11ZM11 2.5C11.2652 2.5 11.5196 2.60536 11.7071 2.79289C11.8946 2.98043 12 3.23478 12 3.5V5.5C12 5.76522 11.8946 6.01957 11.7071 6.20711C11.5196 6.39464 11.2652 6.5 11 6.5H5C4.73478 6.5 4.48043 6.39464 4.29289 6.20711C4.10536 6.01957 4 5.76522 4 5.5V3.5C4 3.23478 4.10536 2.98043 4.29289 2.79289C4.48043 2.60536 4.73478 2.5 5 2.5H11Z"
        fill={typography2?.align === "flex-end" ? "white" : "#1A237E"}
      />
    </svg>
  );
};

export default JustifyAlignSecond;
