import React from "react";
import TestimonialSlider from "./TestimonialSlider";


const Testimonials = () => {
  return (
    <>
      <div className="main-testi">
        <div className="coustom_container">
          <div className="inner">
            <div className="left-testi">
              <p className="clr">Testimonials</p>
              <h2>What Our Client Say</h2>
              <p>
                But I must explain to you how all this mistaken idea of
                denouncing pleasure<br/> and praising pain was born and I will give
                you a complete account of the <br/>system, and expound great explorer
                of the truth, the master-builder of <br/> human pleasure, but because
                those who do not.
              </p>
            </div>
            <div className="right-testi">
                <TestimonialSlider />
            </div>
          </div>       
        </div>
      </div>
    </>
  );
};

export default Testimonials;
