import React from "react";

const PortraitSvg = ({ handlePortraitClick }) => {
  return (
    <div>
      <svg
        width="14"
        height="20"
        viewBox="0 0 14 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={handlePortraitClick}
      >
        <path
          d="M11.5 19.75H2.5C1.90326 19.75 1.33097 19.5129 0.90901 19.091C0.487053 18.669 0.25 18.0967 0.25 17.5V2.5C0.25 1.90326 0.487053 1.33097 0.90901 0.90901C1.33097 0.487053 1.90326 0.25 2.5 0.25H11.5C12.0967 0.25 12.669 0.487053 13.091 0.90901C13.5129 1.33097 13.75 1.90326 13.75 2.5V17.5C13.75 18.0967 13.5129 18.669 13.091 19.091C12.669 19.5129 12.0967 19.75 11.5 19.75ZM2.5 1.75C2.30109 1.75 2.11032 1.82902 1.96967 1.96967C1.82902 2.11032 1.75 2.30109 1.75 2.5V17.5C1.75 17.6989 1.82902 17.8897 1.96967 18.0303C2.11032 18.171 2.30109 18.25 2.5 18.25H11.5C11.6989 18.25 11.8897 18.171 12.0303 18.0303C12.171 17.8897 12.25 17.6989 12.25 17.5V2.5C12.25 2.30109 12.171 2.11032 12.0303 1.96967C11.8897 1.82902 11.6989 1.75 11.5 1.75H2.5Z"
          fill="white"
        />
        <path
          d="M8.5 3H5.5C5.10218 3 4.72064 2.89464 4.43934 2.70711C4.15804 2.51957 4 2.26522 4 2V1.5C4 1.36739 4.07902 1.24021 4.21967 1.14645C4.36032 1.05268 4.55109 1 4.75 1H9.25C9.44891 1 9.63968 1.05268 9.78033 1.14645C9.92098 1.24021 10 1.36739 10 1.5V2C10 2.26522 9.84196 2.51957 9.56066 2.70711C9.27936 2.89464 8.89782 3 8.5 3Z"
          fill="white"
        />
      </svg>
    </div>
  );
};

export default PortraitSvg;
