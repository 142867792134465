import React, { useState, useEffect } from "react";
import DocsNavbar from "./DocsNavbar";
import { useParams, useLocation, useNavigate } from "react-router-dom";

import MainDocsPage from "./MainDocsPage";

const Document = () => {
  const { docId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedData, setSelectedData] = useState({});
  const [tabs, setTabs] = useState();
  const [documentLoading, setDocumentLoading] = useState(true);
  const [showSearch, setShowSearch] = useState(false);

  const listItems = [
    {
      url: "welcome-to-figma-clone",
      name: "Welcome To Figma Clone",
      headings: [
        { url: "before-you-build", name: "Before you Build" },
        { url: "start-building!", name: "Start Building!" },
        {
          url: "add-advanced-functionality",
          name: "Add Advanced Functionality",
        },
      ],
    },
    {
      url: "dashboard",
      name: "Dashboard",
      headings: [
        { url: "workspaces", name: "Workspaces" },
        { url: "starter-apps", name: "Starter Apps" },
        { url: "example-screens", name: "Example Screens" },
        { url: "expert-services", name: "Expert Services" },
        { url: "expert-projects", name: "Expert Projects" },
        { url: "support-and-resources", name: "Support and Resources" },
        { url: "account-settings", name: "Account Settings" },
      ],
    },
    {
      url: "workspaces",
      name: "Workspaces",
      heading: [
        { url: "setting-up-a-workspace", name: "Setting Up a Workspace" },
        { url: "workspace-settings", name: "Workspace Settings" },
        { url: "settings", name: "Settingss" },
        { url: "collaborators", name: "Collaborators" },
        { url: "inviting-collaborators", name: "Inviting Collaborators" },
      ],
    },
    {
      url: "community-resources",
      name: "Community Resources",
      heading: [
        { url: "javaScript", name: "JavaScript" },
        { url: "react-and-react-native", name: "React and React Native" },
        { url: "settings", name: "Settingss" },
        { url: "data-and-apis", name: "Data and APIs" },
        { url: "payments", name: "Payments" },
      ],
    },
    {
      url: "faqs",
      name: "FAQs",
      heading: [
        { url: "why-react-native?", name: "Why React Native?" },
        { url: "why-expo?", name: "Why Expo?" },
        { url: "expo-cli", name: "Expo CLI" },
        {
          url: "why-doesn't-my-app-in-the-browser-(Web Preview)-match-my-app-in-Live-Preview?",
          name: "Why doesn't my app in the browser (Web Preview) match my app in Live Preview?",
        },
        {
          url: "can-I-put-my-app-on-the-app-store/Google-play-store?",
          name: "Can I put my app on the App Store/Google Play Store?",
        },
      ],
    },
    { url: "How do I request a feature?", name: "How do I request a feature?" },
    { url: "assists", name: "Assists" },
    { url: "settings", name: "Settings" },
    { url: "environments", name: "Environments" },
    { url: "live-preview", name: "Live Preview" },
    { url: "console-logs", name: "Console Logs" },
    { url: "components", name: "Components" },
    {
      url: "intro-to-components",
      name: "Intro To Components",
    },
    {
      url: "basic",
      name: "Basic",
      children: [
        { url: "screen", name: "Screen", depth: 2 },
        { url: "icon", name: "Icon", depth: 2 },
        { url: "text", name: "Text", depth: 2 },
        { url: "view", name: "View", depth: 2 },
      ],
    },
    {
      url: "buttons",
      name: "Buttons",
      children: [
        { url: "button", name: "Button", depth: 2 },
        { url: "icon-button", name: "Icon Button", depth: 2 },
        {
          url: "link",
          name: "Link",
          depth: 2,
        },
        { url: "touchable", name: "Touchable", depth: 2 },
        { url: "pressable", name: "Pressable", depth: 2 },
      ],
    },
    {
      url: "views",
      name: "Views",
      children: [
        { url: "blur-view", name: "Blur View", depth: 2 },
        {
          url: "keyboard-avoiding-view",
          name: "Keyboard Avoiding View",
          depth: 2,
        },
        {
          url: "keyboard-aware-scroll-view",
          name: "Keyboard Aware Scroll View",
          depth: 2,
        },
        { url: "scroll-view", name: "Scroll View", depth: 2 },
        { url: "shadow", name: "Shadow", depth: 2 },
      ],
    },
    {
      url: "flex-layout",
      name: "Flex Layout",
      children: [
        { url: "aspect-ratio", name: "Aspect Ratio", depth: 2 },
        { url: "center", name: "Center", depth: 2 },
        {
          url: "circle",
          name: "Circle",
          depth: 2,
        },
        { url: "square", name: "Square", depth: 2 },
        { url: "linear-gradient", name: "Linear Gradient", depth: 2 },
        { url: "h-stack", name: "H Stack", depth: 2 },
        { url: "v-stack", name: "V Stack", depth: 2 },
        { url: "z-stack", name: "Z Stack", depth: 2 },
        { url: "divider", name: "Divider", depth: 2 },
        { url: "spacer", name: "Spacer", depth: 2 },
      ],
    },

    {
      url: "containers",
      name: "Containers",
      children: [
        { url: "accordion", name: "Accordion", depth: 2 },
        { url: "surface", name: "Surface", depth: 2 },
      ],
    },
    {
      url: "media",
      name: "Media",
      children: [
        { url: "image", name: "Image", depth: 2 },
        { url: "image-background", name: "Image Background", depth: 2 },
        { url: "svg", name: "SVG", depth: 2 },
        { url: "audio-player", name: "Audio Player", depth: 2 },
        { url: "video-player", name: "Video Player", depth: 2 },
        { url: "youtube-player", name: "Youtube Player", depth: 2 },
      ],
    },
    {
      url: "lists-data",
      name: "Lists & Data",
      children: [
        { url: "fetch", name: "Fetch", depth: 2 },
        { url: "list", name: "List", depth: 2 },
        { url: "flashList", name: "FlashList", depth: 2 },
        { url: "masonry-list", name: "Masonry List", depth: 2 },
        {
          url: "section-list",
          name: "Section List",
          depth: 2,
          children: [
            { url: "section-header", name: "Section Header", depth: 3 },
          ],
        },
        {
          url: "swipeable-list",
          name: "Swipeable List",
          depth: 2,
          children: [
            { url: "swipable-item", name: "Swipable Item", depth: 3 },
            {
              url: "swipeable-item-button",
              name: "Swipeable Item Button",
              depth: 3,
            },
          ],
        },
        {
          url: "table",
          name: "Table",
          depth: 2,
          children: [
            { url: "table-row", name: "Table Row", depth: 3 },
            { url: "table-cell", name: "Table Cell", depth: 3 },
          ],
        },
      ],
    },
    {
      url: "form-inputs",
      name: "Form Inputs",
      children: [
        { url: "text-input", name: "Text Input", depth: 2 },
        { url: "text-area", name: "Text Area", depth: 2 },
        { url: "number-input", name: "Number Input", depth: 2 },
        { url: "styled-text-field", name: "Styled Text Field", depth: 2 },
        {
          url: "picker",
          name: "Picker",
          depth: 2,
          children: [{ url: "picker-item", name: "Picker Item", depth: 3 }],
        },
        { url: "date-picker", name: "Date Picker", depth: 2 },
        { url: "multi-select-picker", name: "Multi-Select Picker", depth: 2 },
        { url: "pin-input", name: "PIN Input", depth: 2 },
      ],
    },
    {
      url: "form-controls",
      name: "Form Controls",
      children: [
        { url: "switch", name: "Switch", depth: 2 },
        { url: "switch-row", name: "Switch Row", depth: 2 },
        { url: "checkbox", name: "Checkbox", depth: 2 },

        { url: "checkbox-row", name: "Checkbox Row", depth: 2 },
        { url: "radio-button", name: "Radio Button", depth: 2 },
        { url: "radio-button-row", name: "Radio Button Row", depth: 2 },
        { url: "radio-button-group", name: "Radio Button Group", depth: 2 },
        { url: "slider", name: "Slider", depth: 2 },
        { url: "star-rating", name: "Star Rating", depth: 2 },
        { url: "stepper", name: "Stepper", depth: 2 },
      ],
    },
    {
      url: "maps",
      name: "Maps",
      children: [
        { url: "map-view", name: "Map View", depth: 2 },
        { url: "map-marker", name: "Map Marker", depth: 2 },
        { url: "map-callout", name: "Map Callout", depth: 2 },
        {
          url: "map-marker-cluster",
          name: "Map Marker Cluster",
          depth: 2,
          children: [
            {
              url: "map-marker-cluster-view",
              name: "Map Marker Cluster View",
              depth: 3,
            },
          ],
        },
        {
          url: "map-circle",
          name: "Map Circle",
          depth: 2,
        },
      ],
    },
    {
      url: "swipers",
      name: "Swipers",
      children: [
        {
          url: "swiper",
          name: "Swiper",
          depth: 2,
          children: [{ url: "swiper-item", name: "Swiper Item", depth: 3 }],
        },
        {
          url: "deck-swiper",
          name: "Deck Swiper",
          depth: 2,
          children: [
            { url: "deck-swiper-card", name: "Deck Swiper Card", depth: 3 },
          ],
        },
        {
          url: "tab-view",
          name: "Tab View",
          depth: 2,
          children: [{ url: "tab-view-item", name: "Tab View Item", depth: 3 }],
        },
      ],
    },
    {
      url: "text-elements",
      name: "Text Elements",
      children: [
        { url: "headings", name: "Headings", depth: 2 },
        { url: "markdown", name: "Markdown", depth: 2 },
      ],
    },
    {
      url: "overlays",
      name: "Overlays",
      children: [
        { url: "modal", name: "Modal", depth: 2 },
        { url: "bottom-sheet", name: "Bottom Sheet", depth: 2 },
        {
          url: "action-sheet",
          name: "Action Sheet",
          depth: 2,
          children: [
            { url: "action-sheet-item", name: "Action Sheet Item", depth: 3 },
            {
              url: "action-sheet-cancel",
              name: "Action Sheet Cancel",
              depth: 3,
            },
          ],
        },
      ],
    },
    {
      url: "utilities",
      name: "Utilities",
      children: [
        { url: "activity-indicator", name: "Activity Indicator", depth: 2 },
        { url: "linear-progress", name: "Linear Progress", depth: 2 },
        { url: "circular-progress", name: "Circular Progress", depth: 2 },
        { url: "web-view", name: "Web View", depth: 2 },
        { url: "html-view", name: "HTML View", depth: 2 },
        { url: "custom-code", name: "Custom Code", depth: 2 },
      ],
    },
    { url: "saved-blocks", name: "Saved Blocks", depth: 1 },
    { url: "styling", name: "Styling", depth: 1 },
    { url: "intro-to-styling", name: "Intro To Styling", depth: 1 },
    { url: "theme-colors", name: "Theme Colors", depth: 1 },
    { url: "breakpoints", name: "Breakpoints", depth: 1 },
    { url: "stylesheets", name: "Stylesheets", depth: 1 },
    { url: "dynamic-styling", name: "Dynamic Styling", depth: 1 },
    { url: "conditional-display", name: "Conditional Display", depth: 1 },
    { url: "typography", name: "Typography", depth: 1 },
    { url: "margins-padding", name: "Margins & Pedding", depth: 1 },
    {
      url: "borders-background-effect",
      name: "Borders, Background, Effect",
      depth: 1,
    },
    { url: "size-position", name: "Size & Position", depth: 1 },
    { url: "variables", name: "Variables", depth: 1 },
    { url: "intro-to-variables", name: "Intro To Variables", depth: 1 },
    { url: "app-variables", name: "App Variables", depth: 1 },
    { url: "device-variables", name: "Device Variables", depth: 1 },
    { url: "screen-variables", name: "Screen Variables", depth: 1 },
    { url: "system-variables", name: "System Variables", depth: 1 },
    { url: "variables-in-code", name: "Variables In Code", depth: 1 },
    { url: "navigation", name: "Navigation", depth: 1 },
    { url: "intro-to-navigation", name: "Intro To Navigation", depth: 1 },
    { url: "stack-navigator", name: "Stack Navigator", depth: 1 },
    {
      url: "tab-navigator",
      name: "Tab Navigator",
      depth: 1,
      children: [{ url: "Tab Bar Block", name: "Tab Bar Block", depth: 2 }],
    },
    { url: "passing-data", name: "Passing Data", depth: 1 },
    { url: "header-buttons", name: "Header Buttons", depth: 1 },
    { url: "interactions", name: "Interactions", depth: 1 },
    { url: "action-editor", name: "Action Editor", depth: 1 },
    { url: "triggers", name: "Triggers", depth: 1 },
    { url: "actions", name: "Actions", depth: 1 },
    { url: "custom-code", name: "Custom Code", depth: 1 },
    { url: "intro-to-custom-code", name: "Intro To Custom Code", depth: 1 },
    { url: "editor", name: "Editor", depth: 1 },
    { url: "code-assistant", name: "Code Assistant", depth: 1 },
    { url: "functions", name: "Functions", depth: 1 },
    { url: "files", name: "Files", depth: 1 },
    { url: "jsx", name: "JSX", depth: 1 },
    { url: "packages", name: "Packages", depth: 1 },
    { url: "connecting-to-data", name: "Connecting To Data", depth: 1 },
    { url: "rest-api-services", name: "Rest API Services", depth: 1 },
    { url: "rest-api-endpoints", name: "Rest API Endpoints", depth: 1 },
    { url: "working-with-data", name: "Working With Data", depth: 1 },
    { url: "fetching-data", name: "Fetching Data", depth: 1 },
    { url: "submitting-data", name: "Submitting Data", depth: 1 },
    { url: "data-integrations", name: "Data Integrations", depth: 1 },
    { url: "rest-api-integrations", name: "REST API Integrations", depth: 1 },
    { url: "authentications", name: "Authentications", depth: 1 },
    {
      url: "intro-to-authentications",
      name: "Intro To Authentications",
      depth: 1,
    },
    { url: "back4app", name: "Back4App", depth: 1 },
    { url: "backendless", name: "Backend less", depth: 1 },
    { url: "bubble", name: "Bubble", depth: 1 },
    { url: "supabase", name: "Supabase", depth: 1 },
    { url: "xano", name: "Xano", depth: 1 },
    { url: "laravel", name: "Laravel", depth: 1 },
    {
      url: "exporting-publishing-sharing",
      name: "Exporting Publishing & Sharing",
      depth: 1,
    },
    { url: "intro-to-publishing", name: "Intro To Publishing", depth: 1 },
    {
      url: "publishing-your-app-to-the-apple-app-store",
      name: "Publishing Your App to the Apple App Store",
      depth: 1,
    },
    {
      url: "publishing-your-app-to-the-google-play-store",
      name: "Publishing Your App to the Google Play Store",
      depth: 1,
    },
    {
      url: "publishing-your-app-as-a-pwa",
      name: "Publishing Your App as a PWA",
      depth: 1,
    },
    {
      url: "sharing-your-project-online",
      name: "Sharing Your Project Online",
      depth: 1,
    },
    { url: "github-commit", name: "GitHub Commit", depth: 1 },
    { url: "push-notifications", name: "Push Notifications", depth: 1 },
    {
      url: "intro-to-push-notifications",
      name: "Intro To Push Notifications",
      depth: 1,
    },
    {
      url: "push-notifications-for-ios",
      name: "Push Notifications For iOS",
      depth: 1,
    },
  ];
  const specialItems = [
    "Welcome To Figma Clone",
    "Components",
    "Styling",
    "Variables",
    "Navigation",
    "Interactions",
    "Custom Code",
    "Connecting To Data",
    "Working With Data",
    "Data Integrations",
    "Authentications",
    "Exporting Publishing & Sharing",
    "Push Notifications",
  ];

  useEffect(() => {
    if (listItems) {
      setSelectedData(listItems[0]);
    }
  }, []);

  useEffect(() => {
    if (docId) {
      setDocumentLoading(true);
      setTimeout(() => {
        setTabs(docId);
        setDocumentLoading(false);
      }, 400);
    }
  }, [docId]);

  console.log(docId, "docId");

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  useEffect(() => {
    if (docId) {
      for (let i = 0; i < listItems.length; i++) {
        if (listItems[i].url === docId) {
          setSelectedData(listItems[i]);
        }
      }
    }
  }, [docId]);

  const navigateForward = () => {
    const currentIndex = listItems.findIndex((item) => item.url === docId);
    if (currentIndex < listItems.length - 1) {
      navigate(`/docs/${listItems[currentIndex + 1].url}`);
    }
  };

  const navigateBackward = () => {
    const currentIndex = listItems.findIndex((item) => item.url === docId);
    if (currentIndex > 0) {
      navigate(`/docs/${listItems[currentIndex - 1].url}`);
    }
  };
  return (
    <div>
      <DocsNavbar
        listItems={listItems}
        location={location}
        docId={docId}
        selectedData={selectedData}
        setSelectedData={setSelectedData}
        tabs={tabs}
        setTabs={setTabs}
        documentLoading={documentLoading}
        setDocumentLoading={setDocumentLoading}
        specialItems={specialItems}
      />
      <MainDocsPage
        listItems={listItems}
        location={location}
        docId={docId}
        selectedData={selectedData}
        setSelectedData={setSelectedData}
        tabs={tabs}
        setTabs={setTabs}
        documentLoading={documentLoading}
        setDocumentLoading={setDocumentLoading}
        specialItems={specialItems}
        navigateForward={navigateForward}
        navigateBackward={navigateBackward}
      />
    </div>
  );
};

export default Document;
