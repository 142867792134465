import React, { useState, useRef, useEffect } from "react";
import styled, { keyframes } from "styled-components";

// Keyframes for slide up animation
const slideUp = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
`;

// Styled component for the drawer
const DrawerWrapper = styled.div`
  position: absolute;
  bottom: -86vh;
  // left: 0;
  width: 100%;
  height: ${({ height }) => height}$px;
  background-color: white;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  transform: translateY(100%);
  animation: ${({ isOpen }) => (isOpen ? slideUp : "none")} 0.3s forwards;
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
  overflow: hidden;
  z-index: 99999 !important;
`;

const DragHandle = styled.div`
  width: 100%;
  height: 20px;
  background: #ddd;
  cursor: ns-resize;
  position: absolute;
  top: 0;
  left: 0;
`;

const InputWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 10px;
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Drawer = ({
  item,
  RenderComponent,
  uploadBottomSheetsHandler,
  isDraftPreview,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const [height, setHeight] = useState(item?.snaps?.[1]?.middleSnap); // Initial height of the drawer

  // const [bottomSnap, setBottomSnap] = useState({ value: 10, unit: "%" });
  // const [middleSnap, setMiddleSnap] = useState({ value: 50, unit: "%" });
  // const [topSnap, setTopSnap] = useState({ value: 80, unit: "%" });

  const drawerRef = useRef(null);

  const convertToPixels = (value, unit) => {
    if (unit === "%") {
      return (value / 100) * window.innerHeight;
    }
    return value;
  };

  const getSnapPositions = () => {
    return [
      convertToPixels(item?.bottomSnap?.value, item?.bottomSnap?.unit),
      convertToPixels(item?.middleSnap?.value, item?.middleSnap?.unit),
      convertToPixels(item?.topSnap?.value, item?.topSnap?.unit),
    ];
  };

  const startResizing = (e) => {
    e.preventDefault();
    window.addEventListener("mousemove", resizeDrawer);
    window.addEventListener("mouseup", stopResizing);
  };

  const resizeDrawer = (e) => {
    const newHeight = window.innerHeight - e.clientY;

    console.log(newHeight);
    if (newHeight > 100 && newHeight < window.innerHeight) {
      // setHeight(
      //   item?.snaps?.[1]?.middleSnap ? item?.snaps?.[1]?.middleSnap : newHeight
      // );
      setHeight(newHeight);
    }
  };

  const stopResizing = () => {
    window.removeEventListener("mousemove", resizeDrawer);
    window.removeEventListener("mouseup", stopResizing);
    snapToPosition();
  };

  const snapToPosition = () => {
    const snapPositions = getSnapPositions();
    const closest = snapPositions.reduce((prev, curr) =>
      Math.abs(curr - height) < Math.abs(prev - height) ? curr : prev
    );
    setHeight(closest);
  };

  const handleInputChange = (setter) => (e) => {
    const { name, value } = e.target;
    setter((prev) => ({
      ...prev,
      [name]: name === "value" ? Number(value) : value,
    }));
  };

  useEffect(() => {
    setIsOpen(true);
    setHeight(convertToPixels(item?.middleSnap?.value, item?.middleSnap?.unit));
  }, []);

  useEffect(() => {
    setHeight(getSnapPositions()[1]); // Default to middle snap position
  }, [item?.bottomSnap?.value, item?.middleSnap?.value, item?.topSnap?.value]);

  const handleSnapChange = (snap, setter) => {
    setter(snap);
    setHeight(convertToPixels(snap.value, snap.unit));
  };

  const marginStyle = item.margin
    ? {
        margin: item.margin[0].all + item.margin[0].unit,
        marginLeft: item.margin[1].left + item.margin[1].unit,
        marginTop: item.margin[2].top + item.margin[2].unit,
        marginRight: item.margin[3].right + item.margin[3].unit,
        marginBottom: item.margin[4].bottom + item.margin[4].unit,
      }
    : {};
  const paddingStyle = item.padding
    ? {
        padding: item.padding[0].all + item.padding[0].unit,
        paddingLeft: item.padding[1].left + item.padding[1].unit,
        paddingTop: item.padding[2].top + item.padding[2].unit,
        paddingRight: item.padding[3].right + item.padding[3].unit,
        paddingBottom: item.padding[4].bottom + item.padding[4].unit,
      }
    : {};

  const borderWidthStyle = item.borderWidth
    ? {
        borderWidth: item.borderWidth[0].all + item.borderWidth[0].unit,
        borderLeftWidth: item.borderWidth[1].left + item.borderWidth[1].unit,
        borderTopWidth: item.borderWidth[2].top + item.borderWidth[2].unit,
        borderRightWidth: item.borderWidth[3].right + item.borderWidth[3].unit,
        borderBottomWidth:
          item.borderWidth[4].bottom + item.borderWidth[4].unit,
      }
    : {};

  const width = parseInt(item.size[0].width);
  const min_height = parseInt(item.size[3]?.min_height);
  const min_width = parseInt(item.size[2]?.min_width);
  const max_height = parseInt(item.size[5]?.max_height);
  const max_width = parseInt(item.size[4]?.max_width);
  const widthUnit = item.size[0]?.unit;
  const heightUnit = item.size[1]?.unit;
  const min_heightunit = item.size[3]?.unit;
  const min_widthunit = item.size[2]?.unit;
  const max_heightunit = item.size[5]?.unit;
  const max_widthunit = item.size[4]?.unit;

  const RadiusStyle = item.borderRadius
    ? {
        borderRadius: item.borderRadius[0].all + item.borderRadius[0].unit,
        borderRadiusLeft:
          item.borderRadius[1].topLeft + item.borderRadius[1].unit,
        borderRadiusTop:
          item.borderRadius[2].topRight + item.borderRadius[2].unit,
        borderRadiusRight:
          item.borderRadius[3].bottomLeft + item.borderRadius[3].unit,
        borderRadiusBottom:
          item.borderRadius[4].bottomRight + item.borderRadius[4].unit,
      }
    : {};

  return (
    <>
      <DrawerWrapper
        ref={drawerRef}
        isOpen={isOpen}
        // height={height}
        style={{
          backgroundColor: item.id ? item?.background : null,
          resize: "none",
          zIndex: "10",
          cursor: isDraftPreview ? "pointer" : "default", // Set cursor style dynamically
          width: `${width}${widthUnit}`,
          height: `${height}${heightUnit}`,
          minHeight: `${min_height}${min_heightunit}`,
          minWidth: `${min_width}${min_widthunit}`,
          maxHeight: `${max_height}${max_heightunit}`,
          maxWidth: `${max_width}${max_widthunit}`,
          color: item.id ? item.color : null,
          fontSize: item.id ? `${item.fontSize}px` : "12px",
          lineHeight: item.id ? `${item.lineHeight}px` : "12px",
          letterSpacing: item.id ? `${item.letterSpacing}px` : null,
          fontFamily: item.id ? item.fontFamily : null,
          textAlign: item.id ? item.textAlign : null,
          fontWeight: item.id ? item.fontWeight : null,
          textDecoration: item.id ? item.textdecoration : null,
          borderColor: item.id ? item.borderColor : null,
          borderStyle: item.id ? item.borderStyle : null,
          textTransform: item.id ? item.textTransform : null,
          ...marginStyle,
          ...paddingStyle,
          ...borderWidthStyle,
          ...RadiusStyle,
          cursor: "pointer",
        }}
        onClick={(e) => uploadBottomSheetsHandler(e, item)}
      >
        <DragHandle onMouseDown={startResizing} />
        <div style={{ padding: "20px", paddingTop: "40px" }}>
          {item.children.map((child, index) => RenderComponent(child, index))}
        </div>
      </DrawerWrapper>
    </>
  );
};

export default Drawer;
