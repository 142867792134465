import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import Select from "react-select";
import info_icon from "../../../../assets/Icons/info_icon.svg";
import Switch from "react-switch";
import InformationIcon from "../../../../assets/Iconcomponent/InformationIcon";

const AccessiblityComponent = ({
  ToolTipBox,
  tooltipContent,
  setTooltipContent,
  showTooltipData,
  handleIconClick,
}) => {
  const [checked, setChecked] = useState(false);
  const [selectedCategorie, setSelectedCategorie] = useState("");

  const ReverseSwitch = ({ checked, handleColumnChange, setChecked }) => {
    return (
      <label>
        <Switch
          checked={checked}
          onColor="#3366ff"
          onHandleColor="#2693e6"
          handleDiameter={29}
          uncheckedIcon={false}
          checkedIcon={false}
          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
          className="react-switch"
          id="material-switch"
        />
      </label>
    );
  };

  return (
    <div>
      <div className="scroll-sec">
        <Accordion flush defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Accessibility </Accordion.Header>
            <Accordion.Body>
              <>
                <div className="access_box">
                  <div className="acess-label">
                    <p>AccessibilityLabel</p>
                    <ToolTipBox
                      iconType="green"
                      type="Accessibility Label"
                      handleIconClick={handleIconClick}
                      showTooltipData={showTooltipData}
                      tooltipContent={tooltipContent}
                    />
                  </div>
                  <div className="type-sec">
                    <input
                      type="text"
                      value={""}
                      name="acc_name"
                      placeholder="auto"
                    />
                  </div>
                </div>
                <div className="access_box">
                  <div className="acess-label">
                    <p>AccessibilityHint</p>
                    <ToolTipBox
                      iconType="green"
                      type="Accessibility Hint"
                      handleIconClick={handleIconClick}
                      showTooltipData={showTooltipData}
                      tooltipContent={tooltipContent}
                    />{" "}
                  </div>
                  <div className="type-sec">
                    <input
                      type="text"
                      value={""}
                      name="acc_name"
                      placeholder="auto"
                    />
                  </div>
                </div>
                <div className="access_box">
                  <div className="acess-label">
                    <p>AccessibilityRole</p>
                    <ToolTipBox
                      iconType="green"
                      type="Accessibility Role"
                      handleIconClick={handleIconClick}
                      showTooltipData={showTooltipData}
                      tooltipContent={tooltipContent}
                    />{" "}
                  </div>

                  <div className="option-slt">
                    <select
                      onChange={(e) => setSelectedCategorie(e.target.value)}
                      style={{
                        fontSize: "14px",
                      }}
                    >
                      {" "}
                      <option hidden>select type</option>
                      <option value="none">none</option>
                      <option value="button">button</option>
                      <option value="link">link</option>
                      <option value="header">header</option>
                      <option value="search">search</option>
                      <option value="image">image</option>
                      <option value="key">key</option>
                      <option value="text">text</option>
                      <option value="summary">summary</option>
                      <option value="imagebutton">imagebutton</option>
                      <option value="adjuctable">adjuctable</option>
                    </select>
                  </div>
                </div>

                <div className="access_box">
                  <div className="acess-label">
                    <p>Accessible</p>
                    <ToolTipBox
                      iconType="green"
                      type="Accessible"
                      handleIconClick={handleIconClick}
                      showTooltipData={showTooltipData}
                      tooltipContent={tooltipContent}
                    />{" "}
                  </div>

                  <div className="access_right">
                    <ReverseSwitch checked={checked} setChecked={setChecked} />
                  </div>
                </div>
                <div className="access_navtive">
                  <div className="acess-label">
                    <p>nativeID</p>
                    <ToolTipBox
                      type="nativeID"
                      iconType="green"
                      handleIconClick={handleIconClick}
                      showTooltipData={showTooltipData}
                      tooltipContent={tooltipContent}
                    />{" "}
                  </div>

                  <div className="type-sec">
                    <input
                      type="text"
                      value={""}
                      name="acc_name"
                      placeholder="auto"
                    />
                  </div>
                </div>
              </>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  );
};

export default AccessiblityComponent;
