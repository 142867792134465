import React from "react";

const TextAlignLast = ({ selectedFolder }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="btmIcon"
      // style={{
      //   background: selectedFolder?.textAlign === "Justify" && "#0AABE6",
      // }}
    >
      <path
        d="M3.75 5.25H14.25C14.7 5.25 15 4.95 15 4.5C15 4.05 14.7 3.75 14.25 3.75H3.75C3.3 3.75 3 4.05 3 4.5C3 4.95 3.3 5.25 3.75 5.25Z"
        fill="#fff"
      />
      <path
        d="M3.75 9.75H14.25C14.7 9.75 15 9.45 15 9C15 8.55 14.7 8.25 14.25 8.25H3.75C3.3 8.25 3 8.55 3 9C3 9.45 3.3 9.75 3.75 9.75Z"
        fill="#fff"
      />
      <path
        d="M3.75 14.25H14.25C14.7 14.25 15 13.95 15 13.5C15 13.05 14.7 12.75 14.25 12.75H3.75C3.3 12.75 3 13.05 3 13.5C3 13.95 3.3 14.25 3.75 14.25Z"
        fill="#fff"
      />
    </svg>
  );
};

export default TextAlignLast;
