import React from "react";

const FlashListSvg = ({ width, height, change }) => {
  return (
    <>
      <svg
        width={width}
        height={height}
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_148_850)">
          <path
            d="M24.5 27.125L17.5 20.125"
            stroke={change ? "#fff" : "#3c4c6e"}
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M17.5 27.125L24.5 20.125"
            stroke={change ? "#fff" : "#3c4c6e"}
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            stroke-rule="evenodd"
            clip-rule="evenodd"
            d="M15.75 12.25H21L3.5 27.125L11.375 12.25H6.125L13.125 0.875H24.5L15.75 12.25Z"
            stroke={change ? "#fff" : "#3c4c6e"}
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_148_850">
            <rect width="28" height="28" fill={change ? "#fff" : "#3c4c6e"} />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default FlashListSvg;
