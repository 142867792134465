import React, { useEffect, useState } from "react";
import { DeleteOutline, RocketLaunch, Feed } from "@mui/icons-material";
import DeletePopup from "../../Popup/DeletePopup";
import DashboardData from "../../pages/Dashboard/DashboardData";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteMyProjectData,
  duplicateMyProject,
  getMyProjects,
  shareMyProject,
} from "../../redux/actions/projectsActions";
import noLogo from "../../assets/Image/no_logo.svg";
import moment from "moment";

import Loader from "../../common/Loader/Loader";
import { useNavigate } from "react-router-dom";
import ThreeDots from "../../common/svgComponents/ThreeDots";
import ShareSvg from "../../common/svgComponents/ShareSvg";
import DuplicateSvg from "../../common/svgComponents/DuplicateSvg";
import DeleteSvg2 from "../../common/svgComponents/DeleteSvg2";
import ShareProjectModal from "../../Popup/ShareProjectModal";
import img_1 from "../../assets/Icons/img_1.svg";
import img_2s from "../../assets/Icons/img_2s.svg";
import cloud_img from "../../assets/Icons/cloud_img.svg";
import emptyProjects from "../../assets/Image/emptyProjects.png";

const GetProjects = ({ onOpenModal }) => {
  const navigate = useNavigate();
  const maxLength = 80;
  const dispatch = useDispatch();
  const [projects, setProjects] = useState([]);
  const [change, setChange] = useState(false);
  const [deleted, setDeleted] = useState();
  const [openOption, setOpenOption] = useState(null);
  const [openShare, setOpenShare] = useState(false);
  const [shareEmail, setShareEmail] = useState();

  const myAllProjectList = useSelector(
    (state) => state?.auth?.myAllProjectList
  );
  const newProjectCreated = useSelector(
    (state) => state?.auth?.newProjectCreated
  );
  const { deleteMyProject, addDuplicateProject } = useSelector(
    (state) => state?.auth
  );
  const pageLoading = useSelector((state) => state?.auth?.pageLoading);

  const onDeleteModal = (data) => {
    setChange(true);
    setDeleted(data);
  };

  const onCloseModal = () => {
    setChange(false);
  };
  const onCloseShareModal = () => {
    setOpenShare(false);
  };

  const handleDelete = (data) => {
    onCloseModal();
    dispatch(deleteMyProjectData(data?._id, onCloseModal));
  };

  useEffect(() => {
    dispatch(getMyProjects());
  }, [newProjectCreated, deleteMyProject, addDuplicateProject]);

  const navigateToDashboard = (data) => {
    localStorage.setItem("project_id", data._id);
    localStorage.setItem("project_name", data.projectname);
    navigate(`/workspace/${encodeURIComponent(data.projectname)}`);
  };

  const trimText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    } else {
      return text.slice(0, maxLength) + " ...";
    }
  };

  const openOptionHandler = (items) => {
    setOpenOption(openOption === items._id ? null : items._id);
  };
  const openShareModal = (items) => {
    setOpenShare(openShare === items._id ? null : items._id);
  };
  const closeShareModal = () => {
    setOpenShare(false);
  };

  const duplicateProjectHandler = (items) => {
    dispatch(duplicateMyProject(items?._id));
    setOpenOption(null);
  };

  const shareProjectHandler = (e) => {
    e.preventDefault();

    const data = {
      projectId: openOption,
      email: shareEmail,
    };

    dispatch(shareMyProject(data, closeShareModal));
  };

  return (
    <>
      {pageLoading ? (
        <Loader />
      ) : (
        <div className="all-products">
          {myAllProjectList && myAllProjectList.length > 0 ? (
            myAllProjectList.map((items, index) => (
              <>
                <div className="main-product" key={index}>
                  <div className="delete-sec">
                    <ThreeDots onClick={() => openOptionHandler(items)} />
                    {/* <DeleteOutline onClick={() => onDeleteModal(items)} /> */}
                    {openOption === items?._id && (
                      <>
                        <div className="shareOption">
                          <svg
                            width="27"
                            height="16"
                            viewBox="0 0 27 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="arrowsvg2"
                          >
                            <path
                              d="M14.6604 1.01399L14.6606 1.01423L25.4958 13.4827L25.497 13.484L25.6237 13.6319C25.7035 13.7488 25.75 13.8888 25.75 14.0339C25.75 14.4292 25.4065 14.793 24.9272 14.793L2.07281 14.793C1.59346 14.793 1.25 14.4292 1.25 14.0339C1.25 13.894 1.29075 13.7607 1.36337 13.6451L1.52294 13.4647L1.52295 13.4647L1.52565 13.4616L12.3525 1.01754C12.3529 1.01708 12.3533 1.01661 12.3537 1.01615C12.6291 0.705598 13.0439 0.500942 13.508 0.500942C13.9768 0.500942 14.3889 0.701184 14.6604 1.01399Z"
                              fill="#1A237E"
                              stroke="#1A237E"
                            />
                          </svg>
                          <ul className="shareUl">
                            <li
                              className="shareLi"
                              onClick={() => openShareModal(items)}
                            >
                              <ShareSvg /> Share
                            </li>
                            <li
                              className="shareLi"
                              onClick={() => duplicateProjectHandler(items)}
                            >
                              <DuplicateSvg /> Duplicate
                            </li>
                            <li
                              className="shareLi"
                              onClick={() => onDeleteModal(items)}
                            >
                              <DeleteSvg2 /> Delete
                            </li>
                          </ul>
                        </div>
                      </>
                    )}
                    {openShare === items?._id && (
                      <ShareProjectModal
                        open={openShare}
                        projectID={deleted}
                        handleDelete={handleDelete}
                        onCloseModal={onCloseShareModal}
                        shareProjectHandler={shareProjectHandler}
                        setShareEmail={setShareEmail}
                      />
                    )}
                    {change && (
                      <DeletePopup
                        change={change}
                        projectID={deleted}
                        handleDelete={handleDelete}
                        onCloseModal={onCloseModal}
                      />
                    )}
                  </div>
                  <div
                    className="inner_project"
                    onClick={() => navigateToDashboard(items)}
                  >
                    <div className="head-sec">
                      <div className="left-sec">
                        {items.logo_url ? (
                          <span className="cloud_logo">
                            <img
                              src={items.logo_url}
                              alt={`Logo for ${items.projectname}`}
                            />
                          </span>
                        ) : (
                          <span className="cloud_logo">
                            <img src={cloud_img} alt="No Logo" />
                          </span>
                        )}
                      </div>
                      <div className="body-sec">
                        <h4>{items.projectname}</h4>
                        {/* <p>{trimText(items.description, maxLength)}</p> */}
                        <div className="right-sec">
                          {/* <span>{items.updatedAt}</span> */}
                          <span>
                            Edited {moment(items.updated_at).fromNow()}
                          </span>
                        </div>
                      </div>
                      <div className="bottom-sec">
                        <div className="left-sec">
                          <span>
                            <img src={img_1} alt="" />
                          </span>
                          <span>{items.screenCount}</span>
                        </div>
                        <div className="mid-sec">
                          <span>
                            <img src={img_2s} alt="" />
                          </span>
                          <span>{items.__v} </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ))
          ) : (
            <div
              className="d-flex"
              style={{
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <img src={emptyProjects} alt="" className="imgofempty" />
              <p className="no-data-available">Create Your First Project</p>
            </div>
          )}
          {/* <div className="Add_conatiner" onClick={onOpenModal}>
            <div className="icon-img">
              <img src="/assets/Image/plusIcon.svg" alt="" />
            </div>
            <div className="text">
              <h1>Add New Project</h1>
              <p>Add New Project And Design File</p>
            </div>
          </div> */}
        </div>
      )}
    </>
  );
};

export default GetProjects;
